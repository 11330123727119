@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    width: 100%;
    padding-right: 0.75rem;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: 0.75rem;
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container,
    .container-sm {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container,
    .container-md,
    .container-sm {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1320px;
    }
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) * -0.5);
    margin-left: calc(var(--bs-gutter-x) * -0.5);
}

.row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
}

.col-auto {
    flex: 0 0 auto;
    width: auto;
}

.col-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
}

.col-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
}

.col-3 {
    flex: 0 0 auto;
    width: 25%;
}

.col-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
}

.col-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
}

.col-6 {
    flex: 0 0 auto;
    width: 50%;
}

.col-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
}

.col-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
}

.col-9 {
    flex: 0 0 auto;
    width: 75%;
}

.col-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
}

.col-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
}

.col-12 {
    flex: 0 0 auto;
    width: 100%;
}

@media (min-width: 576px) {
    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
    }
    .col-sm-1 {
        flex: 0 0 auto;
        width: 8.33333333%;
    }
    .col-sm-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
    }
    .col-sm-3 {
        flex: 0 0 auto;
        width: 25%;
    }
    .col-sm-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }
    .col-sm-5 {
        flex: 0 0 auto;
        width: 41.66666667%;
    }
    .col-sm-6 {
        flex: 0 0 auto;
        width: 50%;
    }
    .col-sm-7 {
        flex: 0 0 auto;
        width: 58.33333333%;
    }
    .col-sm-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }
    .col-sm-9 {
        flex: 0 0 auto;
        width: 75%;
    }
    .col-sm-10 {
        flex: 0 0 auto;
        width: 83.33333333%;
    }
    .col-sm-11 {
        flex: 0 0 auto;
        width: 91.66666667%;
    }
    .col-sm-12 {
        flex: 0 0 auto;
        width: 100%;
    }
}

@media (min-width: 768px) {
    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
    }
    .col-md-1 {
        flex: 0 0 auto;
        width: 8.33333333%;
    }
    .col-md-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
    }
    .col-md-3 {
        flex: 0 0 auto;
        width: 25%;
    }
    .col-md-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }
    .col-md-5 {
        flex: 0 0 auto;
        width: 41.66666667%;
    }
    .col-md-6 {
        flex: 0 0 auto;
        width: 50%;
    }
    .col-md-7 {
        flex: 0 0 auto;
        width: 58.33333333%;
    }
    .col-md-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }
    .col-md-9 {
        flex: 0 0 auto;
        width: 75%;
    }
    .col-md-10 {
        flex: 0 0 auto;
        width: 83.33333333%;
    }
    .col-md-11 {
        flex: 0 0 auto;
        width: 91.66666667%;
    }
    .col-md-12 {
        flex: 0 0 auto;
        width: 100%;
    }
}

@media (min-width: 992px) {
    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
    }
    .col-lg-1 {
        flex: 0 0 auto;
        width: 8.33333333%;
    }
    .col-lg-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
    }
    .col-lg-3 {
        flex: 0 0 auto;
        width: 25%;
    }
    .col-lg-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }
    .col-lg-5 {
        flex: 0 0 auto;
        width: 41.66666667%;
    }
    .col-lg-6 {
        flex: 0 0 auto;
        width: 50%;
    }
    .col-lg-7 {
        flex: 0 0 auto;
        width: 58.33333333%;
    }
    .col-lg-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }
    .col-lg-9 {
        flex: 0 0 auto;
        width: 75%;
    }
    .col-lg-10 {
        flex: 0 0 auto;
        width: 83.33333333%;
    }
    .col-lg-11 {
        flex: 0 0 auto;
        width: 91.66666667%;
    }
    .col-lg-12 {
        flex: 0 0 auto;
        width: 100%;
    }
}

.align-items-center {
    align-items: center !important;
}

.justify-content-center {
    justify-content: center !important;
}

.mt-1 {
    margin-top: .25rem!important
}

.mt-2 {
    margin-top: .5rem!important
}

.mt-3 {
    margin-top: 1rem!important
}

.mt-4 {
    margin-top: 1.5rem!important
}

.mt-5 {
    margin-top: 3rem!important
}

.mb-1 {
    margin-bottom: .25rem!important
}

.mb-2 {
    margin-bottom: .5rem!important
}

.mb-3 {
    margin-bottom: 1rem!important
}

.mb-4 {
    margin-bottom: 1.5rem!important
}

.mb-5 {
    margin-bottom: 3rem!important
}

.pt-0 {
    padding-top: 0!important
}

.pt-1 {
    padding-top: .25rem!important
}

.pt-2 {
    padding-top: .5rem!important
}

.pt-3 {
    padding-top: 1rem!important
}

.pt-4 {
    padding-top: 1.5rem!important
}

.pt-5 {
    padding-top: 3rem!important
}

.pb-0 {
    padding-bottom: 0!important
}

.pb-1 {
    padding-bottom: .25rem!important
}

.pb-2 {
    padding-bottom: .5rem!important
}

.pb-3 {
    padding-bottom: 1rem!important
}

.pb-4 {
    padding-bottom: 1.5rem!important
}

.pb-5 {
    padding-bottom: 3rem!important
}

.pe-0 {
    padding-right: 0!important
}

.pe-1 {
    padding-right: .25rem!important
}

.pe-2 {
    padding-right: .5rem!important
}

.pe-3 {
    padding-right: 1rem!important
}

.pe-4 {
    padding-right: 1.5rem!important
}

.pe-5 {
    padding-right: 3rem!important
}

.ps-0 {
    padding-left: 0!important
}

.ps-1 {
    padding-left: .25rem!important
}

.ps-2 {
    padding-left: .5rem!important
}

.ps-3 {
    padding-left: 1rem!important
}

.ps-4 {
    padding-left: 1.5rem!important
}

.ps-5 {
    padding-left: 3rem!important
}

.m-auto {
    margin: auto!important
}

.p-0 {
    padding: 0!important
}

.p-1 {
    padding: .25rem!important
}

.p-2 {
    padding: .5rem!important
}

.p-3 {
    padding: 1rem!important
}

.p-4 {
    padding: 1.5rem!important
}

.p-5 {
    padding: 3rem!important
}

.mr-auto {
    margin-right: auto!important
}

.mb-auto {
    margin-bottom: auto!important
}

.ml-auto {
    margin-left: auto!important
}

.m-auto {
    margin: auto!important
}

.mt-auto {
    margin-top: auto!important
}
 .animatable {
  
  /* initially hide animatable objects */
  visibility: hidden;
  
  /* initially pause animatable objects their animations */
  -webkit-animation-play-state: paused;   
  animation-play-state: paused; 
}

/* show objects being animated */
.animate-in {
  visibility: visible;
  
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  
  -webkit-animation-duration: 1s;
  animation-duration: 1s;

  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.animate-out {
  visibility: visible;
  
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  
  -webkit-animation-duration: 1s;
  animation-duration: 1s;

  -webkit-animation-play-state: running;
  animation-play-state: running;

  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}

/* .animate-out:after {
  content :''
  letter-spacing: inherit;
} */

/* CSS Animations (extracted from http://glifo.uiparade.com/) */
@-webkit-keyframes fadeInDown {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-20px);
	}	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
}

@keyframes fadeInDown {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-20px);
		        transform: translateY(-20px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}



@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	20% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	60% {
		opacity: 0;
	}
	20% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-webkit-keyframes bounceInLeft {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-2000px);
	}
	60% {
		-webkit-transform: translateX(20px);
	}

	80% {
		-webkit-transform: translateX(-5px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
	}
}

@keyframes bounceInLeft {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-2000px);
		        transform: translateX(-2000px);
	}

	60% {
		-webkit-transform: translateX(20px);
		        transform: translateX(20px);
	}

	80% {
		-webkit-transform: translateX(-5px);
		        transform: translateX(-5px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}
}
@-webkit-keyframes bounceInRight {
	0% {
		opacity: 0;
		-webkit-transform: translateX(2000px);
	}

	60% {
		-webkit-transform: translateX(-20px);
	}

	80% {
		-webkit-transform: translateX(5px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
	}
}

@keyframes bounceInRight {
	0% {
		opacity: 0;
		-webkit-transform: translateX(2000px);
		        transform: translateX(2000px);
	}

	60% {
		-webkit-transform: translateX(-20px);
		        transform: translateX(-20px);
	}

	80% {
		-webkit-transform: translateX(5px);
		        transform: translateX(5px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}
}
@-webkit-keyframes fadeInUp {
	0% {
		opacity: 0;
		-webkit-transform: translateY(20px);
	}	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
}

@keyframes fadeInUp {
	0% {
		opacity: 0;
		-webkit-transform: translateY(20px);
		        transform: translateY(20px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}
@-webkit-keyframes bounceIn {
	0% {
		opacity: 0;
		-webkit-transform: scale(.3);
	}
	50% {
		-webkit-transform: scale(1.05);
	}

	70% {
		-webkit-transform: scale(.9);
	}

	100% {
		opacity: 1;
		-webkit-transform: scale(1);
	}
}

@keyframes bounceIn {
	0% {
		opacity: 0;
		-webkit-transform: scale(.3);
		        transform: scale(.3);
	}

	50% {
		-webkit-transform: scale(1.05);
		        transform: scale(1.05);
	}

	70% {
		-webkit-transform: scale(.9);
		        transform: scale(.9);
	}

	100% {
		opacity: 1;
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}
@-webkit-keyframes moveUp {
	0% {
		opacity: 1;
		-webkit-transform: translateY(40px);
	}	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
}

@keyframes moveUp {
	0% {
		opacity: 1;
		-webkit-transform: translateY(40px);
		        transform: translateY(40px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@-webkit-keyframes fadeBgColor {
	0%{
		background:none;
	}
  70%{
		background:none;
	}
	100%{
		background:#464646;
	}
}
@keyframes fadeBgColor {
	0%{
		background:none;
	}
  70%{
		background:none;
	}
	100%{
		background:#464646;
	}
}

.animate-in.animationDelay, .animate-out.animationDelay {
	animation-delay:.4s;
	-webkit-animation-delay:.4s;
}
.animate-in.animationDelayMed, .animate-out.animationDelayMed {
	animation-delay:1.2s;
	-webkit-animation-delay:1.2s;
}
.animate-in.animationDelayLong, .animate-out.animationDelayLong {
	animation-delay:1.6s;
	-webkit-animation-delay:1.6s;
}
.animate-in.fadeBgColor, .animate-out.fadeBgColor {
	-webkit-animation-name: fadeBgColor;
	animation-name: fadeBgColor;
}
.animate-in.bounceIn, .animate-out.bounceIn {
	-webkit-animation-name: bounceIn;
	animation-name: bounceIn;
}
.animate-in.bounceInRight, .animate-out.bounceInRight {
	-webkit-animation-name: bounceInRight;
	animation-name: bounceInRight;
}
.animate-in.bounceInLeft, .animate-out.bounceInLeft {
	-webkit-animation-name: bounceInLeft;
	animation-name: bounceInLeft;
}
.animate-in.fadeIn, .animate-out.fadeIn {
	-webkit-animation-name: fadeIn;
	animation-name: fadeIn;
}
.animate-in.fadeInDown, .animate-out.fadeInDown {
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
}
.animate-in.fadeInUp, .animate-out.fadeInUp {
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
}
.animate-in.moveUp, .animate-out.moveUp {
	-webkit-animation-name: moveUp;
	animation-name: moveUp;
}

/* ==============Lov Patsariya UI Developer=========== */


@media(max-width:1366px) {
.container1440{max-width:1200px;}
.bookingFilterBox{width:600px;}
/* .menu ul li a{font-size:13px;} */
/* .menu ul li{padding:15px 10px;} */
}



@media(max-width:1199px) {
.container1440{max-width:991px;}
.featureBox{padding:15px;}
.topBanner-content h1{font-size:40px;}

.worksIconBox{height:100px;width:100px;}
.worksIconBox span img {max-width:50px;}
.hostingBox h4{font-size:18px;}
.bookingFilterBox{width:495px;padding-right:15px;}
.bookingFilterBox h5{font-size:17px;}
}


/* =============767px 991px Start========== */
@media(max-width:991px){
.globalCard{padding:15px;}
.hostingBox h4{font-size:14px;}
.login-box p.ml-4 {margin-left: 0!important;}
.bookingFilterBox{width:369px;padding-right:15px;}

.headerSearchBox {margin-right:auto;}	
.logo{max-width:50px;}
.head-user-name {display:none;}	
.web-view-logo{display:none;}	
body.loged header{padding-right:0px;}
header > nav {padding-right:50px; }
.banner-home{background-position:center top;background-size:cover;}
.desktopFrame {margin-top:30px;}
.mob-menu{display:block;}
.close-menu{display:block;}
.menu{opacity:0; visibility:hidden; -webkit-backface-visibility:hidden; backface-visibility:hidden; position:fixed; top:0px; right:0px; margin-right:-100%; background:#164a7b; padding-top:50px; width:300px; height:100vh; overflow:hidden; overflow-y:auto; z-index:1; transition:400ms;}
.menu ul li {display:block;border-bottom:1px solid #d8d8d8; }
.menu ul li a{color:#ffffff;}
.menu-slide{margin-right:0; opacity:1; visibility:visible; -webkit-backface-visibility:visible; backface-visibility:visible; transition:400ms;}
.steps-main-wrapper{flex-wrap:wrap!important;}
.step-left, .step-right {order: 2;padding: 3rem 1rem 0;}
.steps-listing li{padding-right:0px;padding-left:70px;}
.steps-listing li span.step-number{left:0;right:auto;}
.steps-listing li:before{left: 26px;right:auto;}
.step-left {text-align: left;}
.login-box{margin-right:40px;}
.system-block{padding:0px;}
.menu_toggle_box {opacity: 1; visibility: visible; -webkit-backface-visibility: visible; backface-visibility: visible; }
.leftPannelMAin {margin-left: -320px; position: absolute; height: 100%; z-index: 10;border:1px solid #ddd;top:0px}
.leftPannelMAin.show_slide { margin-left: 0;}
/* .innerbody  .header.sildepannel{padding-left:60px} */
.btn.btn-menu{background:transparent;}
.bookingSearchFilter .btn{font-size:12px;}
.bookingFilterBox h5{font-size:13px;}
}
/* =============767px 991px End========== */

/* =============767px Start========== */
@media(max-width:767px){

.thousand-month sub {
    font-size: 1.8rem;
}
.thousand-month {
    font-size: 3.8rem;
}

.storage-content h4.weight600 {
    font-size: 24px;
}

.p-5.storage-space-block {
    padding: 20px!important;
}
.banner-sm .topBanner-content {
    min-height: 360px;
}
.contactus-details.p-5.pt60 {
    padding: 20px!important;
}
.contactus-map, .contactus-details {
    width: 100%;
}
.contactus-box{flex-wrap:wrap;}
.contactus-map img {
        border-bottom-left-radius: 0px;
        border-top-right-radius: 10px;
}
.contactus-details p {font-size: 16px;}
.contactus-details p span {
    max-width: 40px;
}
.contactus-details p label {padding-left: 4.5rem;}


.logo {max-width:40px;}	
.headerSearchBox { width: 87px; margin: 0; text-align: center; }	
.headSearchInnerBox{display:none;position:absolute;left:0;bottom:-33px;width:100%;border-top:1px solid #ddd; z-index: 12;}
.headerSearchBox .form-control{border-radius:0;min-height:40px;}
.headSearchInnerBox.show{display:block;}
.head-user-img{width:30px;height:30px;}	
.priceTickersList{display:none;}	
.login-box{max-width:174px;margin-left:0;}	
.btn.btnHeader{padding:3px 12px;min-width:60px;}
.top_user_info{max-width:50px;min-width:auto;}
.topBanner-content h1,.sectionHead h2,.tableBoxHead h4{font-size:26px;}
.topBanner-content p{font-size:14px;}


.mobW100{width:100%!important;max-width:100%!important;}
.mobTextCenter{text-align:center;}
.tableFilter .btn {min-height:45px;}
span.footLogo{display:block;top:auto;margin:0 auto 25px;}
footer p.copyRight{margin:15px 0 0;font-size:0.7rem;}
.addressBox{margin-top:15px;}
.largeImg{margin-top:30px;}
.animationWalletBox {margin-bottom:45px!important;}
.createAccountBox span{margin:0 0 10px;}
.formLinksInfo{font-size:12px;}



.bannerSearchBox{flex-wrap:wrap;justify-content:center;}
.bannerSearchBox .btn{margin-top:15px;min-height:50px;}
.p2pStoargeBox{padding:0;}
.sliderCommonStyle {padding:0;}
.sliderCommonStyle .slick-prev {left:-2px;}
.sliderCommonStyle .slick-next {right:-2px;}
.videoBox{margin-bottom:15px;}
.videoBox video{height:256px;}
.subscribeHead{margin-bottom:10px;}
.subscribeBox .btn{top: 3px;}

.hostingBox {margin:15px 0;}
.emptyFormBox {flex-wrap:wrap;}
.minWidth200.ml15{margin:10px 0 0!important;text-align:center;}
.spaceWorthSection .btn{min-width:100%;}
.spaceWorthPrice{font-size:30px;margin-bottom:10px;}
.spaceWorth h2{font-size:20px;}
.banner-sm .topBanner-content, .bannerHeight{min-height:calc(100vh - 70px);}

.userProfileName{display:none;}
.bookingFilterBox { width: 100%; padding-right: 0; }
.bookingMapBox, .bookingLeftContentBox {width:100%;padding:15px;}
.bookingSearchFilter .btn {font-size:12px;margin-left:5px;padding-left:5px;padding-right:5px;}
.bookingMapBox iframe {height:256px;}
}
/* =============767px End========== */

@media(max-width:479px){
.topBanner-content h1, .sectionHead h2, .tableBoxHead h4 {
font-size: 22px;
}
.subscribeBox .form-control{padding-left:10px;padding-right:72px;}
.subscribeBox .btn{top:6px;font-size:13px;right:0px;}
.sectionActionBtn .btn{font-size:14px!important;min-width:auto!important;}	
}

@media(min-width:768px){
.headSearchIcon{display:none;}
}

@media(min-width:992px){
.mob-view-logo{display:none;}
.menu ul li + li{margin-left:10px;}
}

@media(min-width:1024px){
.mainContent {min-height:calc(100vh - 479px);}
.blogRow div[class*="col"]{max-width:30%;margin:15px 0;}		
}

@media(max-width:991px){

.info-block {
border-left: none;
padding-left: 0;
margin-top: 1rem;
}


}




/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

*,
::after,
::before {
    box-sizing: border-box
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent
}

@-ms-viewport {
    width: device-width
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff
}

[tabindex="-1"]:focus {
    outline: 0!important
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: .5rem
}

p {
    margin-top: 0;
    margin-bottom: 1rem
}

abbr[data-original-title],
abbr[title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit
}

dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 1rem
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0
}

dt {
    font-weight: 700
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

dfn {
    font-style: italic
}

b,
strong {
    font-weight: bolder
}

small {
    font-size: 80%
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects
}

a:hover {
    color: #0056b3;
    text-decoration: underline
}

a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none
}

a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none
}

a:not([href]):not([tabindex]):focus {
    outline: 0
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar
}

figure {
    margin: 0 0 1rem
}

img {
    vertical-align: middle;
    border-style: none
}

svg:not(:root) {
    overflow: hidden
}

table {
    border-collapse: collapse
}

caption {
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom
}

th {
    text-align: inherit
}

label {
    display: inline-block;
    margin-bottom: .5rem
}

button {
    border-radius: 0
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button,
input {
    overflow: visible
}

button,
select {
    text-transform: none
}

[type=reset],
[type=submit],
button,
html [type=button] {
    -webkit-appearance: button
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border-style: none
}

input[type=checkbox],
input[type=radio] {
    box-sizing: border-box;
    padding: 0
}

input[type=date],
input[type=datetime-local],
input[type=month],
input[type=time] {
    -webkit-appearance: listbox
}

textarea {
    overflow: auto;
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal
}

progress {
    vertical-align: baseline
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: none
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

summary {
    display: list-item;
    cursor: pointer
}

template {
    display: none
}

[hidden] {
    display: none!important
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: .5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit
}

.h1,
h1 {
    font-size: 2.5rem
}

.h2,
h2 {
    font-size: 2rem
}

.h3,
h3 {
    font-size: 1.75rem
}

.h4,
h4 {
    font-size: 1.5rem
}

.h5,
h5 {
    font-size: 1.25rem
}

.h6,
h6 {
    font-size: 1rem
}

.lead {
    font-size: 1.25rem;
    font-weight: 300
}

.display-1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.2
}

.display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.2
}

.display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2
}

.display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1)
}

.small,
small {
    font-size: 80%;
    font-weight: 400
}

.mark,
mark {
    padding: .2em;
    background-color: #fcf8e3
}

.list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline {
    padding-left: 0;
    list-style: none
}

.list-inline-item {
    display: inline-block
}

.list-inline-item:not(:last-child) {
    margin-right: .5rem
}

.initialism {
    font-size: 90%;
    text-transform: uppercase
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem
}

.blockquote-footer {
    display: block;
    font-size: 80%;
    color: #6c757d
}

.blockquote-footer::before {
    content: "\2014 \00A0"
}

.img-fluid {
    max-width: 100%;
    height: auto
}

.img-thumbnail {
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    max-width: 100%;
    height: auto
}

.figure {
    display: inline-block
}

.figure-img {
    margin-bottom: .5rem;
    line-height: 1
}

.figure-caption {
    font-size: 90%;
    color: #6c757d
}

code,
kbd,
pre,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

code {
    font-size: 87.5%;
    color: #e83e8c;
    word-break: break-word
}

a>code {
    color: inherit
}

kbd {
    padding: .2rem .4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #212529;
    border-radius: .2rem
}

kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700
}

pre {
    display: block;
    font-size: 87.5%;
    color: #212529
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

@media (min-width:576px) {
    .container {
        max-width: 540px
    }
}

@media (min-width:768px) {
    .container {
        max-width: 720px
    }
}

@media (min-width:992px) {
    .container {
        max-width: 960px
    }
}

@media (min-width:1200px) {
    .container {
        max-width: 1140px
    }
}

.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px
}

.no-gutters {
    margin-right: 0;
    margin-left: 0
}

.no-gutters>.col,
.no-gutters>[class*=col-] {
    padding-right: 0;
    padding-left: 0
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px
}

.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
}

.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none
}

.col-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%
}

.col-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%
}

.col-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%
}

.col-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%
}

.col-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%
}

.col-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%
}

.col-9 {
    flex: 0 0 75%;
    max-width: 75%
}

.col-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%
}

.col-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%
}

.order-first {
    order: -1
}

.order-last {
    order: 13
}

.order-0 {
    order: 0
}

.order-1 {
    order: 1
}

.order-2 {
    order: 2
}

.order-3 {
    order: 3
}

.order-4 {
    order: 4
}

.order-5 {
    order: 5
}

.order-6 {
    order: 6
}

.order-7 {
    order: 7
}

.order-8 {
    order: 8
}

.order-9 {
    order: 9
}

.order-10 {
    order: 10
}

.order-11 {
    order: 11
}

.order-12 {
    order: 12
}

.offset-1 {
    margin-left: 8.333333%
}

.offset-2 {
    margin-left: 16.666667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.333333%
}

.offset-5 {
    margin-left: 41.666667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.333333%
}

.offset-8 {
    margin-left: 66.666667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.333333%
}

.offset-11 {
    margin-left: 91.666667%
}

@media (min-width:576px) {
    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }
    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }
    .col-sm-1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }
    .col-sm-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }
    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%
    }
    .col-sm-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }
    .col-sm-5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }
    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%
    }
    .col-sm-7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }
    .col-sm-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }
    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%
    }
    .col-sm-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }
    .col-sm-11 {
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }
    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%
    }
    .order-sm-first {
        order: -1
    }
    .order-sm-last {
        order: 13
    }
    .order-sm-0 {
        order: 0
    }
    .order-sm-1 {
        order: 1
    }
    .order-sm-2 {
        order: 2
    }
    .order-sm-3 {
        order: 3
    }
    .order-sm-4 {
        order: 4
    }
    .order-sm-5 {
        order: 5
    }
    .order-sm-6 {
        order: 6
    }
    .order-sm-7 {
        order: 7
    }
    .order-sm-8 {
        order: 8
    }
    .order-sm-9 {
        order: 9
    }
    .order-sm-10 {
        order: 10
    }
    .order-sm-11 {
        order: 11
    }
    .order-sm-12 {
        order: 12
    }
    .offset-sm-0 {
        margin-left: 0
    }
    .offset-sm-1 {
        margin-left: 8.333333%
    }
    .offset-sm-2 {
        margin-left: 16.666667%
    }
    .offset-sm-3 {
        margin-left: 25%
    }
    .offset-sm-4 {
        margin-left: 33.333333%
    }
    .offset-sm-5 {
        margin-left: 41.666667%
    }
    .offset-sm-6 {
        margin-left: 50%
    }
    .offset-sm-7 {
        margin-left: 58.333333%
    }
    .offset-sm-8 {
        margin-left: 66.666667%
    }
    .offset-sm-9 {
        margin-left: 75%
    }
    .offset-sm-10 {
        margin-left: 83.333333%
    }
    .offset-sm-11 {
        margin-left: 91.666667%
    }
}

@media (min-width:768px) {
    .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }
    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }
    .col-md-1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }
    .col-md-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }
    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%
    }
    .col-md-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }
    .col-md-5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }
    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%
    }
    .col-md-7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }
    .col-md-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }
    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%
    }
    .col-md-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }
    .col-md-11 {
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }
    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%
    }
    .order-md-first {
        order: -1
    }
    .order-md-last {
        order: 13
    }
    .order-md-0 {
        order: 0
    }
    .order-md-1 {
        order: 1
    }
    .order-md-2 {
        order: 2
    }
    .order-md-3 {
        order: 3
    }
    .order-md-4 {
        order: 4
    }
    .order-md-5 {
        order: 5
    }
    .order-md-6 {
        order: 6
    }
    .order-md-7 {
        order: 7
    }
    .order-md-8 {
        order: 8
    }
    .order-md-9 {
        order: 9
    }
    .order-md-10 {
        order: 10
    }
    .order-md-11 {
        order: 11
    }
    .order-md-12 {
        order: 12
    }
    .offset-md-0 {
        margin-left: 0
    }
    .offset-md-1 {
        margin-left: 8.333333%
    }
    .offset-md-2 {
        margin-left: 16.666667%
    }
    .offset-md-3 {
        margin-left: 25%
    }
    .offset-md-4 {
        margin-left: 33.333333%
    }
    .offset-md-5 {
        margin-left: 41.666667%
    }
    .offset-md-6 {
        margin-left: 50%
    }
    .offset-md-7 {
        margin-left: 58.333333%
    }
    .offset-md-8 {
        margin-left: 66.666667%
    }
    .offset-md-9 {
        margin-left: 75%
    }
    .offset-md-10 {
        margin-left: 83.333333%
    }
    .offset-md-11 {
        margin-left: 91.666667%
    }
}

@media (min-width:992px) {
    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }
    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }
    .col-lg-1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }
    .col-lg-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }
    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%
    }
    .col-lg-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }
    .col-lg-5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }
    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%
    }
    .col-lg-7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }
    .col-lg-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }
    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%
    }
    .col-lg-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }
    .col-lg-11 {
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }
    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%
    }
    .order-lg-first {
        order: -1
    }
    .order-lg-last {
        order: 13
    }
    .order-lg-0 {
        order: 0
    }
    .order-lg-1 {
        order: 1
    }
    .order-lg-2 {
        order: 2
    }
    .order-lg-3 {
        order: 3
    }
    .order-lg-4 {
        order: 4
    }
    .order-lg-5 {
        order: 5
    }
    .order-lg-6 {
        order: 6
    }
    .order-lg-7 {
        order: 7
    }
    .order-lg-8 {
        order: 8
    }
    .order-lg-9 {
        order: 9
    }
    .order-lg-10 {
        order: 10
    }
    .order-lg-11 {
        order: 11
    }
    .order-lg-12 {
        order: 12
    }
    .offset-lg-0 {
        margin-left: 0
    }
    .offset-lg-1 {
        margin-left: 8.333333%
    }
    .offset-lg-2 {
        margin-left: 16.666667%
    }
    .offset-lg-3 {
        margin-left: 25%
    }
    .offset-lg-4 {
        margin-left: 33.333333%
    }
    .offset-lg-5 {
        margin-left: 41.666667%
    }
    .offset-lg-6 {
        margin-left: 50%
    }
    .offset-lg-7 {
        margin-left: 58.333333%
    }
    .offset-lg-8 {
        margin-left: 66.666667%
    }
    .offset-lg-9 {
        margin-left: 75%
    }
    .offset-lg-10 {
        margin-left: 83.333333%
    }
    .offset-lg-11 {
        margin-left: 91.666667%
    }
}

@media (min-width:1200px) {
    .col-xl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }
    .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }
    .col-xl-1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }
    .col-xl-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }
    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%
    }
    .col-xl-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }
    .col-xl-5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }
    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%
    }
    .col-xl-7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }
    .col-xl-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }
    .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%
    }
    .col-xl-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }
    .col-xl-11 {
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }
    .col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%
    }
    .order-xl-first {
        order: -1
    }
    .order-xl-last {
        order: 13
    }
    .order-xl-0 {
        order: 0
    }
    .order-xl-1 {
        order: 1
    }
    .order-xl-2 {
        order: 2
    }
    .order-xl-3 {
        order: 3
    }
    .order-xl-4 {
        order: 4
    }
    .order-xl-5 {
        order: 5
    }
    .order-xl-6 {
        order: 6
    }
    .order-xl-7 {
        order: 7
    }
    .order-xl-8 {
        order: 8
    }
    .order-xl-9 {
        order: 9
    }
    .order-xl-10 {
        order: 10
    }
    .order-xl-11 {
        order: 11
    }
    .order-xl-12 {
        order: 12
    }
    .offset-xl-0 {
        margin-left: 0
    }
    .offset-xl-1 {
        margin-left: 8.333333%
    }
    .offset-xl-2 {
        margin-left: 16.666667%
    }
    .offset-xl-3 {
        margin-left: 25%
    }
    .offset-xl-4 {
        margin-left: 33.333333%
    }
    .offset-xl-5 {
        margin-left: 41.666667%
    }
    .offset-xl-6 {
        margin-left: 50%
    }
    .offset-xl-7 {
        margin-left: 58.333333%
    }
    .offset-xl-8 {
        margin-left: 66.666667%
    }
    .offset-xl-9 {
        margin-left: 75%
    }
    .offset-xl-10 {
        margin-left: 83.333333%
    }
    .offset-xl-11 {
        margin-left: 91.666667%
    }
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent
}

.table td,
.table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6
}

.table tbody+tbody {
    border-top: 2px solid #dee2e6
}

.table .table {
    background-color: #fff
}

.table-sm td,
.table-sm th {
    padding: .3rem
}

.table-bordered {
    border: 1px solid #dee2e6
}

.table-bordered td,
.table-bordered th {
    border: 1px solid #dee2e6
}

.table-bordered thead td,
.table-bordered thead th {
    border-bottom-width: 2px
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05)
}

.table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, .075)
}

.table-primary,
.table-primary>td,
.table-primary>th {
    background-color: #b8daff
}

.table-hover .table-primary:hover {
    background-color: #9fcdff
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
    background-color: #9fcdff
}

.table-secondary,
.table-secondary>td,
.table-secondary>th {
    background-color: #d6d8db
}

.table-hover .table-secondary:hover {
    background-color: #c8cbcf
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
    background-color: #c8cbcf
}

.table-success,
.table-success>td,
.table-success>th {
    background-color: #c3e6cb
}

.table-hover .table-success:hover {
    background-color: #b1dfbb
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
    background-color: #b1dfbb
}

.table-info,
.table-info>td,
.table-info>th {
    background-color: #bee5eb
}

.table-hover .table-info:hover {
    background-color: #abdde5
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
    background-color: #abdde5
}

.table-warning,
.table-warning>td,
.table-warning>th {
    background-color: #ffeeba
}

.table-hover .table-warning:hover {
    background-color: #ffe8a1
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
    background-color: #ffe8a1
}

.table-danger,
.table-danger>td,
.table-danger>th {
    background-color: #f5c6cb
}

.table-hover .table-danger:hover {
    background-color: #f1b0b7
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
    background-color: #f1b0b7
}

.table-light,
.table-light>td,
.table-light>th {
    background-color: #fdfdfe
}

.table-hover .table-light:hover {
    background-color: #ececf6
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
    background-color: #ececf6
}

.table-dark,
.table-dark>td,
.table-dark>th {
    background-color: #c6c8ca
}

.table-hover .table-dark:hover {
    background-color: #b9bbbe
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
    background-color: #b9bbbe
}

.table-active,
.table-active>td,
.table-active>th {
    background-color: rgba(0, 0, 0, .075)
}

.table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, .075)
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
    background-color: rgba(0, 0, 0, .075)
}

.table .thead-dark th {
    color: #fff;
    background-color: #212529;
    border-color: #32383e
}

.table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6
}

.table-dark {
    color: #fff;
    background-color: #212529
}

.table-dark td,
.table-dark th,
.table-dark thead th {
    border-color: #32383e
}

.table-dark.table-bordered {
    border: 0
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, .05)
}

.table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, .075)
}

@media (max-width:575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar
    }
    .table-responsive-sm>.table-bordered {
        border: 0
    }
}

@media (max-width:767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar
    }
    .table-responsive-md>.table-bordered {
        border: 0
    }
}

@media (max-width:991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar
    }
    .table-responsive-lg>.table-bordered {
        border: 0
    }
}

@media (max-width:1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar
    }
    .table-responsive-xl>.table-bordered {
        border: 0
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar
}

.table-responsive>.table-bordered {
    border: 0
}

.form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1
}

.form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1
}

.form-control::placeholder {
    color: #6c757d;
    opacity: 1
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1
}

select.form-control:not([size]):not([multiple]) {
    height: calc(2.25rem + 2px)
}

select.form-control:focus::-ms-value {
    color: #495057;
    background-color: #fff
}

.form-control-file,
.form-control-range {
    display: block;
    width: 100%
}

.col-form-label {
    padding-top: calc(.375rem + 1px);
    padding-bottom: calc(.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5
}

.col-form-label-lg {
    padding-top: calc(.5rem + 1px);
    padding-bottom: calc(.5rem + 1px);
    font-size: 1.25rem;
    line-height: 1.5
}

.col-form-label-sm {
    padding-top: calc(.25rem + 1px);
    padding-bottom: calc(.25rem + 1px);
    font-size: .875rem;
    line-height: 1.5
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding-top: .375rem;
    padding-bottom: .375rem;
    margin-bottom: 0;
    line-height: 1.5;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm,
.input-group-lg>.form-control-plaintext.form-control,
.input-group-lg>.input-group-append>.form-control-plaintext.btn,
.input-group-lg>.input-group-append>.form-control-plaintext.input-group-text,
.input-group-lg>.input-group-prepend>.form-control-plaintext.btn,
.input-group-lg>.input-group-prepend>.form-control-plaintext.input-group-text,
.input-group-sm>.form-control-plaintext.form-control,
.input-group-sm>.input-group-append>.form-control-plaintext.btn,
.input-group-sm>.input-group-append>.form-control-plaintext.input-group-text,
.input-group-sm>.input-group-prepend>.form-control-plaintext.btn,
.input-group-sm>.input-group-prepend>.form-control-plaintext.input-group-text {
    padding-right: 0;
    padding-left: 0
}

.form-control-sm,
.input-group-sm>.form-control,
.input-group-sm>.input-group-append>.btn,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-prepend>.input-group-text {
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem
}

.input-group-sm>.input-group-append>select.btn:not([size]):not([multiple]),
.input-group-sm>.input-group-append>select.input-group-text:not([size]):not([multiple]),
.input-group-sm>.input-group-prepend>select.btn:not([size]):not([multiple]),
.input-group-sm>.input-group-prepend>select.input-group-text:not([size]):not([multiple]),
.input-group-sm>select.form-control:not([size]):not([multiple]),
select.form-control-sm:not([size]):not([multiple]) {
    height: calc(1.8125rem + 2px)
}

.form-control-lg,
.input-group-lg>.form-control,
.input-group-lg>.input-group-append>.btn,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-prepend>.input-group-text {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem
}

.input-group-lg>.input-group-append>select.btn:not([size]):not([multiple]),
.input-group-lg>.input-group-append>select.input-group-text:not([size]):not([multiple]),
.input-group-lg>.input-group-prepend>select.btn:not([size]):not([multiple]),
.input-group-lg>.input-group-prepend>select.input-group-text:not([size]):not([multiple]),
.input-group-lg>select.form-control:not([size]):not([multiple]),
select.form-control-lg:not([size]):not([multiple]) {
    height: calc(2.875rem + 2px)
}

.form-group {
    margin-bottom: 1rem
}

.form-text {
    display: block;
    margin-top: .25rem
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px
}

.form-row>.col,
.form-row>[class*=col-] {
    padding-right: 5px;
    padding-left: 5px
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem
}

.form-check-input {
    position: absolute;
    margin-top: .3rem;
    margin-left: -1.25rem
}

.form-check-input:disabled~.form-check-label {
    color: #6c757d
}

.form-check-label {
    margin-bottom: 0
}

.form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: .75rem
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: .3125rem;
    margin-left: 0
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #28a745
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    line-height: 1;
    color: #fff;
    background-color: rgba(40, 167, 69, .8);
    border-radius: .2rem
}

.custom-select.is-valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.was-validated .form-control:valid {
    border-color: #28a745
}

.custom-select.is-valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.was-validated .form-control:valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25)
}

.custom-select.is-valid~.valid-feedback,
.custom-select.is-valid~.valid-tooltip,
.form-control.is-valid~.valid-feedback,
.form-control.is-valid~.valid-tooltip,
.was-validated .custom-select:valid~.valid-feedback,
.was-validated .custom-select:valid~.valid-tooltip,
.was-validated .form-control:valid~.valid-feedback,
.was-validated .form-control:valid~.valid-tooltip {
    display: block
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
    color: #28a745
}

.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip,
.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip {
    display: block
}

.custom-control-input.is-valid~.custom-control-label,
.was-validated .custom-control-input:valid~.custom-control-label {
    color: #28a745
}

.custom-control-input.is-valid~.custom-control-label::before,
.was-validated .custom-control-input:valid~.custom-control-label::before {
    background-color: #71dd8a
}

.custom-control-input.is-valid~.valid-feedback,
.custom-control-input.is-valid~.valid-tooltip,
.was-validated .custom-control-input:valid~.valid-feedback,
.was-validated .custom-control-input:valid~.valid-tooltip {
    display: block
}

.custom-control-input.is-valid:checked~.custom-control-label::before,
.was-validated .custom-control-input:valid:checked~.custom-control-label::before {
    background-color: #34ce57
}

.custom-control-input.is-valid:focus~.custom-control-label::before,
.was-validated .custom-control-input:valid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(40, 167, 69, .25)
}

.custom-file-input.is-valid~.custom-file-label,
.was-validated .custom-file-input:valid~.custom-file-label {
    border-color: #28a745
}

.custom-file-input.is-valid~.custom-file-label::before,
.was-validated .custom-file-input:valid~.custom-file-label::before {
    border-color: inherit
}

.custom-file-input.is-valid~.valid-feedback,
.custom-file-input.is-valid~.valid-tooltip,
.was-validated .custom-file-input:valid~.valid-feedback,
.was-validated .custom-file-input:valid~.valid-tooltip {
    display: block
}

.custom-file-input.is-valid:focus~.custom-file-label,
.was-validated .custom-file-input:valid:focus~.custom-file-label {
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25)
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    line-height: 1;
    color: #fff;
    background-color: rgba(220, 53, 69, .8);
    border-radius: .2rem
}

.custom-select.is-invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.was-validated .form-control:invalid {
    border-color: #dc3545
}

.custom-select.is-invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.was-validated .form-control:invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25)
}

.custom-select.is-invalid~.invalid-feedback,
.custom-select.is-invalid~.invalid-tooltip,
.form-control.is-invalid~.invalid-feedback,
.form-control.is-invalid~.invalid-tooltip,
.was-validated .custom-select:invalid~.invalid-feedback,
.was-validated .custom-select:invalid~.invalid-tooltip,
.was-validated .form-control:invalid~.invalid-feedback,
.was-validated .form-control:invalid~.invalid-tooltip {
    display: block
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
    color: #dc3545
}

.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip,
.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip {
    display: block
}

.custom-control-input.is-invalid~.custom-control-label,
.was-validated .custom-control-input:invalid~.custom-control-label {
    color: #dc3545
}

.custom-control-input.is-invalid~.custom-control-label::before,
.was-validated .custom-control-input:invalid~.custom-control-label::before {
    background-color: #efa2a9
}

.custom-control-input.is-invalid~.invalid-feedback,
.custom-control-input.is-invalid~.invalid-tooltip,
.was-validated .custom-control-input:invalid~.invalid-feedback,
.was-validated .custom-control-input:invalid~.invalid-tooltip {
    display: block
}

.custom-control-input.is-invalid:checked~.custom-control-label::before,
.was-validated .custom-control-input:invalid:checked~.custom-control-label::before {
    background-color: #e4606d
}

.custom-control-input.is-invalid:focus~.custom-control-label::before,
.was-validated .custom-control-input:invalid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(220, 53, 69, .25)
}

.custom-file-input.is-invalid~.custom-file-label,
.was-validated .custom-file-input:invalid~.custom-file-label {
    border-color: #dc3545
}

.custom-file-input.is-invalid~.custom-file-label::before,
.was-validated .custom-file-input:invalid~.custom-file-label::before {
    border-color: inherit
}

.custom-file-input.is-invalid~.invalid-feedback,
.custom-file-input.is-invalid~.invalid-tooltip,
.was-validated .custom-file-input:invalid~.invalid-feedback,
.was-validated .custom-file-input:invalid~.invalid-tooltip {
    display: block
}

.custom-file-input.is-invalid:focus~.custom-file-label,
.was-validated .custom-file-input:invalid:focus~.custom-file-label {
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25)
}

.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center
}

.form-inline .form-check {
    width: 100%
}

@media (min-width:576px) {
    .form-inline label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0
    }
    .form-inline .form-group {
        display: flex;
        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
        margin-bottom: 0
    }
    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle
    }
    .form-inline .form-control-plaintext {
        display: inline-block
    }
    .form-inline .input-group {
        width: auto
    }
    .form-inline .form-check {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-left: 0
    }
    .form-inline .form-check-input {
        position: relative;
        margin-top: 0;
        margin-right: .25rem;
        margin-left: 0
    }
    .form-inline .custom-control {
        align-items: center;
        justify-content: center
    }
    .form-inline .custom-control-label {
        margin-bottom: 0
    }
}

.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn:focus,
.btn:hover {
    text-decoration: none
}

.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.btn.disabled,
.btn:disabled {
    opacity: .65
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer
}

.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active {
    background-image: none
}

a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none
}

.btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff
}

.btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc
}

.btn-primary.focus,
.btn-primary:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5)
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5)
}

.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62
}

.btn-secondary.focus,
.btn-secondary:focus {
    box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5)
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5)
}

.btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745
}

.btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34
}

.btn-success.focus,
.btn-success:focus {
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5)
}

.btn-success.disabled,
.btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5)
}

.btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8
}

.btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b
}

.btn-info.focus,
.btn-info:focus {
    box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
}

.btn-info.disabled,
.btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show>.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f
}

.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
}

.btn-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00
}

.btn-warning.focus,
.btn-warning:focus {
    box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.btn-warning.disabled,
.btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show>.btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500
}

.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130
}

.btn-danger.focus,
.btn-danger:focus {
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
}

.btn-danger.disabled,
.btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d
}

.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
}

.btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5
}

.btn-light.focus,
.btn-light:focus {
    box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.btn-light.disabled,
.btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show>.btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.btn-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40
}

.btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124
}

.btn-dark.focus,
.btn-dark:focus {
    box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
}

.btn-dark.disabled,
.btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
}

.btn-outline-primary {
    color: #007bff;
    background-color: transparent;
    background-image: none;
    border-color: #007bff
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5)
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5)
}

.btn-outline-secondary {
    color: #6c757d;
    background-color: transparent;
    background-image: none;
    border-color: #6c757d
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
    box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5)
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show>.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5)
}

.btn-outline-success {
    color: #28a745;
    background-color: transparent;
    background-image: none;
    border-color: #28a745
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745
}

.btn-outline-success.focus,
.btn-outline-success:focus {
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5)
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show>.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5)
}

.btn-outline-info {
    color: #17a2b8;
    background-color: transparent;
    background-image: none;
    border-color: #17a2b8
}

.btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8
}

.btn-outline-info.focus,
.btn-outline-info:focus {
    box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show>.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
}

.btn-outline-warning {
    color: #ffc107;
    background-color: transparent;
    background-image: none;
    border-color: #ffc107
}

.btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-outline-warning.focus,
.btn-outline-warning:focus {
    box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show>.btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.btn-outline-danger {
    color: #dc3545;
    background-color: transparent;
    background-image: none;
    border-color: #dc3545
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-outline-danger.focus,
.btn-outline-danger:focus {
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show>.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
}

.btn-outline-light {
    color: #f8f9fa;
    background-color: transparent;
    background-image: none;
    border-color: #f8f9fa
}

.btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-outline-light.focus,
.btn-outline-light:focus {
    box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent
}

.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show>.btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.btn-outline-dark {
    color: #343a40;
    background-color: transparent;
    background-image: none;
    border-color: #343a40
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40
}

.btn-outline-dark.focus,
.btn-outline-dark:focus {
    box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show>.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
}

.btn-link {
    font-weight: 400;
    color: #007bff;
    background-color: transparent
}

.btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent
}

.btn-link.focus,
.btn-link:focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none
}

.btn-link.disabled,
.btn-link:disabled {
    color: #6c757d
}

.btn-group-lg>.btn,
.btn-lg {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem
}

.btn-group-sm>.btn,
.btn-sm {
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem
}

.btn-block {
    display: block;
    width: 100%
}

.btn-block+.btn-block {
    margin-top: .5rem
}

input[type=button].btn-block,
input[type=reset].btn-block,
input[type=submit].btn-block {
    width: 100%
}

.fade {
    opacity: 0;
    transition: opacity .15s linear
}

.fade.show {
    opacity: 1
}

.collapse {
    display: none
}

.collapse.show {
    display: block
}

tr.collapse.show {
    display: table-row
}

tbody.collapse.show {
    display: table-row-group
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height .35s ease
}

.dropdown,
.dropup {
    position: relative
}

.dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent
}

.dropdown-toggle:empty::after {
    margin-left: 0
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem
}

.dropup .dropdown-menu {
    margin-top: 0;
    margin-bottom: .125rem
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: 0;
    border-right: .3em solid transparent;
    border-bottom: .3em solid;
    border-left: .3em solid transparent
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropright .dropdown-menu {
    margin-top: 0;
    margin-left: .125rem
}

.dropright .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-bottom: .3em solid transparent;
    border-left: .3em solid
}

.dropright .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropright .dropdown-toggle::after {
    vertical-align: 0
}

.dropleft .dropdown-menu {
    margin-top: 0;
    margin-right: .125rem
}

.dropleft .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: ""
}

.dropleft .dropdown-toggle::after {
    display: none
}

.dropleft .dropdown-toggle::before {
    display: inline-block;
    width: 0;
    height: 0;
    margin-right: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: .3em solid;
    border-bottom: .3em solid transparent
}

.dropleft .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropleft .dropdown-toggle::before {
    vertical-align: 0
}

.dropdown-divider {
    height: 0;
    margin: .5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent
}

.dropdown-menu.show {
    display: block
}

.dropdown-header {
    display: block;
    padding: .5rem 1.5rem;
    margin-bottom: 0;
    font-size: .875rem;
    color: #6c757d;
    white-space: nowrap
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle
}

.btn-group-vertical>.btn,
.btn-group>.btn {
    position: relative;
    flex: 0 1 auto
}

.btn-group-vertical>.btn:hover,
.btn-group>.btn:hover {
    z-index: 1
}

.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus {
    z-index: 1
}

.btn-group .btn+.btn,
.btn-group .btn+.btn-group,
.btn-group .btn-group+.btn,
.btn-group .btn-group+.btn-group,
.btn-group-vertical .btn+.btn,
.btn-group-vertical .btn+.btn-group,
.btn-group-vertical .btn-group+.btn,
.btn-group-vertical .btn-group+.btn-group {
    margin-left: -1px
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start
}

.btn-toolbar .input-group {
    width: auto
}

.btn-group>.btn:first-child {
    margin-left: 0
}

.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.dropdown-toggle-split {
    padding-right: .5625rem;
    padding-left: .5625rem
}

.dropdown-toggle-split::after {
    margin-left: 0
}

.btn-group-sm>.btn+.dropdown-toggle-split,
.btn-sm+.dropdown-toggle-split {
    padding-right: .375rem;
    padding-left: .375rem
}

.btn-group-lg>.btn+.dropdown-toggle-split,
.btn-lg+.dropdown-toggle-split {
    padding-right: .75rem;
    padding-left: .75rem
}

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
    width: 100%
}

.btn-group-vertical>.btn+.btn,
.btn-group-vertical>.btn+.btn-group,
.btn-group-vertical>.btn-group+.btn,
.btn-group-vertical>.btn-group+.btn-group {
    margin-top: -1px;
    margin-left: 0
}

.btn-group-vertical>.btn-group:not(:last-child)>.btn,
.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn-group:not(:first-child)>.btn,
.btn-group-vertical>.btn:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
    margin-bottom: 0
}

.btn-group-toggle>.btn input[type=checkbox],
.btn-group-toggle>.btn input[type=radio],
.btn-group-toggle>.btn-group>.btn input[type=checkbox],
.btn-group-toggle>.btn-group>.btn input[type=radio] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%
}

.input-group>.custom-file,
.input-group>.custom-select,
.input-group>.form-control {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0
}

.input-group>.custom-file:focus,
.input-group>.custom-select:focus,
.input-group>.form-control:focus {
    z-index: 3
}

.input-group>.custom-file+.custom-file,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.form-control,
.input-group>.custom-select+.custom-file,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.form-control,
.input-group>.form-control+.custom-file,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.form-control {
    margin-left: -1px
}

.input-group>.custom-select:not(:last-child),
.input-group>.form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.custom-select:not(:first-child),
.input-group>.form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group>.custom-file {
    display: flex;
    align-items: center
}

.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:last-child) .custom-file-label::before {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.custom-file:not(:first-child) .custom-file-label,
.input-group>.custom-file:not(:first-child) .custom-file-label::before {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group-append,
.input-group-prepend {
    display: flex
}

.input-group-append .btn,
.input-group-prepend .btn {
    position: relative;
    z-index: 2
}

.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.btn,
.input-group-append .input-group-text+.input-group-text,
.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-prepend .input-group-text+.input-group-text {
    margin-left: -1px
}

.input-group-prepend {
    margin-right: -1px
}

.input-group-append {
    margin-left: -1px
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: .25rem
}

.input-group-text input[type=checkbox],
.input-group-text input[type=radio] {
    margin-top: 0
}

.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem
}

.custom-control-inline {
    display: inline-flex;
    margin-right: 1rem
}

.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    background-color: #007bff
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.custom-control-input:active~.custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff
}

.custom-control-input:disabled~.custom-control-label {
    color: #6c757d
}

.custom-control-input:disabled~.custom-control-label::before {
    background-color: #e9ecef
}

.custom-control-label {
    margin-bottom: 0
}

.custom-control-label::before {
    position: absolute;
    top: .25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #dee2e6
}

.custom-control-label::after {
    position: absolute;
    top: .25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%
}

.custom-checkbox .custom-control-label::before {
    border-radius: .25rem
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #007bff
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E")
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
    background-color: #007bff
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E")
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(0, 123, 255, .5)
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
    background-color: rgba(0, 123, 255, .5)
}

.custom-radio .custom-control-label::before {
    border-radius: 50%
}

.custom-radio .custom-control-input:checked~.custom-control-label::before {
    background-color: #007bff
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E")
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(0, 123, 255, .5)
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem 1.75rem .375rem .75rem;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .75rem center;
    background-size: 8px 10px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-appearance: none;
    appearance: none
}

.custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075), 0 0 5px rgba(128, 189, 255, .5)
}

.custom-select:focus::-ms-value {
    color: #495057;
    background-color: #fff
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: .75rem;
    background-image: none
}

.custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef
}

.custom-select::-ms-expand {
    opacity: 0
}

.custom-select-sm {
    height: calc(1.8125rem + 2px);
    padding-top: .375rem;
    padding-bottom: .375rem;
    font-size: 75%
}

.custom-select-lg {
    height: calc(2.875rem + 2px);
    padding-top: .375rem;
    padding-bottom: .375rem;
    font-size: 125%
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    margin-bottom: 0
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(2.25rem + 2px);
    margin: 0;
    opacity: 0
}

.custom-file-input:focus~.custom-file-control {
    border-color: #80bdff;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.custom-file-input:focus~.custom-file-control::before {
    border-color: #80bdff
}

.custom-file-input:lang(en)~.custom-file-label::after {
    content: "Browse"
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(calc(2.25rem + 2px) - 1px * 2);
    padding: .375rem .75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 .25rem .25rem 0
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav-link {
    display: block;
    padding: .5rem 1rem
}

.nav-link:focus,
.nav-link:hover {
    text-decoration: none
}

.nav-link.disabled {
    color: #6c757d
}

.nav-tabs {
    border-bottom: 1px solid #dee2e6
}

.nav-tabs .nav-item {
    margin-bottom: -1px
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: #e9ecef #e9ecef #dee2e6
}

.nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav-pills .nav-link {
    border-radius: .25rem
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #007bff
}

.nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center
}

.nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center
}

.tab-content>.tab-pane {
    display: none
}

.tab-content>.active {
    display: block
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: .5rem 1rem
}

.navbar>.container,
.navbar>.container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between
}

.navbar-brand {
    display: inline-block;
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap
}

.navbar-brand:focus,
.navbar-brand:hover {
    text-decoration: none
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0
}

.navbar-nav .dropdown-menu {
    position: static;
    float: none
}

.navbar-text {
    display: inline-block;
    padding-top: .5rem;
    padding-bottom: .5rem
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem
}

.navbar-toggler:focus,
.navbar-toggler:hover {
    text-decoration: none
}

.navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%
}

@media (max-width:575.98px) {
    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:576px) {
    .navbar-expand-sm {
        flex-flow: row nowrap;
        justify-content: flex-start
    }
    .navbar-expand-sm .navbar-nav {
        flex-direction: row
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto
    }
    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }
    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid {
        flex-wrap: nowrap
    }
    .navbar-expand-sm .navbar-collapse {
        display: flex!important;
        flex-basis: auto
    }
    .navbar-expand-sm .navbar-toggler {
        display: none
    }
    .navbar-expand-sm .dropup .dropdown-menu {
        top: auto;
        bottom: 100%
    }
}

@media (max-width:767.98px) {
    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:768px) {
    .navbar-expand-md {
        flex-flow: row nowrap;
        justify-content: flex-start
    }
    .navbar-expand-md .navbar-nav {
        flex-direction: row
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute
    }
    .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto
    }
    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }
    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid {
        flex-wrap: nowrap
    }
    .navbar-expand-md .navbar-collapse {
        display: flex!important;
        flex-basis: auto
    }
    .navbar-expand-md .navbar-toggler {
        display: none
    }
    .navbar-expand-md .dropup .dropdown-menu {
        top: auto;
        bottom: 100%
    }
}

@media (max-width:991.98px) {
    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:992px) {
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start
    }
    .navbar-expand-lg .navbar-nav {
        flex-direction: row
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }
    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid {
        flex-wrap: nowrap
    }
    .navbar-expand-lg .navbar-collapse {
        display: flex!important;
        flex-basis: auto
    }
    .navbar-expand-lg .navbar-toggler {
        display: none
    }
    .navbar-expand-lg .dropup .dropdown-menu {
        top: auto;
        bottom: 100%
    }
}

@media (max-width:1199.98px) {
    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:1200px) {
    .navbar-expand-xl {
        flex-flow: row nowrap;
        justify-content: flex-start
    }
    .navbar-expand-xl .navbar-nav {
        flex-direction: row
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto
    }
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }
    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid {
        flex-wrap: nowrap
    }
    .navbar-expand-xl .navbar-collapse {
        display: flex!important;
        flex-basis: auto
    }
    .navbar-expand-xl .navbar-toggler {
        display: none
    }
    .navbar-expand-xl .dropup .dropdown-menu {
        top: auto;
        bottom: 100%
    }
}

.navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
    padding-right: 0;
    padding-left: 0
}

.navbar-expand .navbar-nav {
    flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute
}

.navbar-expand .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
    flex-wrap: nowrap
}

.navbar-expand .navbar-collapse {
    display: flex!important;
    flex-basis: auto
}

.navbar-expand .navbar-toggler {
    display: none
}

.navbar-expand .dropup .dropdown-menu {
    top: auto;
    bottom: 100%
}

.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, .5)
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, .7)
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, .3)
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, .5);
    border-color: rgba(0, 0, 0, .1)
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}

.navbar-light .navbar-text {
    color: rgba(0, 0, 0, .5)
}

.navbar-light .navbar-text a {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
    color: rgba(0, 0, 0, .9)
}

.navbar-dark .navbar-brand {
    color: #fff
}

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, .5)
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, .75)
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, .25)
}

.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link {
    color: #fff
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, .5);
    border-color: rgba(255, 255, 255, .1)
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}

.navbar-dark .navbar-text {
    color: rgba(255, 255, 255, .5)
}

.navbar-dark .navbar-text a {
    color: #fff
}

.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
    color: #fff
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem
}

.card>hr {
    margin-right: 0;
    margin-left: 0
}

.card>.list-group:first-child .list-group-item:first-child {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.card>.list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.card-body {
    flex: 1 1 auto;
    padding: 1.25rem
}

.card-title {
    margin-bottom: .75rem
}

.card-subtitle {
    margin-top: -.375rem;
    margin-bottom: 0
}

.card-text:last-child {
    margin-bottom: 0
}

.card-link:hover {
    text-decoration: none
}

.card-link+.card-link {
    margin-left: 1.25rem
}

.card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, .03);
    border-bottom: 1px solid rgba(0, 0, 0, .125)
}

.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0
}

.card-header+.list-group .list-group-item:first-child {
    border-top: 0
}

.card-footer {
    padding: .75rem 1.25rem;
    background-color: rgba(0, 0, 0, .03);
    border-top: 1px solid rgba(0, 0, 0, .125)
}

.card-footer:last-child {
    border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px)
}

.card-header-tabs {
    margin-right: -.625rem;
    margin-bottom: -.75rem;
    margin-left: -.625rem;
    border-bottom: 0
}

.card-header-pills {
    margin-right: -.625rem;
    margin-left: -.625rem
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem
}

.card-img {
    width: 100%;
    border-radius: calc(.25rem - 1px)
}

.card-img-top {
    width: 100%;
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px)
}

.card-img-bottom {
    width: 100%;
    border-bottom-right-radius: calc(.25rem - 1px);
    border-bottom-left-radius: calc(.25rem - 1px)
}

.card-deck {
    display: flex;
    flex-direction: column
}

.card-deck .card {
    margin-bottom: 15px
}

@media (min-width:576px) {
    .card-deck {
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px
    }
    .card-deck .card {
        display: flex;
        flex: 1 0;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px
    }
}

.card-group {
    display: flex;
    flex-direction: column
}

.card-group>.card {
    margin-bottom: 15px
}

@media (min-width:576px) {
    .card-group {
        flex-flow: row wrap
    }
    .card-group>.card {
        flex: 1 0;
        margin-bottom: 0
    }
    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0
    }
    .card-group>.card:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }
    .card-group>.card:first-child .card-header,
    .card-group>.card:first-child .card-img-top {
        border-top-right-radius: 0
    }
    .card-group>.card:first-child .card-footer,
    .card-group>.card:first-child .card-img-bottom {
        border-bottom-right-radius: 0
    }
    .card-group>.card:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }
    .card-group>.card:last-child .card-header,
    .card-group>.card:last-child .card-img-top {
        border-top-left-radius: 0
    }
    .card-group>.card:last-child .card-footer,
    .card-group>.card:last-child .card-img-bottom {
        border-bottom-left-radius: 0
    }
    .card-group>.card:only-child {
        border-radius: .25rem
    }
    .card-group>.card:only-child .card-header,
    .card-group>.card:only-child .card-img-top {
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem
    }
    .card-group>.card:only-child .card-footer,
    .card-group>.card:only-child .card-img-bottom {
        border-bottom-right-radius: .25rem;
        border-bottom-left-radius: .25rem
    }
    .card-group>.card:not(:first-child):not(:last-child):not(:only-child) {
        border-radius: 0
    }
    .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-footer,
    .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-header,
    .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
    .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-img-top {
        border-radius: 0
    }
}

.card-columns .card {
    margin-bottom: .75rem
}

@media (min-width:576px) {
    .card-columns {
        -webkit-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 1.25rem;
        -moz-column-gap: 1.25rem;
        grid-column-gap: 1.25rem;
        column-gap: 1.25rem
    }
    .card-columns .card {
        display: inline-block;
        width: 100%
    }
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: .75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: .25rem
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: .5rem;
    padding-left: .5rem;
    color: #6c757d;
    content: "/"
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: underline
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: none
}

.breadcrumb-item.active {
    color: #6c757d
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem
}

.page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #dee2e6
}

.page-link:hover {
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6
}

.page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.page-link:not(:disabled):not(.disabled) {
    cursor: pointer
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.page-item:last-child .page-link {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6
}

.pagination-lg .page-link {
    padding: .75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: .3rem;
    border-bottom-left-radius: .3rem
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: .3rem;
    border-bottom-right-radius: .3rem
}

.pagination-sm .page-link {
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem
}

.badge {
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem
}

.badge:empty {
    display: none
}

.btn .badge {
    position: relative;
    top: -1px
}

.badge-pill {
    padding-right: .6em;
    padding-left: .6em;
    border-radius: 10rem
}

.badge-primary {
    color: #fff;
    background-color: #007bff
}

.badge-primary[href]:focus,
.badge-primary[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #0062cc
}

.badge-secondary {
    color: #fff;
    background-color: #6c757d
}

.badge-secondary[href]:focus,
.badge-secondary[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #545b62
}

.badge-success {
    color: #fff;
    background-color: #28a745
}

.badge-success[href]:focus,
.badge-success[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #1e7e34
}

.badge-info {
    color: #fff;
    background-color: #17a2b8
}

.badge-info[href]:focus,
.badge-info[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #117a8b
}

.badge-warning {
    color: #212529;
    background-color: #ffc107
}

.badge-warning[href]:focus,
.badge-warning[href]:hover {
    color: #212529;
    text-decoration: none;
    background-color: #d39e00
}

.badge-danger {
    color: #fff;
    background-color: #dc3545
}

.badge-danger[href]:focus,
.badge-danger[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #bd2130
}

.badge-light {
    color: #212529;
    background-color: #f8f9fa
}

.badge-light[href]:focus,
.badge-light[href]:hover {
    color: #212529;
    text-decoration: none;
    background-color: #dae0e5
}

.badge-dark {
    color: #fff;
    background-color: #343a40
}

.badge-dark[href]:focus,
.badge-dark[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #1d2124
}

.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: .3rem
}

@media (min-width:576px) {
    .jumbotron {
        padding: 4rem 2rem
    }
}

.jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0
}

.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem
}

.alert-heading {
    color: inherit
}

.alert-link {
    font-weight: 700
}

.alert-dismissible {
    padding-right: 4rem
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: .75rem 1.25rem;
    color: inherit
}

.alert-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff
}

.alert-primary hr {
    border-top-color: #9fcdff
}

.alert-primary .alert-link {
    color: #002752
}

.alert-secondary {
    color: #383d41;
    background-color: #e2e3e5;
    border-color: #d6d8db
}

.alert-secondary hr {
    border-top-color: #c8cbcf
}

.alert-secondary .alert-link {
    color: #202326
}

.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb
}

.alert-success hr {
    border-top-color: #b1dfbb
}

.alert-success .alert-link {
    color: #0b2e13
}

.alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb
}

.alert-info hr {
    border-top-color: #abdde5
}

.alert-info .alert-link {
    color: #062c33
}

.alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba
}

.alert-warning hr {
    border-top-color: #ffe8a1
}

.alert-warning .alert-link {
    color: #533f03
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb
}

.alert-danger hr {
    border-top-color: #f1b0b7
}

.alert-danger .alert-link {
    color: #491217
}

.alert-light {
    color: #818182;
    background-color: #fefefe;
    border-color: #fdfdfe
}

.alert-light hr {
    border-top-color: #ececf6
}

.alert-light .alert-link {
    color: #686868
}

.alert-dark {
    color: #1b1e21;
    background-color: #d6d8d9;
    border-color: #c6c8ca
}

.alert-dark hr {
    border-top-color: #b9bbbe
}

.alert-dark .alert-link {
    color: #040505
}

@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0
    }
    to {
        background-position: 0 0
    }
}

@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0
    }
    to {
        background-position: 0 0
    }
}

.progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: .75rem;
    background-color: #e9ecef;
    border-radius: .25rem
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    background-color: #007bff;
    transition: width .6s ease
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem
}

.progress-bar-animated {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite
}

.media {
    display: flex;
    align-items: flex-start
}

.media-body {
    flex: 1 1
}

.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0
}

.list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit
}

.list-group-item-action:focus,
.list-group-item-action:hover {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa
}

.list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef
}

.list-group-item {
    position: relative;
    display: block;
    padding: .75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125)
}

.list-group-item:first-child {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.list-group-item:focus,
.list-group-item:hover {
    z-index: 1;
    text-decoration: none
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: #6c757d;
    background-color: #fff
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff
}

.list-group-flush .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0
}

.list-group-flush:first-child .list-group-item:first-child {
    border-top: 0
}

.list-group-flush:last-child .list-group-item:last-child {
    border-bottom: 0
}

.list-group-item-primary {
    color: #004085;
    background-color: #b8daff
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
    color: #004085;
    background-color: #9fcdff
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085
}

.list-group-item-secondary {
    color: #383d41;
    background-color: #d6d8db
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
    color: #383d41;
    background-color: #c8cbcf
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41
}

.list-group-item-success {
    color: #155724;
    background-color: #c3e6cb
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
    color: #155724;
    background-color: #b1dfbb
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724
}

.list-group-item-info {
    color: #0c5460;
    background-color: #bee5eb
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
    color: #0c5460;
    background-color: #abdde5
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460
}

.list-group-item-warning {
    color: #856404;
    background-color: #ffeeba
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
    color: #856404;
    background-color: #ffe8a1
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404
}

.list-group-item-danger {
    color: #721c24;
    background-color: #f5c6cb
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
    color: #721c24;
    background-color: #f1b0b7
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24
}

.list-group-item-light {
    color: #818182;
    background-color: #fdfdfe
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
    color: #818182;
    background-color: #ececf6
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182
}

.list-group-item-dark {
    color: #1b1e21;
    background-color: #c6c8ca
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
    color: #1b1e21;
    background-color: #b9bbbe
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5
}

.close:focus,
.close:hover {
    color: #000;
    text-decoration: none;
    opacity: .75
}

.close:not(:disabled):not(.disabled) {
    cursor: pointer
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none
}

.modal-open {
    overflow: hidden
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none
}

.modal.fade .modal-dialog {
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out;
    -webkit-transform: translate(0, -25%);
    transform: translate(0, -25%)
}

.modal.show .modal-dialog {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - (.5rem * 2))
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem;
    outline: 0
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.show {
    opacity: .5
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem
}

.modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem
}

.modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #e9ecef
}

.modal-footer>:not(:first-child) {
    margin-left: .25rem
}

.modal-footer>:not(:last-child) {
    margin-right: .25rem
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll
}

@media (min-width:576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto
    }
    .modal-dialog-centered {
        min-height: calc(100% - (1.75rem * 2))
    }
    .modal-sm {
        max-width: 300px
    }
}

@media (min-width:992px) {
    .modal-lg {
        max-width: 800px
    }
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .875rem;
    word-wrap: break-word;
    opacity: 0
}

.tooltip.show {
    opacity: .9
}

.tooltip .arrow {
    position: absolute;
    display: block;
    width: .8rem;
    height: .4rem
}

.tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-tooltip-auto[x-placement^=top],
.bs-tooltip-top {
    padding: .4rem 0
}

.bs-tooltip-auto[x-placement^=top] .arrow,
.bs-tooltip-top .arrow {
    bottom: 0
}

.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
    top: 0;
    border-width: .4rem .4rem 0;
    border-top-color: #000
}

.bs-tooltip-auto[x-placement^=right],
.bs-tooltip-right {
    padding: 0 .4rem
}

.bs-tooltip-auto[x-placement^=right] .arrow,
.bs-tooltip-right .arrow {
    left: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-right .arrow::before {
    right: 0;
    border-width: .4rem .4rem .4rem 0;
    border-right-color: #000
}

.bs-tooltip-auto[x-placement^=bottom],
.bs-tooltip-bottom {
    padding: .4rem 0
}

.bs-tooltip-auto[x-placement^=bottom] .arrow,
.bs-tooltip-bottom .arrow {
    top: 0
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
    bottom: 0;
    border-width: 0 .4rem .4rem;
    border-bottom-color: #000
}

.bs-tooltip-auto[x-placement^=left],
.bs-tooltip-left {
    padding: 0 .4rem
}

.bs-tooltip-auto[x-placement^=left] .arrow,
.bs-tooltip-left .arrow {
    right: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::before {
    left: 0;
    border-width: .4rem 0 .4rem .4rem;
    border-left-color: #000
}

.tooltip-inner {
    max-width: 200px;
    padding: .25rem .5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: .25rem
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem
}

.popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: .5rem;
    margin: 0 .3rem
}

.popover .arrow::after,
.popover .arrow::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-popover-auto[x-placement^=top],
.bs-popover-top {
    margin-bottom: .5rem
}

.bs-popover-auto[x-placement^=top] .arrow,
.bs-popover-top .arrow {
    bottom: calc((.5rem + 1px) * -1)
}

.bs-popover-auto[x-placement^=top] .arrow::after,
.bs-popover-auto[x-placement^=top] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-top .arrow::before {
    border-width: .5rem .5rem 0
}

.bs-popover-auto[x-placement^=top] .arrow::before,
.bs-popover-top .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[x-placement^=top] .arrow::after,
.bs-popover-top .arrow::after {
    bottom: 1px;
    border-top-color: #fff
}

.bs-popover-auto[x-placement^=right],
.bs-popover-right {
    margin-left: .5rem
}

.bs-popover-auto[x-placement^=right] .arrow,
.bs-popover-right .arrow {
    left: calc((.5rem + 1px) * -1);
    width: .5rem;
    height: 1rem;
    margin: .3rem 0
}

.bs-popover-auto[x-placement^=right] .arrow::after,
.bs-popover-auto[x-placement^=right] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-right .arrow::before {
    border-width: .5rem .5rem .5rem 0
}

.bs-popover-auto[x-placement^=right] .arrow::before,
.bs-popover-right .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[x-placement^=right] .arrow::after,
.bs-popover-right .arrow::after {
    left: 1px;
    border-right-color: #fff
}

.bs-popover-auto[x-placement^=bottom],
.bs-popover-bottom {
    margin-top: .5rem
}

.bs-popover-auto[x-placement^=bottom] .arrow,
.bs-popover-bottom .arrow {
    top: calc((.5rem + 1px) * -1)
}

.bs-popover-auto[x-placement^=bottom] .arrow::after,
.bs-popover-auto[x-placement^=bottom] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-bottom .arrow::before {
    border-width: 0 .5rem .5rem .5rem
}

.bs-popover-auto[x-placement^=bottom] .arrow::before,
.bs-popover-bottom .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[x-placement^=bottom] .arrow::after,
.bs-popover-bottom .arrow::after {
    top: 1px;
    border-bottom-color: #fff
}

.bs-popover-auto[x-placement^=bottom] .popover-header::before,
.bs-popover-bottom .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7
}

.bs-popover-auto[x-placement^=left],
.bs-popover-left {
    margin-right: .5rem
}

.bs-popover-auto[x-placement^=left] .arrow,
.bs-popover-left .arrow {
    right: calc((.5rem + 1px) * -1);
    width: .5rem;
    height: 1rem;
    margin: .3rem 0
}

.bs-popover-auto[x-placement^=left] .arrow::after,
.bs-popover-auto[x-placement^=left] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-left .arrow::before {
    border-width: .5rem 0 .5rem .5rem
}

.bs-popover-auto[x-placement^=left] .arrow::before,
.bs-popover-left .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[x-placement^=left] .arrow::after,
.bs-popover-left .arrow::after {
    right: 1px;
    border-left-color: #fff
}

.popover-header {
    padding: .5rem .75rem;
    margin-bottom: 0;
    font-size: 1rem;
    color: inherit;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px)
}

.popover-header:empty {
    display: none
}

.popover-body {
    padding: .5rem .75rem;
    color: #212529
}

.carousel {
    position: relative
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden
}

.carousel-item {
    position: relative;
    display: none;
    align-items: center;
    width: 100%;
    transition: -webkit-transform .6s ease;
    transition: transform .6s ease;
    transition: transform .6s ease, -webkit-transform .6s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000px;
    perspective: 1000px
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
    display: block
}

.carousel-item-next,
.carousel-item-prev {
    position: absolute;
    top: 0
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
    -webkit-transform: translateX(0);
    transform: translateX(0)
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.active.carousel-item-right,
.carousel-item-next {
    -webkit-transform: translateX(100%);
    transform: translateX(100%)
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .active.carousel-item-right,
    .carousel-item-next {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }
}

.active.carousel-item-left,
.carousel-item-prev {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .active.carousel-item-left,
    .carousel-item-prev {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }
}

.carousel-control-next,
.carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: .5
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9
}

.carousel-control-prev {
    left: 0
}

.carousel-control-next {
    right: 0
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: transparent no-repeat center center;
    background-size: 100% 100%
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E")
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E")
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 10px;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none
}

.carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, .5)
}

.carousel-indicators li::before {
    position: absolute;
    top: -10px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 10px;
    content: ""
}

.carousel-indicators li::after {
    position: absolute;
    bottom: -10px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 10px;
    content: ""
}

.carousel-indicators .active {
    background-color: #fff
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center
}

.align-baseline {
    vertical-align: baseline!important
}

.align-top {
    vertical-align: top!important
}

.align-middle {
    vertical-align: middle!important
}

.align-bottom {
    vertical-align: bottom!important
}

.align-text-bottom {
    vertical-align: text-bottom!important
}

.align-text-top {
    vertical-align: text-top!important
}

.bg-primary {
    background-color: #007bff!important
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
    background-color: #0062cc!important
}

.bg-secondary {
    background-color: #6c757d!important
}

a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
    background-color: #545b62!important
}

.bg-success {
    background-color: #28a745!important
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
    background-color: #1e7e34!important
}

.bg-info {
    background-color: #17a2b8!important
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
    background-color: #117a8b!important
}

.bg-warning {
    background-color: #ffc107!important
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
    background-color: #d39e00!important
}

.bg-danger {
    background-color: #dc3545!important
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
    background-color: #bd2130!important
}

.bg-light {
    background-color: #f8f9fa!important
}

a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
    background-color: #dae0e5!important
}

.bg-dark {
    background-color: #343a40!important
}

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
    background-color: #1d2124!important
}

.bg-white {
    background-color: #fff!important
}

.bg-transparent {
    background-color: transparent!important
}

.border {
    border: 1px solid #dee2e6!important
}

.border-top {
    border-top: 1px solid #dee2e6!important
}

.border-right {
    border-right: 1px solid #dee2e6!important
}

.border-bottom {
    border-bottom: 1px solid #dee2e6!important
}

.border-left {
    border-left: 1px solid #dee2e6!important
}

.border-0 {
    border: 0!important
}

.border-top-0 {
    border-top: 0!important
}

.border-right-0 {
    border-right: 0!important
}

.border-bottom-0 {
    border-bottom: 0!important
}

.border-left-0 {
    border-left: 0!important
}

.border-primary {
    border-color: #007bff!important
}

.border-secondary {
    border-color: #6c757d!important
}

.border-success {
    border-color: #28a745!important
}

.border-info {
    border-color: #17a2b8!important
}

.border-warning {
    border-color: #ffc107!important
}

.border-danger {
    border-color: #dc3545!important
}

.border-light {
    border-color: #f8f9fa!important
}

.border-dark {
    border-color: #343a40!important
}

.border-white {
    border-color: #fff!important
}

.rounded {
    border-radius: .25rem!important
}

.rounded-top {
    border-top-left-radius: .25rem!important;
    border-top-right-radius: .25rem!important
}

.rounded-right {
    border-top-right-radius: .25rem!important;
    border-bottom-right-radius: .25rem!important
}

.rounded-bottom {
    border-bottom-right-radius: .25rem!important;
    border-bottom-left-radius: .25rem!important
}

.rounded-left {
    border-top-left-radius: .25rem!important;
    border-bottom-left-radius: .25rem!important
}

.rounded-circle {
    border-radius: 50%!important
}

.rounded-0 {
    border-radius: 0!important
}

.clearfix::after {
    display: block;
    clear: both;
    content: ""
}

.d-none {
    display: none!important
}

.d-inline {
    display: inline!important
}

.d-inline-block {
    display: inline-block!important
}

.d-block {
    display: block!important
}

.d-table {
    display: table!important
}

.d-table-row {
    display: table-row!important
}

.d-table-cell {
    display: table-cell!important
}

.d-flex {
    display: flex!important
}

.d-inline-flex {
    display: inline-flex!important
}

@media (min-width:576px) {
    .d-sm-none {
        display: none!important
    }
    .d-sm-inline {
        display: inline!important
    }
    .d-sm-inline-block {
        display: inline-block!important
    }
    .d-sm-block {
        display: block!important
    }
    .d-sm-table {
        display: table!important
    }
    .d-sm-table-row {
        display: table-row!important
    }
    .d-sm-table-cell {
        display: table-cell!important
    }
    .d-sm-flex {
        display: flex!important
    }
    .d-sm-inline-flex {
        display: inline-flex!important
    }
}

@media (min-width:768px) {
    .d-md-none {
        display: none!important
    }
    .d-md-inline {
        display: inline!important
    }
    .d-md-inline-block {
        display: inline-block!important
    }
    .d-md-block {
        display: block!important
    }
    .d-md-table {
        display: table!important
    }
    .d-md-table-row {
        display: table-row!important
    }
    .d-md-table-cell {
        display: table-cell!important
    }
    .d-md-flex {
        display: flex!important
    }
    .d-md-inline-flex {
        display: inline-flex!important
    }
}

@media (min-width:992px) {
    .d-lg-none {
        display: none!important
    }
    .d-lg-inline {
        display: inline!important
    }
    .d-lg-inline-block {
        display: inline-block!important
    }
    .d-lg-block {
        display: block!important
    }
    .d-lg-table {
        display: table!important
    }
    .d-lg-table-row {
        display: table-row!important
    }
    .d-lg-table-cell {
        display: table-cell!important
    }
    .d-lg-flex {
        display: flex!important
    }
    .d-lg-inline-flex {
        display: inline-flex!important
    }
}

@media (min-width:1200px) {
    .d-xl-none {
        display: none!important
    }
    .d-xl-inline {
        display: inline!important
    }
    .d-xl-inline-block {
        display: inline-block!important
    }
    .d-xl-block {
        display: block!important
    }
    .d-xl-table {
        display: table!important
    }
    .d-xl-table-row {
        display: table-row!important
    }
    .d-xl-table-cell {
        display: table-cell!important
    }
    .d-xl-flex {
        display: flex!important
    }
    .d-xl-inline-flex {
        display: inline-flex!important
    }
}

@media print {
    .d-print-none {
        display: none!important
    }
    .d-print-inline {
        display: inline!important
    }
    .d-print-inline-block {
        display: inline-block!important
    }
    .d-print-block {
        display: block!important
    }
    .d-print-table {
        display: table!important
    }
    .d-print-table-row {
        display: table-row!important
    }
    .d-print-table-cell {
        display: table-cell!important
    }
    .d-print-flex {
        display: flex!important
    }
    .d-print-inline-flex {
        display: inline-flex!important
    }
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden
}

.embed-responsive::before {
    display: block;
    content: ""
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0
}

.embed-responsive-21by9::before {
    padding-top: 42.857143%
}

.embed-responsive-16by9::before {
    padding-top: 56.25%
}

.embed-responsive-4by3::before {
    padding-top: 75%
}

.embed-responsive-1by1::before {
    padding-top: 100%
}

.flex-row {
    flex-direction: row!important
}

.flex-column {
    flex-direction: column!important
}

.flex-row-reverse {
    flex-direction: row-reverse!important
}

.flex-column-reverse {
    flex-direction: column-reverse!important
}

.flex-wrap {
    flex-wrap: wrap!important
}

.flex-nowrap {
    flex-wrap: nowrap!important
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse!important
}

.justify-content-start {
    justify-content: flex-start!important
}

.justify-content-end {
    justify-content: flex-end!important
}

.justify-content-center {
    justify-content: center!important
}

.justify-content-between {
    justify-content: space-between!important
}

.justify-content-around {
    justify-content: space-around!important
}

.align-items-start {
    align-items: flex-start!important
}

.align-items-end {
    align-items: flex-end!important
}

.align-items-center {
    align-items: center!important
}

.align-items-baseline {
    align-items: baseline!important
}

.align-items-stretch {
    align-items: stretch!important
}

.align-content-start {
    align-content: flex-start!important
}

.align-content-end {
    align-content: flex-end!important
}

.align-content-center {
    align-content: center!important
}

.align-content-between {
    align-content: space-between!important
}

.align-content-around {
    align-content: space-around!important
}

.align-content-stretch {
    align-content: stretch!important
}

.align-self-auto {
    align-self: auto!important
}

.align-self-start {
    align-self: flex-start!important
}

.align-self-end {
    align-self: flex-end!important
}

.align-self-center {
    align-self: center!important
}

.align-self-baseline {
    align-self: baseline!important
}

.align-self-stretch {
    align-self: stretch!important
}

@media (min-width:576px) {
    .flex-sm-row {
        flex-direction: row!important
    }
    .flex-sm-column {
        flex-direction: column!important
    }
    .flex-sm-row-reverse {
        flex-direction: row-reverse!important
    }
    .flex-sm-column-reverse {
        flex-direction: column-reverse!important
    }
    .flex-sm-wrap {
        flex-wrap: wrap!important
    }
    .flex-sm-nowrap {
        flex-wrap: nowrap!important
    }
    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse!important
    }
    .justify-content-sm-start {
        justify-content: flex-start!important
    }
    .justify-content-sm-end {
        justify-content: flex-end!important
    }
    .justify-content-sm-center {
        justify-content: center!important
    }
    .justify-content-sm-between {
        justify-content: space-between!important
    }
    .justify-content-sm-around {
        justify-content: space-around!important
    }
    .align-items-sm-start {
        align-items: flex-start!important
    }
    .align-items-sm-end {
        align-items: flex-end!important
    }
    .align-items-sm-center {
        align-items: center!important
    }
    .align-items-sm-baseline {
        align-items: baseline!important
    }
    .align-items-sm-stretch {
        align-items: stretch!important
    }
    .align-content-sm-start {
        align-content: flex-start!important
    }
    .align-content-sm-end {
        align-content: flex-end!important
    }
    .align-content-sm-center {
        align-content: center!important
    }
    .align-content-sm-between {
        align-content: space-between!important
    }
    .align-content-sm-around {
        align-content: space-around!important
    }
    .align-content-sm-stretch {
        align-content: stretch!important
    }
    .align-self-sm-auto {
        align-self: auto!important
    }
    .align-self-sm-start {
        align-self: flex-start!important
    }
    .align-self-sm-end {
        align-self: flex-end!important
    }
    .align-self-sm-center {
        align-self: center!important
    }
    .align-self-sm-baseline {
        align-self: baseline!important
    }
    .align-self-sm-stretch {
        align-self: stretch!important
    }
}

@media (min-width:768px) {
    .flex-md-row {
        flex-direction: row!important
    }
    .flex-md-column {
        flex-direction: column!important
    }
    .flex-md-row-reverse {
        flex-direction: row-reverse!important
    }
    .flex-md-column-reverse {
        flex-direction: column-reverse!important
    }
    .flex-md-wrap {
        flex-wrap: wrap!important
    }
    .flex-md-nowrap {
        flex-wrap: nowrap!important
    }
    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse!important
    }
    .justify-content-md-start {
        justify-content: flex-start!important
    }
    .justify-content-md-end {
        justify-content: flex-end!important
    }
    .justify-content-md-center {
        justify-content: center!important
    }
    .justify-content-md-between {
        justify-content: space-between!important
    }
    .justify-content-md-around {
        justify-content: space-around!important
    }
    .align-items-md-start {
        align-items: flex-start!important
    }
    .align-items-md-end {
        align-items: flex-end!important
    }
    .align-items-md-center {
        align-items: center!important
    }
    .align-items-md-baseline {
        align-items: baseline!important
    }
    .align-items-md-stretch {
        align-items: stretch!important
    }
    .align-content-md-start {
        align-content: flex-start!important
    }
    .align-content-md-end {
        align-content: flex-end!important
    }
    .align-content-md-center {
        align-content: center!important
    }
    .align-content-md-between {
        align-content: space-between!important
    }
    .align-content-md-around {
        align-content: space-around!important
    }
    .align-content-md-stretch {
        align-content: stretch!important
    }
    .align-self-md-auto {
        align-self: auto!important
    }
    .align-self-md-start {
        align-self: flex-start!important
    }
    .align-self-md-end {
        align-self: flex-end!important
    }
    .align-self-md-center {
        align-self: center!important
    }
    .align-self-md-baseline {
        align-self: baseline!important
    }
    .align-self-md-stretch {
        align-self: stretch!important
    }
}

@media (min-width:992px) {
    .flex-lg-row {
        flex-direction: row!important
    }
    .flex-lg-column {
        flex-direction: column!important
    }
    .flex-lg-row-reverse {
        flex-direction: row-reverse!important
    }
    .flex-lg-column-reverse {
        flex-direction: column-reverse!important
    }
    .flex-lg-wrap {
        flex-wrap: wrap!important
    }
    .flex-lg-nowrap {
        flex-wrap: nowrap!important
    }
    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse!important
    }
    .justify-content-lg-start {
        justify-content: flex-start!important
    }
    .justify-content-lg-end {
        justify-content: flex-end!important
    }
    .justify-content-lg-center {
        justify-content: center!important
    }
    .justify-content-lg-between {
        justify-content: space-between!important
    }
    .justify-content-lg-around {
        justify-content: space-around!important
    }
    .align-items-lg-start {
        align-items: flex-start!important
    }
    .align-items-lg-end {
        align-items: flex-end!important
    }
    .align-items-lg-center {
        align-items: center!important
    }
    .align-items-lg-baseline {
        align-items: baseline!important
    }
    .align-items-lg-stretch {
        align-items: stretch!important
    }
    .align-content-lg-start {
        align-content: flex-start!important
    }
    .align-content-lg-end {
        align-content: flex-end!important
    }
    .align-content-lg-center {
        align-content: center!important
    }
    .align-content-lg-between {
        align-content: space-between!important
    }
    .align-content-lg-around {
        align-content: space-around!important
    }
    .align-content-lg-stretch {
        align-content: stretch!important
    }
    .align-self-lg-auto {
        align-self: auto!important
    }
    .align-self-lg-start {
        align-self: flex-start!important
    }
    .align-self-lg-end {
        align-self: flex-end!important
    }
    .align-self-lg-center {
        align-self: center!important
    }
    .align-self-lg-baseline {
        align-self: baseline!important
    }
    .align-self-lg-stretch {
        align-self: stretch!important
    }
}

@media (min-width:1200px) {
    .flex-xl-row {
        flex-direction: row!important
    }
    .flex-xl-column {
        flex-direction: column!important
    }
    .flex-xl-row-reverse {
        flex-direction: row-reverse!important
    }
    .flex-xl-column-reverse {
        flex-direction: column-reverse!important
    }
    .flex-xl-wrap {
        flex-wrap: wrap!important
    }
    .flex-xl-nowrap {
        flex-wrap: nowrap!important
    }
    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse!important
    }
    .justify-content-xl-start {
        justify-content: flex-start!important
    }
    .justify-content-xl-end {
        justify-content: flex-end!important
    }
    .justify-content-xl-center {
        justify-content: center!important
    }
    .justify-content-xl-between {
        justify-content: space-between!important
    }
    .justify-content-xl-around {
        justify-content: space-around!important
    }
    .align-items-xl-start {
        align-items: flex-start!important
    }
    .align-items-xl-end {
        align-items: flex-end!important
    }
    .align-items-xl-center {
        align-items: center!important
    }
    .align-items-xl-baseline {
        align-items: baseline!important
    }
    .align-items-xl-stretch {
        align-items: stretch!important
    }
    .align-content-xl-start {
        align-content: flex-start!important
    }
    .align-content-xl-end {
        align-content: flex-end!important
    }
    .align-content-xl-center {
        align-content: center!important
    }
    .align-content-xl-between {
        align-content: space-between!important
    }
    .align-content-xl-around {
        align-content: space-around!important
    }
    .align-content-xl-stretch {
        align-content: stretch!important
    }
    .align-self-xl-auto {
        align-self: auto!important
    }
    .align-self-xl-start {
        align-self: flex-start!important
    }
    .align-self-xl-end {
        align-self: flex-end!important
    }
    .align-self-xl-center {
        align-self: center!important
    }
    .align-self-xl-baseline {
        align-self: baseline!important
    }
    .align-self-xl-stretch {
        align-self: stretch!important
    }
}

.float-left {
    float: left!important
}

.float-right {
    float: right!important
}

.float-none {
    float: none!important
}

@media (min-width:576px) {
    .float-sm-left {
        float: left!important
    }
    .float-sm-right {
        float: right!important
    }
    .float-sm-none {
        float: none!important
    }
}

@media (min-width:768px) {
    .float-md-left {
        float: left!important
    }
    .float-md-right {
        float: right!important
    }
    .float-md-none {
        float: none!important
    }
}

@media (min-width:992px) {
    .float-lg-left {
        float: left!important
    }
    .float-lg-right {
        float: right!important
    }
    .float-lg-none {
        float: none!important
    }
}

@media (min-width:1200px) {
    .float-xl-left {
        float: left!important
    }
    .float-xl-right {
        float: right!important
    }
    .float-xl-none {
        float: none!important
    }
}

.position-static {
    position: static!important
}

.position-relative {
    position: relative!important
}

.position-absolute {
    position: absolute!important
}

.position-fixed {
    position: fixed!important
}

.position-sticky {
    position: sticky!important
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030
}

@supports (position:sticky) {
    .sticky-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
    -webkit-clip-path: none;
    clip-path: none
}

.w-25 {
    width: 25%!important
}

.w-50 {
    width: 50%!important
}

.w-75 {
    width: 75%!important
}

.w-100 {
    width: 100%!important
}

.h-25 {
    height: 25%!important
}

.h-50 {
    height: 50%!important
}

.h-75 {
    height: 75%!important
}

.h-100 {
    height: 100%!important
}

.mw-100 {
    max-width: 100%!important
}

.mh-100 {
    max-height: 100%!important
}

.m-0 {
    margin: 0!important
}

.mt-0,
.my-0 {
    margin-top: 0!important
}

.mr-0,
.mx-0 {
    margin-right: 0!important
}

.mb-0,
.my-0 {
    margin-bottom: 0!important
}

.ml-0,
.mx-0 {
    margin-left: 0!important
}

.m-1 {
    margin: .25rem!important
}

.mt-1,
.my-1 {
    margin-top: .25rem!important
}

.mr-1,
.mx-1 {
    margin-right: .25rem!important
}

.mb-1,
.my-1 {
    margin-bottom: .25rem!important
}

.ml-1,
.mx-1 {
    margin-left: .25rem!important
}

.m-2 {
    margin: .5rem!important
}

.mt-2,
.my-2 {
    margin-top: .5rem!important
}

.mr-2,
.mx-2 {
    margin-right: .5rem!important
}

.mb-2,
.my-2 {
    margin-bottom: .5rem!important
}

.ml-2,
.mx-2 {
    margin-left: .5rem!important
}

.m-3 {
    margin: 1rem!important
}

.mt-3,
.my-3 {
    margin-top: 1rem!important
}

.mr-3,
.mx-3 {
    margin-right: 1rem!important
}

.mb-3,
.my-3 {
    margin-bottom: 1rem!important
}

.ml-3,
.mx-3 {
    margin-left: 1rem!important
}

.m-4 {
    margin: 1.5rem!important
}

.mt-4,
.my-4 {
    margin-top: 1.5rem!important
}

.mr-4,
.mx-4 {
    margin-right: 1.5rem!important
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem!important
}

.ml-4,
.mx-4 {
    margin-left: 1.5rem!important
}

.m-5 {
    margin: 3rem!important
}

.mt-5,
.my-5 {
    margin-top: 3rem!important
}

.mr-5,
.mx-5 {
    margin-right: 3rem!important
}

.mb-5,
.my-5 {
    margin-bottom: 3rem!important
}

.ml-5,
.mx-5 {
    margin-left: 3rem!important
}

.p-0 {
    padding: 0!important
}

.pt-0,
.py-0 {
    padding-top: 0!important
}

.pr-0,
.px-0 {
    padding-right: 0!important
}

.pb-0,
.py-0 {
    padding-bottom: 0!important
}

.pl-0,
.px-0 {
    padding-left: 0!important
}

.p-1 {
    padding: .25rem!important
}

.pt-1,
.py-1 {
    padding-top: .25rem!important
}

.pr-1,
.px-1 {
    padding-right: .25rem!important
}

.pb-1,
.py-1 {
    padding-bottom: .25rem!important
}

.pl-1,
.px-1 {
    padding-left: .25rem!important
}

.p-2 {
    padding: .5rem!important
}

.pt-2,
.py-2 {
    padding-top: .5rem!important
}

.pr-2,
.px-2 {
    padding-right: .5rem!important
}

.pb-2,
.py-2 {
    padding-bottom: .5rem!important
}

.pl-2,
.px-2 {
    padding-left: .5rem!important
}

.p-3 {
    padding: 1rem!important
}

.pt-3,
.py-3 {
    padding-top: 1rem!important
}

.pr-3,
.px-3 {
    padding-right: 1rem!important
}

.pb-3,
.py-3 {
    padding-bottom: 1rem!important
}

.pl-3,
.px-3 {
    padding-left: 1rem!important
}

.p-4 {
    padding: 1.5rem!important
}

.pt-4,
.py-4 {
    padding-top: 1.5rem!important
}

.pr-4,
.px-4 {
    padding-right: 1.5rem!important
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem!important
}

.pl-4,
.px-4 {
    padding-left: 1.5rem!important
}

.p-5 {
    padding: 3rem!important
}

.pt-5,
.py-5 {
    padding-top: 3rem!important
}

.pr-5,
.px-5 {
    padding-right: 3rem!important
}

.pb-5,
.py-5 {
    padding-bottom: 3rem!important
}

.pl-5,
.px-5 {
    padding-left: 3rem!important
}

.m-auto {
    margin: auto!important
}

.mt-auto,
.my-auto {
    margin-top: auto!important
}

.mr-auto,
.mx-auto {
    margin-right: auto!important
}

.mb-auto,
.my-auto {
    margin-bottom: auto!important
}

.ml-auto,
.mx-auto {
    margin-left: auto!important
}

@media (min-width:576px) {
    .m-sm-0 {
        margin: 0!important
    }
    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0!important
    }
    .mr-sm-0,
    .mx-sm-0 {
        margin-right: 0!important
    }
    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0!important
    }
    .ml-sm-0,
    .mx-sm-0 {
        margin-left: 0!important
    }
    .m-sm-1 {
        margin: .25rem!important
    }
    .mt-sm-1,
    .my-sm-1 {
        margin-top: .25rem!important
    }
    .mr-sm-1,
    .mx-sm-1 {
        margin-right: .25rem!important
    }
    .mb-sm-1,
    .my-sm-1 {
        margin-bottom: .25rem!important
    }
    .ml-sm-1,
    .mx-sm-1 {
        margin-left: .25rem!important
    }
    .m-sm-2 {
        margin: .5rem!important
    }
    .mt-sm-2,
    .my-sm-2 {
        margin-top: .5rem!important
    }
    .mr-sm-2,
    .mx-sm-2 {
        margin-right: .5rem!important
    }
    .mb-sm-2,
    .my-sm-2 {
        margin-bottom: .5rem!important
    }
    .ml-sm-2,
    .mx-sm-2 {
        margin-left: .5rem!important
    }
    .m-sm-3 {
        margin: 1rem!important
    }
    .mt-sm-3,
    .my-sm-3 {
        margin-top: 1rem!important
    }
    .mr-sm-3,
    .mx-sm-3 {
        margin-right: 1rem!important
    }
    .mb-sm-3,
    .my-sm-3 {
        margin-bottom: 1rem!important
    }
    .ml-sm-3,
    .mx-sm-3 {
        margin-left: 1rem!important
    }
    .m-sm-4 {
        margin: 1.5rem!important
    }
    .mt-sm-4,
    .my-sm-4 {
        margin-top: 1.5rem!important
    }
    .mr-sm-4,
    .mx-sm-4 {
        margin-right: 1.5rem!important
    }
    .mb-sm-4,
    .my-sm-4 {
        margin-bottom: 1.5rem!important
    }
    .ml-sm-4,
    .mx-sm-4 {
        margin-left: 1.5rem!important
    }
    .m-sm-5 {
        margin: 3rem!important
    }
    .mt-sm-5,
    .my-sm-5 {
        margin-top: 3rem!important
    }
    .mr-sm-5,
    .mx-sm-5 {
        margin-right: 3rem!important
    }
    .mb-sm-5,
    .my-sm-5 {
        margin-bottom: 3rem!important
    }
    .ml-sm-5,
    .mx-sm-5 {
        margin-left: 3rem!important
    }
    .p-sm-0 {
        padding: 0!important
    }
    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0!important
    }
    .pr-sm-0,
    .px-sm-0 {
        padding-right: 0!important
    }
    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0!important
    }
    .pl-sm-0,
    .px-sm-0 {
        padding-left: 0!important
    }
    .p-sm-1 {
        padding: .25rem!important
    }
    .pt-sm-1,
    .py-sm-1 {
        padding-top: .25rem!important
    }
    .pr-sm-1,
    .px-sm-1 {
        padding-right: .25rem!important
    }
    .pb-sm-1,
    .py-sm-1 {
        padding-bottom: .25rem!important
    }
    .pl-sm-1,
    .px-sm-1 {
        padding-left: .25rem!important
    }
    .p-sm-2 {
        padding: .5rem!important
    }
    .pt-sm-2,
    .py-sm-2 {
        padding-top: .5rem!important
    }
    .pr-sm-2,
    .px-sm-2 {
        padding-right: .5rem!important
    }
    .pb-sm-2,
    .py-sm-2 {
        padding-bottom: .5rem!important
    }
    .pl-sm-2,
    .px-sm-2 {
        padding-left: .5rem!important
    }
    .p-sm-3 {
        padding: 1rem!important
    }
    .pt-sm-3,
    .py-sm-3 {
        padding-top: 1rem!important
    }
    .pr-sm-3,
    .px-sm-3 {
        padding-right: 1rem!important
    }
    .pb-sm-3,
    .py-sm-3 {
        padding-bottom: 1rem!important
    }
    .pl-sm-3,
    .px-sm-3 {
        padding-left: 1rem!important
    }
    .p-sm-4 {
        padding: 1.5rem!important
    }
    .pt-sm-4,
    .py-sm-4 {
        padding-top: 1.5rem!important
    }
    .pr-sm-4,
    .px-sm-4 {
        padding-right: 1.5rem!important
    }
    .pb-sm-4,
    .py-sm-4 {
        padding-bottom: 1.5rem!important
    }
    .pl-sm-4,
    .px-sm-4 {
        padding-left: 1.5rem!important
    }
    .p-sm-5 {
        padding: 3rem!important
    }
    .pt-sm-5,
    .py-sm-5 {
        padding-top: 3rem!important
    }
    .pr-sm-5,
    .px-sm-5 {
        padding-right: 3rem!important
    }
    .pb-sm-5,
    .py-sm-5 {
        padding-bottom: 3rem!important
    }
    .pl-sm-5,
    .px-sm-5 {
        padding-left: 3rem!important
    }
    .m-sm-auto {
        margin: auto!important
    }
    .mt-sm-auto,
    .my-sm-auto {
        margin-top: auto!important
    }
    .mr-sm-auto,
    .mx-sm-auto {
        margin-right: auto!important
    }
    .mb-sm-auto,
    .my-sm-auto {
        margin-bottom: auto!important
    }
    .ml-sm-auto,
    .mx-sm-auto {
        margin-left: auto!important
    }
}

@media (min-width:768px) {
    .m-md-0 {
        margin: 0!important
    }
    .mt-md-0,
    .my-md-0 {
        margin-top: 0!important
    }
    .mr-md-0,
    .mx-md-0 {
        margin-right: 0!important
    }
    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0!important
    }
    .ml-md-0,
    .mx-md-0 {
        margin-left: 0!important
    }
    .m-md-1 {
        margin: .25rem!important
    }
    .mt-md-1,
    .my-md-1 {
        margin-top: .25rem!important
    }
    .mr-md-1,
    .mx-md-1 {
        margin-right: .25rem!important
    }
    .mb-md-1,
    .my-md-1 {
        margin-bottom: .25rem!important
    }
    .ml-md-1,
    .mx-md-1 {
        margin-left: .25rem!important
    }
    .m-md-2 {
        margin: .5rem!important
    }
    .mt-md-2,
    .my-md-2 {
        margin-top: .5rem!important
    }
    .mr-md-2,
    .mx-md-2 {
        margin-right: .5rem!important
    }
    .mb-md-2,
    .my-md-2 {
        margin-bottom: .5rem!important
    }
    .ml-md-2,
    .mx-md-2 {
        margin-left: .5rem!important
    }
    .m-md-3 {
        margin: 1rem!important
    }
    .mt-md-3,
    .my-md-3 {
        margin-top: 1rem!important
    }
    .mr-md-3,
    .mx-md-3 {
        margin-right: 1rem!important
    }
    .mb-md-3,
    .my-md-3 {
        margin-bottom: 1rem!important
    }
    .ml-md-3,
    .mx-md-3 {
        margin-left: 1rem!important
    }
    .m-md-4 {
        margin: 1.5rem!important
    }
    .mt-md-4,
    .my-md-4 {
        margin-top: 1.5rem!important
    }
    .mr-md-4,
    .mx-md-4 {
        margin-right: 1.5rem!important
    }
    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1.5rem!important
    }
    .ml-md-4,
    .mx-md-4 {
        margin-left: 1.5rem!important
    }
    .m-md-5 {
        margin: 3rem!important
    }
    .mt-md-5,
    .my-md-5 {
        margin-top: 3rem!important
    }
    .mr-md-5,
    .mx-md-5 {
        margin-right: 3rem!important
    }
    .mb-md-5,
    .my-md-5 {
        margin-bottom: 3rem!important
    }
    .ml-md-5,
    .mx-md-5 {
        margin-left: 3rem!important
    }
    .p-md-0 {
        padding: 0!important
    }
    .pt-md-0,
    .py-md-0 {
        padding-top: 0!important
    }
    .pr-md-0,
    .px-md-0 {
        padding-right: 0!important
    }
    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0!important
    }
    .pl-md-0,
    .px-md-0 {
        padding-left: 0!important
    }
    .p-md-1 {
        padding: .25rem!important
    }
    .pt-md-1,
    .py-md-1 {
        padding-top: .25rem!important
    }
    .pr-md-1,
    .px-md-1 {
        padding-right: .25rem!important
    }
    .pb-md-1,
    .py-md-1 {
        padding-bottom: .25rem!important
    }
    .pl-md-1,
    .px-md-1 {
        padding-left: .25rem!important
    }
    .p-md-2 {
        padding: .5rem!important
    }
    .pt-md-2,
    .py-md-2 {
        padding-top: .5rem!important
    }
    .pr-md-2,
    .px-md-2 {
        padding-right: .5rem!important
    }
    .pb-md-2,
    .py-md-2 {
        padding-bottom: .5rem!important
    }
    .pl-md-2,
    .px-md-2 {
        padding-left: .5rem!important
    }
    .p-md-3 {
        padding: 1rem!important
    }
    .pt-md-3,
    .py-md-3 {
        padding-top: 1rem!important
    }
    .pr-md-3,
    .px-md-3 {
        padding-right: 1rem!important
    }
    .pb-md-3,
    .py-md-3 {
        padding-bottom: 1rem!important
    }
    .pl-md-3,
    .px-md-3 {
        padding-left: 1rem!important
    }
    .p-md-4 {
        padding: 1.5rem!important
    }
    .pt-md-4,
    .py-md-4 {
        padding-top: 1.5rem!important
    }
    .pr-md-4,
    .px-md-4 {
        padding-right: 1.5rem!important
    }
    .pb-md-4,
    .py-md-4 {
        padding-bottom: 1.5rem!important
    }
    .pl-md-4,
    .px-md-4 {
        padding-left: 1.5rem!important
    }
    .p-md-5 {
        padding: 3rem!important
    }
    .pt-md-5,
    .py-md-5 {
        padding-top: 3rem!important
    }
    .pr-md-5,
    .px-md-5 {
        padding-right: 3rem!important
    }
    .pb-md-5,
    .py-md-5 {
        padding-bottom: 3rem!important
    }
    .pl-md-5,
    .px-md-5 {
        padding-left: 3rem!important
    }
    .m-md-auto {
        margin: auto!important
    }
    .mt-md-auto,
    .my-md-auto {
        margin-top: auto!important
    }
    .mr-md-auto,
    .mx-md-auto {
        margin-right: auto!important
    }
    .mb-md-auto,
    .my-md-auto {
        margin-bottom: auto!important
    }
    .ml-md-auto,
    .mx-md-auto {
        margin-left: auto!important
    }
}

@media (min-width:992px) {
    .m-lg-0 {
        margin: 0!important
    }
    .mt-lg-0,
    .my-lg-0 {
        margin-top: 0!important
    }
    .mr-lg-0,
    .mx-lg-0 {
        margin-right: 0!important
    }
    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0!important
    }
    .ml-lg-0,
    .mx-lg-0 {
        margin-left: 0!important
    }
    .m-lg-1 {
        margin: .25rem!important
    }
    .mt-lg-1,
    .my-lg-1 {
        margin-top: .25rem!important
    }
    .mr-lg-1,
    .mx-lg-1 {
        margin-right: .25rem!important
    }
    .mb-lg-1,
    .my-lg-1 {
        margin-bottom: .25rem!important
    }
    .ml-lg-1,
    .mx-lg-1 {
        margin-left: .25rem!important
    }
    .m-lg-2 {
        margin: .5rem!important
    }
    .mt-lg-2,
    .my-lg-2 {
        margin-top: .5rem!important
    }
    .mr-lg-2,
    .mx-lg-2 {
        margin-right: .5rem!important
    }
    .mb-lg-2,
    .my-lg-2 {
        margin-bottom: .5rem!important
    }
    .ml-lg-2,
    .mx-lg-2 {
        margin-left: .5rem!important
    }
    .m-lg-3 {
        margin: 1rem!important
    }
    .mt-lg-3,
    .my-lg-3 {
        margin-top: 1rem!important
    }
    .mr-lg-3,
    .mx-lg-3 {
        margin-right: 1rem!important
    }
    .mb-lg-3,
    .my-lg-3 {
        margin-bottom: 1rem!important
    }
    .ml-lg-3,
    .mx-lg-3 {
        margin-left: 1rem!important
    }
    .m-lg-4 {
        margin: 1.5rem!important
    }
    .mt-lg-4,
    .my-lg-4 {
        margin-top: 1.5rem!important
    }
    .mr-lg-4,
    .mx-lg-4 {
        margin-right: 1.5rem!important
    }
    .mb-lg-4,
    .my-lg-4 {
        margin-bottom: 1.5rem!important
    }
    .ml-lg-4,
    .mx-lg-4 {
        margin-left: 1.5rem!important
    }
    .m-lg-5 {
        margin: 3rem!important
    }
    .mt-lg-5,
    .my-lg-5 {
        margin-top: 3rem!important
    }
    .mr-lg-5,
    .mx-lg-5 {
        margin-right: 3rem!important
    }
    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 3rem!important
    }
    .ml-lg-5,
    .mx-lg-5 {
        margin-left: 3rem!important
    }
    .p-lg-0 {
        padding: 0!important
    }
    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0!important
    }
    .pr-lg-0,
    .px-lg-0 {
        padding-right: 0!important
    }
    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0!important
    }
    .pl-lg-0,
    .px-lg-0 {
        padding-left: 0!important
    }
    .p-lg-1 {
        padding: .25rem!important
    }
    .pt-lg-1,
    .py-lg-1 {
        padding-top: .25rem!important
    }
    .pr-lg-1,
    .px-lg-1 {
        padding-right: .25rem!important
    }
    .pb-lg-1,
    .py-lg-1 {
        padding-bottom: .25rem!important
    }
    .pl-lg-1,
    .px-lg-1 {
        padding-left: .25rem!important
    }
    .p-lg-2 {
        padding: .5rem!important
    }
    .pt-lg-2,
    .py-lg-2 {
        padding-top: .5rem!important
    }
    .pr-lg-2,
    .px-lg-2 {
        padding-right: .5rem!important
    }
    .pb-lg-2,
    .py-lg-2 {
        padding-bottom: .5rem!important
    }
    .pl-lg-2,
    .px-lg-2 {
        padding-left: .5rem!important
    }
    .p-lg-3 {
        padding: 1rem!important
    }
    .pt-lg-3,
    .py-lg-3 {
        padding-top: 1rem!important
    }
    .pr-lg-3,
    .px-lg-3 {
        padding-right: 1rem!important
    }
    .pb-lg-3,
    .py-lg-3 {
        padding-bottom: 1rem!important
    }
    .pl-lg-3,
    .px-lg-3 {
        padding-left: 1rem!important
    }
    .p-lg-4 {
        padding: 1.5rem!important
    }
    .pt-lg-4,
    .py-lg-4 {
        padding-top: 1.5rem!important
    }
    .pr-lg-4,
    .px-lg-4 {
        padding-right: 1.5rem!important
    }
    .pb-lg-4,
    .py-lg-4 {
        padding-bottom: 1.5rem!important
    }
    .pl-lg-4,
    .px-lg-4 {
        padding-left: 1.5rem!important
    }
    .p-lg-5 {
        padding: 3rem!important
    }
    .pt-lg-5,
    .py-lg-5 {
        padding-top: 3rem!important
    }
    .pr-lg-5,
    .px-lg-5 {
        padding-right: 3rem!important
    }
    .pb-lg-5,
    .py-lg-5 {
        padding-bottom: 3rem!important
    }
    .pl-lg-5,
    .px-lg-5 {
        padding-left: 3rem!important
    }
    .m-lg-auto {
        margin: auto!important
    }
    .mt-lg-auto,
    .my-lg-auto {
        margin-top: auto!important
    }
    .mr-lg-auto,
    .mx-lg-auto {
        margin-right: auto!important
    }
    .mb-lg-auto,
    .my-lg-auto {
        margin-bottom: auto!important
    }
    .ml-lg-auto,
    .mx-lg-auto {
        margin-left: auto!important
    }
}

@media (min-width:1200px) {
    .m-xl-0 {
        margin: 0!important
    }
    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0!important
    }
    .mr-xl-0,
    .mx-xl-0 {
        margin-right: 0!important
    }
    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0!important
    }
    .ml-xl-0,
    .mx-xl-0 {
        margin-left: 0!important
    }
    .m-xl-1 {
        margin: .25rem!important
    }
    .mt-xl-1,
    .my-xl-1 {
        margin-top: .25rem!important
    }
    .mr-xl-1,
    .mx-xl-1 {
        margin-right: .25rem!important
    }
    .mb-xl-1,
    .my-xl-1 {
        margin-bottom: .25rem!important
    }
    .ml-xl-1,
    .mx-xl-1 {
        margin-left: .25rem!important
    }
    .m-xl-2 {
        margin: .5rem!important
    }
    .mt-xl-2,
    .my-xl-2 {
        margin-top: .5rem!important
    }
    .mr-xl-2,
    .mx-xl-2 {
        margin-right: .5rem!important
    }
    .mb-xl-2,
    .my-xl-2 {
        margin-bottom: .5rem!important
    }
    .ml-xl-2,
    .mx-xl-2 {
        margin-left: .5rem!important
    }
    .m-xl-3 {
        margin: 1rem!important
    }
    .mt-xl-3,
    .my-xl-3 {
        margin-top: 1rem!important
    }
    .mr-xl-3,
    .mx-xl-3 {
        margin-right: 1rem!important
    }
    .mb-xl-3,
    .my-xl-3 {
        margin-bottom: 1rem!important
    }
    .ml-xl-3,
    .mx-xl-3 {
        margin-left: 1rem!important
    }
    .m-xl-4 {
        margin: 1.5rem!important
    }
    .mt-xl-4,
    .my-xl-4 {
        margin-top: 1.5rem!important
    }
    .mr-xl-4,
    .mx-xl-4 {
        margin-right: 1.5rem!important
    }
    .mb-xl-4,
    .my-xl-4 {
        margin-bottom: 1.5rem!important
    }
    .ml-xl-4,
    .mx-xl-4 {
        margin-left: 1.5rem!important
    }
    .m-xl-5 {
        margin: 3rem!important
    }
    .mt-xl-5,
    .my-xl-5 {
        margin-top: 3rem!important
    }
    .mr-xl-5,
    .mx-xl-5 {
        margin-right: 3rem!important
    }
    .mb-xl-5,
    .my-xl-5 {
        margin-bottom: 3rem!important
    }
    .ml-xl-5,
    .mx-xl-5 {
        margin-left: 3rem!important
    }
    .p-xl-0 {
        padding: 0!important
    }
    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0!important
    }
    .pr-xl-0,
    .px-xl-0 {
        padding-right: 0!important
    }
    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0!important
    }
    .pl-xl-0,
    .px-xl-0 {
        padding-left: 0!important
    }
    .p-xl-1 {
        padding: .25rem!important
    }
    .pt-xl-1,
    .py-xl-1 {
        padding-top: .25rem!important
    }
    .pr-xl-1,
    .px-xl-1 {
        padding-right: .25rem!important
    }
    .pb-xl-1,
    .py-xl-1 {
        padding-bottom: .25rem!important
    }
    .pl-xl-1,
    .px-xl-1 {
        padding-left: .25rem!important
    }
    .p-xl-2 {
        padding: .5rem!important
    }
    .pt-xl-2,
    .py-xl-2 {
        padding-top: .5rem!important
    }
    .pr-xl-2,
    .px-xl-2 {
        padding-right: .5rem!important
    }
    .pb-xl-2,
    .py-xl-2 {
        padding-bottom: .5rem!important
    }
    .pl-xl-2,
    .px-xl-2 {
        padding-left: .5rem!important
    }
    .p-xl-3 {
        padding: 1rem!important
    }
    .pt-xl-3,
    .py-xl-3 {
        padding-top: 1rem!important
    }
    .pr-xl-3,
    .px-xl-3 {
        padding-right: 1rem!important
    }
    .pb-xl-3,
    .py-xl-3 {
        padding-bottom: 1rem!important
    }
    .pl-xl-3,
    .px-xl-3 {
        padding-left: 1rem!important
    }
    .p-xl-4 {
        padding: 1.5rem!important
    }
    .pt-xl-4,
    .py-xl-4 {
        padding-top: 1.5rem!important
    }
    .pr-xl-4,
    .px-xl-4 {
        padding-right: 1.5rem!important
    }
    .pb-xl-4,
    .py-xl-4 {
        padding-bottom: 1.5rem!important
    }
    .pl-xl-4,
    .px-xl-4 {
        padding-left: 1.5rem!important
    }
    .p-xl-5 {
        padding: 3rem!important
    }
    .pt-xl-5,
    .py-xl-5 {
        padding-top: 3rem!important
    }
    .pr-xl-5,
    .px-xl-5 {
        padding-right: 3rem!important
    }
    .pb-xl-5,
    .py-xl-5 {
        padding-bottom: 3rem!important
    }
    .pl-xl-5,
    .px-xl-5 {
        padding-left: 3rem!important
    }
    .m-xl-auto {
        margin: auto!important
    }
    .mt-xl-auto,
    .my-xl-auto {
        margin-top: auto!important
    }
    .mr-xl-auto,
    .mx-xl-auto {
        margin-right: auto!important
    }
    .mb-xl-auto,
    .my-xl-auto {
        margin-bottom: auto!important
    }
    .ml-xl-auto,
    .mx-xl-auto {
        margin-left: auto!important
    }
}

.text-justify {
    text-align: justify!important
}

.text-nowrap {
    white-space: nowrap!important
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.text-left {
    text-align: left!important
}

.text-right {
    text-align: right!important
}

.text-center {
    text-align: center!important
}

@media (min-width:576px) {
    .text-sm-left {
        text-align: left!important
    }
    .text-sm-right {
        text-align: right!important
    }
    .text-sm-center {
        text-align: center!important
    }
}

@media (min-width:768px) {
    .text-md-left {
        text-align: left!important
    }
    .text-md-right {
        text-align: right!important
    }
    .text-md-center {
        text-align: center!important
    }
}

@media (min-width:992px) {
    .text-lg-left {
        text-align: left!important
    }
    .text-lg-right {
        text-align: right!important
    }
    .text-lg-center {
        text-align: center!important
    }
}

@media (min-width:1200px) {
    .text-xl-left {
        text-align: left!important
    }
    .text-xl-right {
        text-align: right!important
    }
    .text-xl-center {
        text-align: center!important
    }
}

.text-lowercase {
    text-transform: lowercase!important
}

.text-uppercase {
    text-transform: uppercase!important
}

.text-capitalize {
    text-transform: capitalize!important
}

.font-weight-light {
    font-weight: 300!important
}

.font-weight-normal {
    font-weight: 400!important
}

.font-weight-bold {
    font-weight: 700!important
}

.font-italic {
    font-style: italic!important
}

.text-white {
    color: #fff!important
}

.text-primary {
    color: #007bff!important
}

a.text-primary:focus,
a.text-primary:hover {
    color: #0062cc!important
}

.text-secondary {
    color: #6c757d!important
}

a.text-secondary:focus,
a.text-secondary:hover {
    color: #545b62!important
}

.text-success {
    color: #28a745!important
}

a.text-success:focus,
a.text-success:hover {
    color: #1e7e34!important
}

.text-info {
    color: #17a2b8!important
}

a.text-info:focus,
a.text-info:hover {
    color: #117a8b!important
}

.text-warning {
    color: #ffc107!important
}

a.text-warning:focus,
a.text-warning:hover {
    color: #d39e00!important
}

.text-danger {
    color: #dc3545!important
}

a.text-danger:focus,
a.text-danger:hover {
    color: #bd2130!important
}

.text-light {
    color: #f8f9fa!important
}

a.text-light:focus,
a.text-light:hover {
    color: #dae0e5!important
}

.text-dark {
    color: #343a40!important
}

a.text-dark:focus,
a.text-dark:hover {
    color: #1d2124!important
}

.text-muted {
    color: #6c757d!important
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0
}

.visible {
    visibility: visible!important
}

.invisible {
    visibility: hidden!important
}

@media print {
    *,
    ::after,
    ::before {
        text-shadow: none!important;
        box-shadow: none!important
    }
    a:not(.btn) {
        text-decoration: underline
    }
    abbr[title]::after {
        content: " (" attr(title) ")"
    }
    pre {
        white-space: pre-wrap!important
    }
    blockquote,
    pre {
        border: 1px solid #999;
        page-break-inside: avoid
    }
    thead {
        display: table-header-group
    }
    img,
    tr {
        page-break-inside: avoid
    }
    h2,
    h3,
    p {
        orphans: 3;
        widows: 3
    }
    h2,
    h3 {
        page-break-after: avoid
    }
    @page {
        size: a3
    }
    body {
        min-width: 992px!important
    }
    .container {
        min-width: 992px!important
    }
    .navbar {
        display: none
    }
    .badge {
        border: 1px solid #000
    }
    .table {
        border-collapse: collapse!important
    }
    .table td,
    .table th {
        background-color: #fff!important
    }
    .table-bordered td,
    .table-bordered th {
        border: 1px solid #ddd!important
    }
}


/*# sourceMappingURL=bootstrap.min.css.map */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    font-family: "Poppins", sans-serif;
}

button {
    font-family: "Poppins", sans-serif;
}

textarea {
    font-family: "Poppins", sans-serif;
}

a,
a:hover {
    text-decoration: none;
    color: inherit;
}


/* CSS For Remove Arrows From Input Type Number  */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}


/* CSS For Remove Arrows From Input Type Number  */

@media (min-width: 992px) {
    .col-lg-25 {
        flex: 0 0 auto;
        width: 20%;
    }
}


/* Desktop Header CSS  */

.primary_header {
    padding: 8px 0;
}

.primary_header_resp {
    display: none;
}

.website_logo a img {
    width: 100%;
    max-width: 160px;
}

.website_search {
    position: relative;
}

.website_search input {
    width: 100%;
    padding: 7px 10px;
    border: 1px solid #d1d1d1;
    outline: none;
    border-radius: 6px;
}

.website_search button {
    position: absolute;
    right: 9px;
    top: 7px;
    color: #757575;
    background-color: #fff;
    border: none;
    font-size: 1rem;
    padding-left: 2px;
}

.action_buttons {
    text-align: right;
}

.action_buttons span a {
    color: #000;
    margin-left: 15px;
    font-weight: 500;
    font-size: 0.9rem;
}

.action_buttons span a i {
    font-size: 1.2rem;
}

.action_buttons span {
    display: inline-block;
}

.header_text_resp {
    padding-left: 2px;
}

.desktop_menu {
    background-color: #000;
}

.desktop_menu_list ul {
    padding: 0;
    margin-bottom: 0;
}

.desktop_menu_list ul li {
    display: inline-flex;
    padding: 10px 20px;
    margin: 0;
    line-height: none;
}

.desktop_menu_list ul li a {
    color: #fff;
    font-size: 1.2rem;
    font-weight: 400;
}

.desktop_menu_list ul li:hover {
    background-color: #ffc600;
}

.desktop_menu_list ul li:hover a {
    color: #000;
}

a.header_comm_box {
    align-items: center;
    font-weight: 400;
    text-transform: uppercase;
    float: right !important;
    font-size: 16px !important;
}


/* Desktop Header CSS  */


/* Mobile Header install app CSS  */

.head_app_install_box {
    background-color: #222121;
    padding: 10px 12px;
    /* display: flex; */
    justify-content: space-between;
    color: #fff;
    display: none;
    align-items: center;
}

.head_app_install_box>span>span {
    color: #14ff12;
    font-weight: 600;
    font-size: 17px;
    align-items: center;
}

.install_btn_box {
    padding-left: 5px;
}

.install_btn_box a {
    align-items: center;
    color: #fff;
}

.install_btn_box a:hover {
    color: #fff;
}

.install_btn_box a button {
    background-color: #04b302;
    border: none;
    padding: 3px 8px;
    border-radius: 5px;
    font-weight: 500;
    /* text-decoration: underline; */
    align-items: center;
    color: inherit;
    display: flex;
}

.install_btn_box a button i {
    margin-right: 5px;
    font-size: 44px;
}


/* Mobile Header install app CSS  */


/* Mobile Main Navbar Bottom CSS  */

.mob_bottom_menu {
    display: none;
}

.mob_bottom_menu {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 55px;
    background: #222121;
    padding: 0px 10px;
    z-index: 99;
    box-shadow: 0px -3px 10px 0 #6c6b6b;
}

.mob_bottom_menu ul {
    list-style: none;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    height: 100%;
    width: 100%;
}

.mob_bottom_menu ul li {
    font-size: 0.7rem;
    flex: 0 0 auto;
    width: 20%;
}

.mob_bottom_menu ul li a {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    font-size: inherit;
    text-decoration: none;
}

.mob_bottom_menu ul li a span {
    color: #d7d7d7;
    font-size: inherit;
    margin-top: 4px;
    display: block;
}

.mob_bottom_menu ul li img {
    width: 25px;
}

.mob_menu_community a {
    margin-top: -30px;
}

.mob_menu_community img {
    background-color: #fff;
    padding: 10px;
    width: 50px !important;
    box-shadow: 0 0 8px 0 #333;
    top: -50px;
    border-radius: 30px;
}


/* Mobile Main Navbar Bottom CSS  */


/* Banner CSS */

.home_slider {
    padding: 1rem;
    /* background-color: #eae6dd; */
    /* background-color: #000; */
    height: 30rem;
}

.red_carpet_r {
    max-height: 30rem;
    object-fit: cover;
    width: auto;
    /* background-color: #000; */
    margin: auto;
}

.slide-container {
    background-color: #000;
}

.section_slider_img img {
    width: 100% !important;
    display: block;
    margin: 0 auto;
}

.section_slider_text h1 {
    font-size: 7rem;
    font-family: 'Pacifico', cursive;
    color: #000;
    font-weight: 100;
    font-style: italic;
}

.section_slider_text {
    text-align: center;
    background: -webkit-linear-gradient( 315deg, rgba(191, 149, 63, 1) 20%, rgba(252, 246, 186, 1) 35%, rgba(179, 135, 40, 1) 50%, rgba(251, 245, 183, 1) 65%, rgba(170, 119, 28, 1) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section_title h3 span {
    color: #000;
    text-shadow: none;
    font-weight: 400;
    float: right;
}


/* .section_slider_text h3 {
    font-size: 1.25rem;
    font-weight: 400;
} */

.section_slider_text h2 {
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1rem;
    font-size: 2rem;
}

.section_slider_text h3 {
    font-weight: 500;
    text-transform: uppercase;
}

.section_slider_text button {
    font-size: 1.25rem;
    background-color: transparent;
    color: #fff;
    font-weight: 400;
    padding: 8px 20px;
    border: none;
    margin-top: 1rem;
    border-radius: 2px;
}

.section_slider_text button a {
    color: #fff;
}

.brand-carousel .section_slider_text button a {
    color: #ffc600;
}

.brand-carousel .owl-dots {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.brand-carousel .owl-theme .owl-dots .owl-dot span {
    width: 15px;
    height: 15px;
}


/* Banner CSS */


/* Categories Circle Section No. 2 */

.category_circle {
    margin-top: 15px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    position: relative;
}

.home_community_box {
    text-align: center;
}

.home_community_box img {
    width: 100%;
    height: 196px;
    border-radius: 9px;
    object-fit: cover;
    box-shadow: 1px 0px 9px -2px #333;
}

.category_circle img {
    width: 100%;
    height: 130px;
    border-radius: 9px;
    object-fit: cover;
}

.img_bg_dark {
    background-size: cover;
    background-color: #000;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    position: absolute;
    border-radius: 9px;
}

.category_circle h3 {
    font-size: 24px;
    position: absolute;
    font-weight: 400;
    margin-bottom: 0;
}

.home_community_box h3 a {
    font-size: 1.25rem;
    font-weight: 300;
    color: rgb(26, 26, 26);
}

.section_title {
    position: relative;
}

.category_circle h3 a {
    font-size: 1.25rem;
    font-weight: 500;
    color: rgb(26, 26, 26);
}

.section_title h3 {
    /* text-align: center; */
    /* text-transform: uppercase; */
    font-size: 2rem;
    font-weight: 600;
    /* text-shadow: 1px 1px 0px #e3c17d; */
}

.section_title::after {
    content: "";
    width: 100%;
    height: 3px;
    /* border-radius: 5px; */
    position: absolute;
    background-color: #000;
    bottom: 0;
}

.section_separator {
    content: "";
    width: 150px;
    height: 3px;
    border-radius: 5px;
    position: absolute;
    background-color: #000;
    /* left: 44%; */
    bottom: -10%;
}


/* Categories Circle Section No. 2 */


/* Section 3 CSS */

.featured_sale_banner a img {
    width: 100%;
    border-radius: 2px;
}


/* Section 3 CSS */


/* CSS For Product Card */

.prduct_card {
    /* border: 1px solid #e6e6e6; */
    margin-bottom: 20px;
    position: relative;
    border-radius: 9px;
    box-shadow: 0px 3px 11px -4px grey;
}

span.wishlist_icon {
    position: absolute;
    display: flex;
    width: 31px;
    height: 31px;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    top: 13px;
    right: 10px;
    box-shadow: 0 0 10px 0;
    cursor: pointer;
}

span.wishlist_icon i {
    color: #aaaaaa;
}

span.wishlist_icon i:hover {
    color: red;
}

.prduct_card a img {
    width: 100%;
    height: 245px;
    object-fit: cover;
    object-position: top;
    /* border-radius: 9px 9px 0 0; */
}


/* @media (min-width: 576px) {
    .prduct_card a img {
        height: 244px;
        object-fit: cover;
        object-position: top;
    }
}

@media (max-width: 575px) {
    .prduct_card a img {
        height: 320px;
        object-fit: cover;
        object-position: top;
    }
}

@media (max-width: 375px) {
    .prduct_card a img {
        height: 220px;
    }
}

@media (max-width: 320px) {
    .prduct_card a img {
        height: 180px;
    }
} */

.product_price_title {
    padding: 10px;
    text-align: center;
}

.product_heading {
    padding: 10px;
    text-align: center;
    background: black;
    border-radius: 9px 9px 0 0;
}

.product_heading h3 {
    line-height: 0.8rem;
    margin-bottom: 0;
    text-transform: uppercase;
}

.product_heading h3 a {
    font-size: 0.9rem;
    color: #fff !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    -webkit-box-orient: vertical;
    font-weight: 500;
    /* margin-bottom: 10px; */
    cursor: pointer;
}

.product_heading h3 a:hover {
    color: #d3d3d3!important;
}

.product_price_title h3 {
    line-height: 1.25rem;
    text-transform: uppercase;
    margin-bottom: 0;
}

.product_price_title h3 a {
    font-size: 0.9rem;
    color: rgb(77, 77, 77);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    -webkit-box-orient: vertical;
    font-weight: 500;
    margin-bottom: 10px;
}

.product_price_title h3 a:hover {
    color: rgb(0, 0, 0);
}

span.current_price {
    font-weight: 700;
    color: #c22704;
    margin-right: 10px;
    font-size: 14px;
}

span.actual_price {
    font-weight: 500;
    color: #777777;
    text-decoration: line-through;
    margin-right: 10px;
    font-size: 13px;
    display: inline-block;
}

span.percent_price {
    font-weight: 500;
    color: #000000;
    text-transform: uppercase;
}

.product_reviews_stars {
    line-height: 0.6;
}


/* CSS For Product Card */


/* CSS For Featured Banner  */

.featured_advertisement a img {
    width: 100%;
    margin: auto;
}


/* CSS For Featured Banner  */


/* CSS For Brand Carousel */

.brand-carousel button {
    display: none !important;
}


/* CSS For Brand Carousel */


/* CSS For Customer Pledge Section  */

.pledge {
    background-color: #222121;
    border-bottom: 1px solid #4a4a4a;
    /* background: linear-gradient( 315deg, rgba(191, 149, 63, 1) 12%, rgba(252, 246, 186, 1) 35%, rgba(179, 135, 40, 1) 50%, rgba(251, 245, 183, 1) 75%, rgba(170, 119, 28, 1) 94%); */
}

.pledge_box {
    text-align: center;
    margin: 1rem 0;
    text-align: center;
    /* border: 1px solid #d3d3d3; */
    box-shadow: 0px 0px 4px 0px #dbdbdb;
    border-radius: 5px;
    padding: 8px 5px;
}

.pledge_box:not(:last-child) {
    border-radius: 2px solid #000;
}

.pledge_box i {
    font-size: 26px;
    color: #d3d3d3;
}

.pledge_box h3 {
    font-size: 14px;
    color: #d3d3d3;
    margin-top: 8px;
    margin-bottom: 0;
    font-weight: 400;
}


/* .border_box {
    border-right: 2px solid #000;
} */


/* CSS For Customer Pledge Section  */


/* CSS For Footer  */

.footer_column {
    position: relative;
}

.footer_mob_resp {
    display: none;
    background-color: #d3d3d3;
}

.footer_column ul li:after {
    position: absolute;
    content: "";
    width: 0;
    left: 0;
    top: 21px;
    border-radius: 5px;
    height: 2px;
    background-color: #d7d7d7;
    transition: 0.25s ease-in-out;
}

.footer_column ul li:hover:after {
    width: 20%;
}

.footer_column h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #d7d7d7;
    border-bottom: 1px solid #292929;
    /* box-shadow: 0px 5px 10px -7px #dbdbdb; */
    /* color: #e1cb83; */
    padding-bottom: 5px;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.footer_column ul {
    padding: 0;
    margin-bottom: 0;
}

.footer_column ul li {
    list-style: none;
    font-size: 1rem;
    margin: 10px 0;
    position: relative;
    display: block;
}

.footer_column ul li a {
    color: #c9c9c9;
}

.footer_column ul li a {
    text-decoration: none;
}

.follow_us {
    line-height: 0;
}

.follow_us span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    width: 40px;
    height: 40px;
    /* background-color: #fff; */
    /* background: linear-gradient( 300deg, rgba(191, 149, 63, 1) 25%, rgba(252, 246, 186, 1) 100%); */
    background: linear-gradient(300deg, #a7a7a7 25%, #ffffff 100%);
    color: #000;
    margin-bottom: 20px;
    margin-right: 5px;
    transition: 0.5s ease-in-out;
}

.follow_us span i {
    color: #000;
    font-size: 20px;
}

.footer_column_resp a img {
    border-radius: 5px;
    box-shadow: 0px 0px 7px 2px #878787;
}

.footer_credit p {
    color: #fff;
}

.footer_credit p a {
    color: #c9c9c9;
    margin-left: 5px;
    font-size: 1rem;
    font-weight: 500;
}

span.facebook_icon:hover {
    background-color: #4267b2;
}

span.instagram_icon:hover {
    background-color: #c13584;
}

span.twitter_icon:hover {
    background-color: #1da1f2;
}

span.youtube_icon:hover {
    background-color: #ff0000;
}

.footer_column a img {
    width: 110px;
    margin-bottom: 8px;
    margin-right: 12px;
    border-radius: 5px;
    box-shadow: 0px 0px 7px 0px #7a7a7a;
}

.footer_bg_dark {
    background-color: #000;
}

.footer_credit {
    text-align: center;
    background-color: #000;
    box-shadow: 0px 4px 10px 0px #fff;
}

.footer_credit img {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.footer_credit p {
    font-size: 0.9rem;
    padding: 10px 0;
    margin-bottom: 0;
}


/* CSS For Footer  */


/* ========================================= CSS For Product Listing Page =============================================== */

.product_filter .collapsible {
    background-color: #f1f1f1;
    color: #000;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid #e6e6e6;
    border-top: 1px solid #e6e6e6;
}

.community_resp_search,
.community_filter {
    display: none;
}

.community_filter {
    overflow-x: scroll;
    background-color: #222121;
    margin-bottom: 10px;
    padding: 1px 2px;
    border-radius: 5px;
}

.community_filter button {
    background-color: #f5f5f5;
    padding: 1px 6px;
    display: inline-block;
    margin: 6px;
    /* box-shadow: 0 0 13px -5px; */
    color: #333;
    border-radius: 32px;
    border: 1px solid #a5a5a5;
    width: -webkit-max-content;
    width: max-content;
}


/* width */

.community_filter::-webkit-scrollbar {
    width: auto;
    height: 7px;
}


/* Track */

.community_filter::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}


/* Handle */

.community_filter::-webkit-scrollbar-thumb {
    background: #959595;
    border-radius: 10px;
}


/* Handle on hover */

.community_filter::-webkit-scrollbar-thumb:hover {
    background: #b30000;
}

.community_resp_search {
    background-color: #222121;
    padding: 8px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.community_resp_search input {
    width: 100%;
    border-radius: 6px;
    outline: none;
    border: none;
    /* box-shadow: 0 0 13px -3px; */
    padding: 1px 5px;
    height: 30px;
    background-color: #f5f5f5;
    color: #333;
}

.community_resp_search select {
    width: 100%;
    padding: 1px 5px;
    height: 30px;
    outline: none;
    border: none;
    /* box-shadow: 0 0 13px -3px; */
    border-radius: 6px;
    background-color: #f5f5f5;
    color: #333;
}

.product_filter .active,
.collapsible:hover {
    background-color: #e8e8e8;
}

.product_filter .collapsible:after {
    content: "\002B";
    color: #000;
    font-weight: bold;
    float: right;
    margin-left: 5px;
}

.product_filter .active:after {
    content: "\2212";
}

.product_filter .content {
    padding: 8px 18px;
    width: 100%;
    /* max-height: 100; */
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    background-color: #f6f6f6;
}

.product_filter .content input {
    margin: 6px 5px 6px 0;
}

.product_filter .content div {
    clear: left;
    margin: 6px 0;
}

.product_filter .content div label {
    margin-bottom: 0;
}

.product_filter {
    background-color: #f1f1f1;
}

.product_filter h3 {
    padding: 9px 9px 9px;
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 0;
}

.filter_title {
    position: relative;
}

.filter_title span {
    position: absolute;
    right: 15px;
    top: 6px;
    font-size: 1.25rem;
}


/* Filter Accordion styles */

.tabs {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
}

.tab {
    width: 100%;
    color: white;
    overflow: hidden;
}

.tab-label {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    background: #2c3e50;
    font-weight: bold;
    cursor: pointer;
    /* Icon */
}

.tab-label:hover {
    background: #1a252f;
}

.tab-label::after {
    /* content: "❯"; */
    width: 1em;
    height: 1em;
    text-align: center;
    transition: all 0.35s;
}

.tab-content {
    max-height: 0;
    padding: 0 1em;
    color: #2c3e50;
    background: white;
    transition: all 0.35s;
}

.tab-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: #2c3e50;
    cursor: pointer;
}

.tab-close:hover {
    background: #1a252f;
}

input:checked+.tab-label {
    background: #1a252f;
}


/* input:checked + .tab-label::after {
    transform: rotate(90deg);
  } */

input:checked~.tab-content {
    max-height: 100vh;
    padding: 1em;
}

.sorting_bar {
    padding: 10px;
    background-color: #f1f1f1;
    margin-bottom: 20px;
}

.sorting_bar h3 {
    font-size: 1.1rem;
    margin-bottom: 0;
    font-weight: 600;
}

.sorting_bar select {
    float: right;
    border: 1px solid #cfcfcf;
    outline: none;
    padding: 3px 5px;
    border-radius: 1px;
    font-size: 1rem;
}

.pagination_bar {
    text-align: right;
}

.pagination_bar button {
    border: none;
    padding: 8px 15px;
    background-color: #f1f1f1;
    color: #000;
    font-size: 1.15rem;
    text-align: center;
    border-radius: 2px;
    margin-left: 5px;
}

.pagination_bar button:hover {
    background-color: #000;
    color: #fff;
}


/* ========================================= CSS End For Product Listing Page =============================================== */


/* ========================================= CSS Start For Product Page =============================================== */

.product_brief h3 {
    font-size: 28px;
    font-weight: 400;
    color: #333;
    margin-top: 5px;
}

span.product_current_price {
    font-weight: 500;
    color: #c22704;
    margin-right: 10px;
    font-size: 21px;
}

span.product_price_text {
    margin-right: 5px;
    color: #6c706e;
}

span.product_actual_price {
    font-weight: 400;
    color: #6c706e;
    text-decoration: line-through;
    margin-right: 10px;
    font-size: 18px;
}

span.rating_count {
    margin-left: 6px;
}

span.product_percent_price {
    padding: 3px 8px;
    background-color: #a37e00;
    color: #fff;
    border-radius: 2px;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 500;
}

.product_textile {
    text-align: center;
    border: 1px solid #c3c4ca;
    padding: 4px;
    margin-right: 7px;
}

.product_textile h3 a {
    color: #333;
    font-size: 14px;
    font-weight: 500;
}

.product_type_btn {
    font-weight: 400;
    color: #333340;
    display: flex;
}

.product_type_one {
    padding: 5px 17px;
    border: 1px solid #000;
    background-color: #000;
    color: #fff;
}

.product_type_two {
    padding: 5px 17px;
    border: 1px solid #000;
}

.product_textile h3 {
    -webkit-margin-before: -0.6rem;
            margin-block-start: -0.6rem;
    -webkit-margin-after: -0.2em;
            margin-block-end: -0.2em;
}

.product_textile_box {
    display: flex;
    margin-bottom: 22px;
}

.product_textile img {
    width: 90px;
    height: 60px;
    object-fit: cover;
}

.brand_name_product {
    text-transform: uppercase;
    font-size: 21px;
    background-color: #000;
    color: #fff;
    padding: 2px 9px;
}

.product_preview_pricing {
    display: flex;
    align-items: center;
}

.product_brief {
    padding: 0 0 15px;
    border-bottom: 1px solid #e2e2e2;
    margin-bottom: 15px;
}

.product_preview img.xzoom5 {
    width: 100% !important;
}

.xzoom-thumbs {
    text-align: left !important;
    margin: 10px 0;
}

.xzoom-gallery,
.xzoom-gallery2,
.xzoom-gallery3,
.xzoom-gallery4,
.xzoom-gallery5 {
    margin-left: 0 !important;
}

.product_rating span {
    color: #ffc600;
}

span.rating_count a {
    color: #333;
    font-weight: 400;
    text-decoration: underline;
    font-size: 14px;
}

.product_size {
    display: flex;
    margin-bottom: 15px;
}

.out_of_stock {
    margin-bottom: 10px;
    background-color: #c70101;
    color: #fff;
    padding: 0 4px;
    display: inline-block;
    border-radius: 3px;
    font-size: 14px;
}

.product_size span {
    display: flex;
    height: 45px;
    min-width: 45px;
    margin-right: 8px;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    border-radius: 50%;
    font-weight: 500;
    background-color: #fff;
    border: 1px solid #bfc0c6;
    color: #000;
}

.product_description {
    padding: 25px 0 10px;
}

.product_description p {
    font-size: .9rem;
    line-height: 1.8em;
    color: #0F1111;
}

.product_description ul {
    /* padding-left: 15px; */
    margin-top: 10px;
}

.product_description ul li {
    font-size: .9rem;
    line-height: 1.8em;
    color: #0F1111;
    list-style: none;
}

.product_description ul li:before {
    content: "\f00c";
    font-family: FontAwesome;
    margin-right: 5px;
}

h3.choose_size {
    font-weight: 500;
    margin-bottom: 10px;
    color: #717785;
    font-size: 1rem;
}

.preview_button button {
    width: 100%;
    padding: 8px;
    border-radius: 2px;
    border: none;
    background-color: #a37e00;
    color: #fff;
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
}

.preview_button button:hover {
    background-color: #a37e00c7;
}

.preview_wish_button button {
    width: 100%;
    padding: 8px;
    border-radius: 2px;
    border: none;
    background-color: #7f7f7f;
    color: #fff;
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
}

.preview_wish_button button:hover {
    background-color: #6d6d6d;
}

.artists_row_product {
    border-top: 1px solid #c3c4ca;
}

.artists_box {
    display: flex;
    text-align: center;
    font-weight: 500;
    padding-top: 1rem;
}

.artists_center {
    float: right;
}

.artists_category {
    width: 50%;
    color: #717785;
}

.artists_name {
    width: 50%;
    color: #333
}

.artists_center {
    color: #717785;
}


/* CSS For Similar Product Section  */

.smilar_product_list {
    margin-top: 50px;
    border-top: 1px solid #f1f1f1;
    padding: 20px 0;
}

.simila_product_card {
    margin: 10px 2px;
}

.simila_product_card,
.product_price_title h3 a {
    margin-bottom: 0;
}

@media (min-width: 576px) {
    .simila_product_card a img {
        height: 200px;
        object-fit: cover;
        object-position: top;
    }
}

.smilar_product_list h2 {
    font-weight: 600;
    margin-bottom: 10px;
}

.similar_product_carousel {
    position: relative;
}

.similar_product_carousel .owl-nav button span {
    background-color: #000;
    padding: 2px 15px;
    font-size: 1.5rem;
    color: #fff;
}

.similar_product_carousel .owl-nav {
    position: absolute;
    top: -56px;
    right: -3px;
}

.similar_product_carousel .owl-dots {
    margin-top: 20px;
}


/* CSS For Similar Product Section  */


/* CSS For Review Section  */

.write_review textarea {
    width: 100%;
    min-height: 200px;
    padding: 10px;
    outline: none;
    border: 1px solid #e6e6e6;
    font-family: "Poppins", sans-serif;
    margin-top: 10px;
}

.write_review button {
    width: 100%;
    padding: 7px;
    border: none;
    border-radius: 2px;
    background-color: #04b302;
    font-size: 1rem;
    color: #fff;
}

.write_review button:hover {
    background-color: #04a102;
}

.total_reviews {
    border-top: 1px solid #e6e6e6;
}

.total_reviews h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0;
    line-height: 1em;
}

.total_reviews span {
    font-size: .9rem;
    color: #4c4c4c;
}

.rating_counts {
    margin-top: 20px;
    margin-bottom: 20px;
}

.rating_counts p i {
    color: #ffc600;
    float: right;
}

.rating_counts p {
    color: #4c4c4c;
    font-size: .9rem;
}

.rating_counts p span {
    font-weight: 600;
}

.write_review h3 {
    font-weight: 600;
    margin-bottom: 5px;
}

.write_review {
    border-top: 1px solid #e6e6e6;
    padding-top: 15px;
}


/* CSS For Star Rating  */


/****** Style Star Rating Widget *****/

.rating {
    border: none;
    float: left;
}

.rating>input {
    display: none;
}

.rating>label:before {
    margin-right: 5px;
    font-size: 1.25em;
    font-family: FontAwesome;
    display: inline-block;
    content: "\f005";
}

.rating>.half:before {
    content: "\f089";
    position: absolute;
}

.rating>label {
    color: #ddd;
    float: right;
}


/***** CSS Magic to Highlight Stars on Hover *****/

.rating>input:checked~label,

/* show gold star when clicked */

.rating:not(:checked)>label:hover,

/* hover current star */

.rating:not(:checked)>label:hover~label {
    color: #ffc600;
}


/* hover previous stars in list */

.rating>input:checked+label:hover,

/* hover current star when changing rating */

.rating>input:checked~label:hover,
.rating>label:hover~input:checked~label,

/* lighten current selection */

.rating>input:checked~label:hover~label {
    color: #FFED85;
}


/* CSS End For Star Rating  */


/* CSS For All Reviews  */

.read_reviews {
    border-top: 1px solid #e6e6e6;
}

.read_reviews h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0;
    line-height: 1em;
}

.name_reviews {
    display: flex;
    align-items: center;
}

.name_reviews img {
    width: 35px;
    height: 35px;
    object-position: top;
    object-fit: cover;
    margin-right: 10px;
    border: 1px solid #cbcbcb;
}

.name_reviews h3 {
    font-size: .9rem;
    font-weight: 400;
}

.review_content span i {
    font-size: .9rem;
    color: #ffc600;
}

.single_review {
    padding: 15px 0;
    border-bottom: 1px solid #e6e6e6;
}

.name_reviews p {
    font-size: .75rem;
    color: #949494;
    margin-top: 3px;
}

.review_content p {
    font-size: .9rem;
    margin: 8px 0;
    color: #4c4c4c;
}


/* CSS End For All Reviews  */


/* CSS For Review Section  */


/* ========================================= CSS End For Product Page =============================================== */


/* ============================================ CSS Start For About Us Page ========================================== */

.about_text p {
    margin: 10px 0;
    text-align: justify;
    font-weight: 400;
    font-size: .95rem;
}

.about_img img {
    width: 100%;
    border-radius: 0px;
    box-shadow: 10px 10px 0px 0px #ffc600;
}

.about_text h3 {
    font-size: 2rem;
    font-weight: 700;
    color: #000000;
    text-shadow: 2px 1px 0px #ffc600;
}


/* ============================================ CSS End For About Us Page ========================================== */


/* ============================================ CSS Start For My Account Page ========================================== */

.tab {
    background-color: #f1f1f1;
}

.tab button {
    display: block;
    background-color: inherit;
    color: black;
    padding: 15px;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;
    font-size: 1rem;
    font-weight: 500;
}

.tab button:hover {
    background-color: #ddd;
}

.tab button.active {
    background-color: #a37e00;
    color: #fff;
}

.tabcontent {
    background-color: #f1f1f1;
    padding: 20px 50px;
    height: 100%;
}

.my_profile_tab h2 {
    font-weight: 500;
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.tab button span i {
    width: 25px;
    font-size: 20px;
}

.my_profile_tab input {
    width: 100%;
    padding: 12px 10px;
    border: 1px solid #aaaaaa;
    outline: none;
    margin: 7px 0;
}

.my_profile_tab select {
    width: 100%;
    padding: 11px 10px;
    border: 1px solid #aaaaaa;
    outline: none;
    margin: 7px 0;
}

.my_profile_tab h4 {
    font-weight: 500;
    font-size: .9rem;
}

.my_profile_tab button {
    padding: 10px;
    width: 100%;
    border: none;
    margin-top: 5px;
    background-color: #000000;
    color: #fff;
    font-size: 1rem;
}

.my_profile_tab table {
    border-collapse: collapse;
    width: 100%;
    background-color: #fff;
}

.my_order_table {
    overflow-x: scroll;
    /* overflow: hidden; */
}

.my_profile_tab td,
th {
    border: 1px solid #aaaaaa;
    text-align: left;
    padding: 8px;
    text-align: center;
}

.my_profile_tab select {
    width: 100%;
    padding: 11px 6px;
    border: 1px solid #aaaaaa;
    outline: none;
    margin: 7px 0;
    height: 52px;
}

.my_profile_tab select option {
    margin: 13px 0;
}


/* .my_profile_tab select:focus {
    margin: 5px 0;
} */


/* 
.login_signup_form select::placeholder {
    font-family: FontAwesome;
} */


/* .my_profile_tab tr:nth-child(even) {
  background-color: #fff;
}

.my_profile_tab tr:nth-child(odd) {
  background-color: #f1f1f1;
} */


/* ============================================ CSS End For My Account Page ========================================== */


/* CSS Start For Order History Card  */

.order_history_tab_content {
    background-color: #f1f1f1;
    padding: 20px 20px;
    height: 100%;
}

.order_history_prodct_card {
    border: 1px solid #e6e6e6;
    margin-bottom: 20px;
    position: relative;
    border-radius: 9px;
    padding: 20px;
    box-shadow: 0px 3px 11px -4px grey;
    background-color: #f7f7f7;
}

.order_history_prodct_card img {
    width: 100%;
    border-radius: 10px;
    vertical-align: middle;
    height: 12rem;
    object-position: top;
    object-fit: cover;
}

.order_status_detail {
    margin-bottom: 0px;
    color: #333;
}

.order_card_details h4 {
    font-size: 18px;
    margin-bottom: 2px;
}

.order_card_details h3 {
    text-transform: uppercase;
    font-size: 23px;
}

.order_purchase_time_date {
    color: #333;
    line-height: 1;
}

.order_product_quantity {
    font-weight: 500;
    margin-bottom: 1px;
}

.order_status_processing {
    font-weight: 600;
    color: #72bb1d;
    margin-bottom: 0;
}

.order_status_dispatched {
    font-weight: 600;
    color: #e3b107;
    margin-bottom: 0;
}

.order_status_cancel {
    font-weight: 600;
    color: #db1e1e;
    margin-bottom: 0;
}

.order_purchase_price {
    margin-bottom: 2px;
    font-weight: 900;
}

.order_card_details button {
    color: #fff;
    font-weight: 500;
    background-color: #000;
    padding: 6px 16px;
    border-radius: 7px;
    border: none;
    outline: none;
    box-shadow: 0px 0px 4px 0px #000;
    margin-top: 10px;
}


/* =============================== CSS End For Order History Card ============================ */


/* =============================== CSS Start For Order Rating Card =========================== */

.order_rating_card button {
    color: #fff;
    font-weight: 500;
    background-color: #000;
    padding: 6px 16px;
    border-radius: 7px;
    border: none;
    outline: none;
    box-shadow: 0px 0px 4px 0px #000;
    margin-top: 10px;
}

.order_rating_card h4 {
    font-size: 18px;
    margin-bottom: 2px;
}

.order_rating_card h3 {
    text-transform: uppercase;
    font-size: 23px;
}

.order_rating_card input {
    width: 100%;
    padding: 7px 10px;
    border: 1px solid #d1d1d1;
    outline: none;
    border-radius: 2px;
    background-color: #fff;
}

.order_rating_card input::-webkit-input-placeholder {
    color: #757575;
}

.order_rating_card input:-ms-input-placeholder {
    color: #757575;
}

.order_rating_card input::placeholder {
    color: #757575;
}


/* .order_ratings_stars {
    line-height: ;
} */


/* =============================== CSS Start For Order Rating Card =========================== */


/* =============================== CSS Start For My Wishlist Page ============================ */

button.wish_cart_btn {
    width: 100%;
    padding: 8px;
    border-radius: 2px;
    border: none;
    background-color: #20201d;
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    margin-top: 10px;
}

button.wish_cart_btn i {
    margin-left: 10px;
}

button.wish_cart_btn:hover {
    background-color: #444444;
}


/* =============================== CSS End For My Wishlist Page ============================ */


/* =============================== CSS Start For My Cart Page ============================ */

.cart_page {
    background-color: #f1f1f1;
}

.my_cart_title {
    background-color: #fff;
    padding: 15px;
}

.my_cart_title span {
    float: right;
    color: #7f7f7f;
    font-weight: 400;
}

.my_cart_title h3 {
    font-size: 1rem;
    font-weight: 600;
}

.cart_table {
    background-color: #fff;
    margin-top: 10px;
    padding: 10px;
}

.cart_items {
    position: relative;
    margin: 5px;
    border: 1px solid #e6e6e6;
}

.cart_items img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    border-right: 1px solid #e6e6e6;
}

.cart_items span.current_price {
    font-size: 1.25rem;
    font-weight: 600;
}

.cart_items span.actual_price {
    font-weight: 400;
    font-size: .9rem;
}

.cart_items h3 a {
    font-weight: 400;
    font-size: 1rem;
    color: #3d3d3d;
}

.cart_quantity {
    margin-top: 10px;
}

.cart_quantity label {
    font-size: .9rem;
    color: #3d3d3d;
}

.cart_quantity select {
    border: 1px solid #e6e6e6;
    padding: 3px;
    outline: none;
    margin-left: 5px;
}

.remove_item span {
    position: absolute;
    right: 20px;
    top: 15px;
}

.cart_items button {
    padding: 5px 10px;
    border-radius: 2px;
    border: 1px solid #e6e6e6;
    background-color: #ffffff;
    color: #656565;
    font-size: .7rem;
    font-weight: 400;
    margin-top: 18px;
    margin-bottom: 10px;
}

.cart_items button:hover {
    color: #000;
}

.checkout_amount {
    background-color: #fff;
    padding: 15px;
}

.checkout_amount h3 {
    font-size: 1rem;
    font-weight: 600;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 10px;
}

.checkout_amount h4 {
    font-weight: 500;
    font-size: 1.15rem;
    margin-top: 15px;
    margin-bottom: 0;
}

.checkout_amount h4 span {
    float: right;
}

.checkout_amount table td {
    border-bottom: 1px solid #e6e6e6;
    text-align: left;
    padding: 5px 0;
}

.checkout_amount table p {
    font-size: .85rem;
    color: #3d3d3d;
    margin: 10px 0;
}

.checkout_amount table span {
    font-size: .85rem;
    color: #3d3d3d;
    float: right;
}

.checkout_amount table {
    border-collapse: collapse;
    width: 100%;
}

.checkout_amount button {
    width: 100%;
    padding: 8px;
    border-radius: 2px;
    border: none;
    background-color: #000;
    color: #fff;
    font-size: 1.1rem;
    font-weight: 600;
    /* text-transform: uppercase; */
    margin-top: 10px;
}

.checkout_amount button:hover {
    background-color: #a37e00;
}

.checkout_amount img {
    display: block;
    margin: 15px auto 5px;
    width: 80%;
}

.remove_item span i {
    font-size: 20px;
    color: #c22704;
}


/* =============================== CSS End For My Cart Page ============================ */


/* =============================== CSS Start For Checkout Page ============================ */

.delivery_details {
    background-color: #fff;
    padding: 15px;
    margin-top: 10px;
}

.delivery_details_title {
    background-color: #fff;
    padding: 15px;
}

.delivery_details_title h3 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0;
}

.delivery_details input {
    outline: none;
    border: 1px solid #e6e6e6;
    padding: 10px 7px;
    width: 100%;
    margin-bottom: 10px;
}

.delivery_details input::-webkit-input-placeholder {
    color: #bdbdbd;
}

.delivery_details input:-ms-input-placeholder {
    color: #bdbdbd;
}

.delivery_details input::placeholder {
    color: #bdbdbd;
}

.delivery_details input:focus {
    border: 1px solid #000;
}

.delivery_details select:focus {
    border: 1px solid #000;
}

.delivery_details select {
    outline: none;
    border: 1px solid #e6e6e6;
    padding: 9px 4px;
    width: 100%;
    margin-bottom: 10px;
}

.delivery_details button {
    width: 100%;
    padding: 8px;
    border-radius: 2px;
    border: none;
    background-color: #04b302;
    color: #fff;
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 10px;
}

.delivery_details button:hover {
    background-color: #04a102;
}

.delivery_details h3 {
    font-size: .95rem;
    font-weight: 400;
    margin-bottom: 4px;
}


/* =============================== CSS End For Checkout Page ============================ */


/* =============================== CSS Start For Contact Page ============================ */

.contact_us_title {
    background-color: #fff;
    padding: 15px;
}

.contact_us_title h3 {
    font-size: 1rem;
    font-weight: 600;
}

.contact_query_form {
    background-color: #fff;
    padding: 15px;
    margin-top: 10px;
}

.contact_query_form p {
    font-size: .9rem;
    margin-bottom: 10px;
}

.contact_query_form h3 {
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 10px;
}

.contact_query_form input {
    width: 100%;
    padding: 8px;
    border: 1px solid #e6e6e6;
    margin-bottom: 10px;
    outline: none;
}

.contact_query_form input:focus {
    border: 1px solid #000;
}

.contact_query_form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #e6e6e6;
    margin-bottom: 5px;
    outline: none;
    min-height: 200px;
}

.contact_query_form textarea:focus {
    border: 1px solid #000;
}

.contact_query_form button {
    width: 100%;
    padding: 8px;
    border-radius: 2px;
    border: none;
    background-color: #04b302;
    color: #fff;
    font-size: 1.1rem;
    font-weight: 600;
}

.contact_query_form button:hover {
    background-color: #04a102;
}

.contact_informations h3 {
    margin-top: 25px;
    margin-bottom: 5px;
}

.contact_informations h3 i {
    width: 15px;
}

.contact_informations p {
    font-size: .85rem;
}


/* =============================== CSS End For Contact Page ============================ */


/* ============================== CSS Start For Privacy Policy ========================== */


/* .terms_privacy_content h1, h2, h3{
  margin: 15px 0;
  font-weight: 600;
} */

.terms_privacy_content h1 {
    font-size: 2rem;
    font-weight: 600;
    margin: 15px 0;
}

.terms_privacy_content h2 {
    font-size: 1.75rem;
    font-weight: 600;
    margin: 15px 0;
}

.terms_privacy_content h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 15px 0;
}

.terms_privacy_content p {
    margin-bottom: 10px;
    font-size: 1rem !important;
    line-height: 1.8em;
}

.terms_privacy_content ul {
    margin-bottom: 10px;
    padding-left: 20px;
}

.terms_privacy_content ol {
    margin-bottom: 10px;
    padding-left: 20px;
}

.terms_privacy_content ul li {
    line-height: 1.8em;
}

.terms_privacy_content ol li {
    line-height: 1.8em;
}

.terms_privacy_content li {
    font-size: 1rem !important;
}

.terms_privacy_content a {
    text-decoration: underline;
}


/* ============================== CSS End For Privacy Policy ========================== */


/* ============================== CSS Start For Login/Signup ========================== */

.register_page {
    background-color: #f1f1f1;
}

.login_signup_page {
    background-color: #fff;
    /* background-image: url(../images/login_bg.png); */
    padding: 40px;
    box-shadow: 0px 0px 16px 3px #424242;
}

.login_page_bg {
    background-image: url(/static/media/login_bg.c01e3310.png);
    background-size: cover;
    margin-bottom: 2rem;
}

.login_signup_page h2 {
    text-align: center;
    margin-bottom: 1rem;
}

.social_login h3 {
    font-weight: 600;
    text-align: center;
    margin-bottom: 5px;
}

.social_login button {
    width: 100%;
    padding: 7px;
    margin: 7px 0;
    border: none;
    border-radius: 2px;
    color: #fff;
    font-size: 1.1rem;
}

button.facebook_login {
    background-color: #4267B2;
}

button.google_login {
    background-color: #DB4437;
}

.login_signup_form {
    border-left: 1px solid #e6e6e6;
    padding-left: 20px;
}

.login_signup_form input {
    width: 100%;
    padding: 11.5px 10px;
    border: 1px solid #aaaaaa;
    outline: none;
    margin: 9px 0;
    height: 47px;
}

.login_signup_form input::-webkit-input-placeholder {
    color: #000;
    font-size: 16px;
}

.login_signup_form input:-ms-input-placeholder {
    color: #000;
    font-size: 16px;
}

.login_signup_form input::placeholder {
    color: #000;
    font-size: 16px;
}

.signup_display_flex {
    display: flex;
    margin-top: 3px;
    line-height: 1.8;
}

.signup_display_flex label {
    font-size: 1rem;
}

.login_signup_form input:focus {
    border: 1px solid #000;
}

.login_signup_form input[type="checkbox"] {
    width: -webkit-min-content;
    width: min-content;
    margin: 4px 5px 0 0;
    height: auto;
}

.login_signup_form label {
    font-size: .9rem;
}

.login_signup_form button {
    width: 100%;
    padding: 7px;
    border-radius: 3px;
    border: none;
    background-color: #04b302;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    margin: 10px 0;
}

.login_signup_form p {
    text-align: center;
    font-size: .9rem;
    margin-top: 15px;
}

.login_signup_form p a {
    color: #fff;
    font-weight: 500;
    background-color: #000;
    padding: 6px 13px;
    border-radius: 3px;
    margin-left: 5px;
}

.login_signup_form button:hover {
    background-color: #04a102;
}

.login_signup_form select {
    width: 100%;
    padding: 11px 6px;
    border: 1px solid #aaaaaa;
    outline: none;
    margin: 9px 0;
    height: 47px;
}

.login_signup_form select option {
    margin: 15px 0;
}

.login_signup_form select:focus {
    margin: 5px 0;
}

.login_signup_form select::-webkit-input-placeholder {
    font-family: FontAwesome;
}

.login_signup_form select:-ms-input-placeholder {
    font-family: FontAwesome;
}

.login_signup_form select::placeholder {
    font-family: FontAwesome;
}


/* ============================== CSS End For Login/Signup ========================== */


/* ======================== CSS For Forgot Password / Update Password ========================== */

.forgot_password {
    text-align: center;
    padding: 40px;
    background-color: #fff;
}

.forgot_password h3 {
    font-size: 1.25rem;
    margin-bottom: 10px;
}

.forgot_password input {
    width: 100%;
    padding: 10px 8px;
    margin-bottom: 10px;
    border: 1px solid #e6e6e6;
    outline: none;
}

.forgot_password input:focus {
    border: 1px solid #000;
}

.forgot_password button {
    width: 100%;
    padding: 7px;
    border-radius: 2px;
    border: none;
    background-color: #04b302;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
}

.forgot_password button:hover {
    background-color: #04a102;
}

.forgot_password p {
    font-size: .9rem;
    margin-top: 15px;
}


/* ======================== CSS End Forgot Password / Update Password ========================== */


/* ======================== CSS For Payment Status ========================== */

.payment_status {
    padding: 50px;
    background-color: #fff;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.payment_status span i {
    font-size: 5rem;
    color: #04a102;
}

.payment_failed span i {
    font-size: 5rem;
    color: red;
}

.payment_status h3 {
    margin-top: 15px;
    font-size: 1.5rem;
    font-weight: 600;
}

.payment_status p {
    margin-top: 10px;
}

.payment_status p span {
    font-weight: 600;
}


/* ======================== CSS For Payment Status ========================== */


/* ======================== CSS For Whatsapp Floating Button ================ */

.whatsapp_floating_btn {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #000;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 0px 0px 10px 2px #6a6a6a;
    z-index: 100;
}

.whatsapp_floating_btn:hover {
    color: #fff;
    background-color: #333;
}

.whatsapp_floating_btn i {
    margin-top: 16px;
}


/* ===================== CSS For Megamenu ======================= */

.navbar {
    overflow: hidden;
    background-color: #000;
}

.navbar a {
    float: left;
    font-size: 16px;
    font-weight: 500;
    color: white;
    text-align: center;
    padding: 4px 16px;
    text-decoration: none;
}

.dropdown {
    float: left;
    overflow: hidden;
}

.dropdown .dropbtn {
    font-size: 16px;
    border: none;
    outline: none;
    color: white;
    padding: 14px 16px;
    background-color: inherit;
    font: inherit;
    margin: 0;
}

.navbar a:hover,
.dropdown:hover .dropbtn {
    background-color: #d7d7d7;
    color: black;
    box-shadow: 0px 0px 6px 0px #ffffff;
    border-radius: 3px;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    width: 100%;
    left: 0;
    box-shadow: 0px 2px 5px 0px #00000040;
    z-index: 1;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.column a {
    float: none;
    color: black;
    padding: 0;
    text-decoration: none;
    display: block;
    text-align: left;
}

.dropdown_menu_list ul {
    padding: 0;
    margin: 0;
}

.dropdown_menu_list ul li {
    margin: 20px 0px;
    list-style: none;
}

.dropdown_menu_list ul li a {
    font-size: .9rem;
}

.megamenu {
    background-color: #000;
}

.dropdown_menu_list h3 {
    margin: 10px 0;
}

.dropdown-content a:hover {
    background-color: inherit !important;
    text-decoration: underline;
}

.drop_down_banner a {
    padding: 0;
    float: inherit;
}

.drop_down_banner a img {
    width: 100% !important;
}

.drop_down_inner {
    background-color: #fff;
    padding: 10px 11px 7px 20px;
}

.dropdown-content {
    z-index: 9999;
}

button.dropbtn i {
    margin-left: 10px;
}


/* ===================== CSS For Megamenu ======================= */

.MuiAccordion-root.Mui-expanded {
    margin: 0!important;
}

.MuiAccordionDetails-root {
    display: flex;
    padding: 0!important;
}


/* CSS For Hiring Banner Start*/

.hire_banner_bg.text-center {
    background: #000;
    padding: 1rem 0;
    text-align: center;
}

.hire_banner_bg.text-center h3 {
    color: #fff;
    font-size: 1.6rem;
    letter-spacing: 2px;
    margin-bottom: 0;
}

.hire_banner_img_box a button {
    position: absolute;
    bottom: 50px;
    padding: 10px 16px;
    cursor: pointer;
    font-size: 20px;
    background-color: #000;
    border: none;
    box-shadow: 0px 0px 10px 1px #d3d3d3;
    color: #fff;
    /* border: 3px solid #a37e00; */
    border-radius: 35px;
    right: 3em;
}

.hire_banner_img_box {
    position: relative;
    width: 100%;
    height: auto;
}

.hire_banner_img_box a button i {
    padding-right: 12px;
}

.hire_banner_img_box img {
    position: relative;
    width: 100%;
    height: auto;
    object-fit: cover;
}


/* CSS For Hiring Banner End*/


/* CSS For Community Listing Page Start */

.community_filter_box {
    background-color: #fff;
    padding: 12px;
    border: 1px solid #bfc0c6;
    box-shadow: 0px 3px 11px -4px grey;
}

.community_category {
    padding: 8px 0;
}

.community_category input {
    margin-right: 10px;
}

.community_category label {
    margin-bottom: 0;
}

.community_category input[type="checkbox"] {
    height: 24px;
}

.register_community_strip {
    background-color: #000;
    color: #fff;
    padding: 16px 8px;
    text-align: center;
}

.register_community_strip h3 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0;
}

.sign_up_community_btn {
    color: #fff;
    background-color: #b58a2c;
    padding: 5px 12px;
    margin-left: 10px;
    border-radius: 4px;
}

.community_card {
    border: 1px solid #e6e6e6;
    margin-bottom: 20px;
    position: relative;
    border-radius: 9px;
    padding: 20px;
    box-shadow: 0px 3px 11px -4px grey;
    background-color: #f1f1f1;
}

img.freelancer_card_img {
    width: 100%;
    border-radius: 50%;
    vertical-align: middle;
    height: 130px;
}

.comm_flag_box img {
    width: 45px;
    margin-right: 10px;
    margin-bottom: 5px;
    height: 30px;
    box-shadow: 0 0 5px 0 #333;
}

.freelancer_name h4 {
    font-size: 15px;
    font-weight: 500;
    color: #747474;
}

.freelancer_name h3 {
    font-size: 19px;
    font-weight: 600;
    /* margin-top: 7px; */
    color: #333;
}

.freelancer_rank {
    font-weight: 600;
    text-align: right;
    font-size: 22px;
    color: #b58a2c;
}

.freelancer_hire_btn {
    position: absolute;
    right: 13px;
    bottom: 0px;
    color: #fff;
    border-radius: 30px;
    background-color: #000;
    padding: 6px 14px;
    font-weight: 300;
    font-size: 14px;
}

.freelancer_hire_btn:hover {
    color: #fff;
}

.freelancer_hire_btn i {
    margin-right: 5px;
}


/* CSS For Community Listing Page End */


/* CSS for Freelancer Main Page */

.freelaancer_view_page {
    padding: 1rem 0;
}

.freelancer_main_img {
    width: 100%;
    border-radius: 10px;
    vertical-align: middle;
    max-height: 20rem;
    object-position: top;
    object-fit: cover;
}

.comm_flag_main_box img {
    width: 80px;
    margin-right: 19px;
    height: 50px;
    box-shadow: 0 0 5px 0 #333;
}

.freelancer_name_main h3 {
    font-size: 28px;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 15px;
}

.freelancer_name_main h4 {
    font-size: 20px;
    font-weight: 500;
    color: #747474;
}

.freelancer_rank_main {
    font-weight: 600;
    float: right;
    font-size: 30px;
    color: #000;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.freelancer_rank_main img {
    width: 70px;
    object-fit: cover;
}

.freelancer_rank_main span {
    position: absolute;
}

.freelancer_rank {
    font-weight: 600;
    float: right;
    font-size: 30px;
    color: #000;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.freelancer_rank img {
    width: 50px;
    object-fit: cover;
}

.freelancer_rank span {
    position: absolute;
    font-size: 24px;
}

.freelancer_hire_btn_main {
    position: absolute;
    right: 16px;
    bottom: 0px;
    color: #fff;
    border-radius: 30px;
    background-color: #000;
    padding: 6px 18px;
    font-weight: 300;
    font-size: 21px;
    align-items: center;
    justify-content: center;
    line-height: normal;
    line-height: initial;
}

.freelancer_hire_btn_main:hover {
    color: #fff;
}

.freelancer_hire_btn_main i {
    margin-right: 10px;
}

.freelancer_points {
    position: absolute;
    bottom: 0px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0;
}

.freelancer_number {
    position: absolute;
    bottom: 5px;
    font-size: 22px;
    font-weight: 600;
}

.freelancer_products {
    position: relative;
}

.freelancer_products h3 {
    font-size: 2rem;
    font-weight: 700;
    text-shadow: 2px 1px 0px #ffc600;
}

.freelancer_products::after {
    content: "";
    width: 71%;
    height: 3px;
    position: absolute;
    background-color: #000;
    bottom: 0;
    right: 0px;
}


/* CONTACT PAGE CSS START */

.contact_outer_container {
    padding: 3rem 0;
}

.contact_bg_dark {
    background-color: #202020;
}

.contact_banner {
    /* background-image: url(../../images/ContactTitle-Image-1.jpg); */
    padding: 4rem;
}

.contact_kaaf_img {
    text-align: center;
}

.contact_kaaf_img img {
    width: 40%;
}


/* -----------------------my-css----------------- */

.contact_outer_container h5 {
    font-weight: bold;
}

.borders {
    border-right: 2px solid #e2e2e2;
}

.borders_responsive {
    border-right: 2px solid #e2e2e2;
}

.hr {
    border-bottom: 2px solid #e2e2e2;
}


/* .app {
	width: 210px;
	height: auto;
	margin-top: 65px;
} */

.app img {
    width: 90%;
    padding: 25px 15px;
    background-color: #f9f8f4;
    border-radius: 16px;
    margin: 18px 0;
}

.social_box {
    padding: 25px 0px;
    background-color: #f9f8f4;
    border-radius: 16px;
    text-align: center;
    width: 90%;
    margin: 18px auto;
}

.social_box img {
    width: 55px;
    margin-bottom: 13px;
}

.play {
    width: 250px;
    height: auto;
    /* display: flex;
justify-content: center;
align-items: center; */
    margin-top: 50px;
}

.play img {
    width: 100%;
    /* margin-left: 45px; */
}

.followus {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 20px;
}


/* .followus .borders {
	border-right: 2px solid #e2e2e2;
} */

.insta {
    width: 60px;
    height: 80px;
    margin: auto;
}

.youtube {
    width: 60px;
    height: 80px;
    margin: auto;
}

.linkedin {
    width: 60px;
    height: 80px;
    margin: auto;
}

.facebook {
    width: 60px;
    height: 80px;
    margin: auto;
}

.followus .insta-box h5 {
    text-align: center;
}

.followus .youtube-box h5 {
    text-align: center;
}

.followus .linedin-box h5 {
    text-align: center;
}

.followus .facebook-box h5 {
    text-align: center;
}

.insta-box {
    margin-bottom: 24px;
    border-right: 2px solid #8d8585;
    margin-top: 40px;
}

.youtube-box {
    margin-bottom: 24px;
    border-right: 2px solid #8d8585;
    margin-top: 70px;
}

.linedin-box {
    margin-bottom: 24px;
    border-right: 2px solid #8d8585;
    margin-top: 70px;
}

.facebook-box {
    margin-bottom: 24px;
    /* border-right: 2px solid #8d8585; */
    margin-top: 70px;
}

.followus a {
    color: #000;
    font-size: 15px;
    font-weight: 500;
}


/* -------------------contact-us----------------------- */

.contactus {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 20px;
}

.contactus h5 {
    text-align: center;
}

.contactus .call-box h5 {
    text-align: center;
}

.contactus .email-box h5 {
    text-align: center;
}

.contactus .web-box h5 {
    text-align: center;
}

.contactus .location-box h5 {
    text-align: center;
}

.contactus a {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 14px;
    font-weight: bold;
}

.call {
    width: 60px;
    height: 80px;
    margin: auto;
}

.call-box {
    margin-bottom: 24px;
    border-right: 2px solid #8d8585;
    margin-top: 40px;
}

.email {
    width: 60px;
    height: 80px;
    margin: auto;
}

.email-box {
    margin-bottom: 24px;
    border-right: 2px solid #8d8585;
    margin-top: 70px;
}

.web {
    width: 60px;
    height: 80px;
    margin: auto;
}

.web-box {
    margin-bottom: 24px;
    border-right: 2px solid #8d8585;
    margin-top: 70px;
}

.location {
    width: 60px;
    height: 80px;
    margin: auto;
}

.location-box {
    margin-bottom: 24px;
    /* border-right: 2px solid #8d8585; */
    margin-top: 70px;
}


/* ----------------------------FORM-------------------- */

.form {
    padding: 20px;
}

.form h5 {
    font-weight: bold;
}

.contact_outer_container .form-control {
    padding: 1.375rem .75rem;
    height: calc(2.5em + 0.75rem + 2px);
    border-radius: 10px;
}

form {
    margin-top: 20px;
}

.contact_outer_container .form-control::-webkit-input-placeholder {
    color: #a4a4a4;
}

.contact_outer_container .form-control:-ms-input-placeholder {
    color: #a4a4a4;
}

.contact_outer_container .form-control::placeholder {
    color: #a4a4a4;
}

.contact_outer_container button {
    display: inline-block;
    margin-top: 15px;
    width: 100%;
    padding: 11px;
    font-weight: 400;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #000;
    border: 1px solid transparent;
    /* padding: 0.375rem .75rem; */
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.contact_outer_container button:hover {
    color: #fff;
    text-decoration: none;
}


/* .footer_column h3 {
    color: #fff;
    font-weight: 400;
    font-size: 1.35rem;
    /* margin-bottom: 20px; */


/* }  */

.form-controlS {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid #fff;
    background: transparent;
    padding: 1.375rem .75rem;
    height: calc(2.5em + 0.75rem + 2px);
}

.contact_outer_container textarea.form-control {
    height: 194px;
}

.btns {}


/* ======================= CSS For Subcategory Main Page ================ */

.subcategory_main_page {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.subcategory_main_page img {
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.subcategory_main_page h3 {
    position: absolute;
    font-size: 26px;
    color: #fff;
    padding: 7px;
    border-radius: 3px;
    font-weight: 500;
    text-transform: uppercase;
}

.subcategory_title {
    font-size: 22px;
    margin-bottom: 1.5rem;
    color: #d3d3d3;
    background-color: #222121;
    border-radius: 20px 0;
    padding: 8px 16px;
    box-shadow: 0 0 10px 1px #1a1a1a;
}

.subcategory_title i {
    margin: 0 10px;
    font-size: 20px;
}

.sub_img_bg_dark {
    background-size: cover;
    background-color: #000;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    position: absolute;
    border-radius: 20px;
}

.sub_img_bg_dark:hover {
    background-color: rgba(0, 0, 0, .7);
    color: #fff;
}


/* ======================= CSS For Subcategory Main Page ================ */


/* ---------------------MEDIA QUERY------------------------- */

@media (max-width: 768px) {
    .borders {
        border-right: none
    }
}

@media only screen and (max-width: 767px) {
    .text {
        margin: auto;
    }
    .contact_outer_container button {
        margin-top: 20px;
    }
    .borders_responsive,
    .border {
        border: none
    }
}


/* CONTACT PAGE CSS END */


/* Style the form */

.regisform {
    background-color: #0c0c0c;
    margin: 0px auto;
    width: 80%;
    /* min-width: 300px; */
    border-radius: 10px;
    -webkit-backdrop-filter: blur(22px);
            backdrop-filter: blur(22px);
    box-shadow: inset 0 0 0 200px rgba(255, 255, 255, 0.074);
    color: #e6e6e6;
    padding: 25px;
    border-radius: 12px;
    grid-gap: 15px;
    gap: 15px;
    border: 3px solid rgba(255, 255, 255, 0.30);
}

@media (max-width: 1024px) {
    .regisform {
        width: 100%;
        padding: 44px 13px;
    }
}

.regisform .tab {
    background-color: #1e1e1e;
    text-align: center;
}


/* .themodestyshow {
    border: 1px solid black;
    border-radius: 20px;
    padding: 20px;
    margin: 10px;
    line-height: 25px;
    background-image: url("kaaf.png");
    background-position: right;
    background-repeat: no-repeat;
    box-shadow: 6px 6px 7px 2px;
    font-family: monospace;
} */


/* .themodestyshow_content {
  border: 1px solid black;
  border-radius: 20px;
  padding: 20px;
  margin: 10px;
  line-height: 25px;
  background-image: url("kaaf.png");
  background-position: right;
  background-repeat: no-repeat;
  box-shadow: 6px 6px 7px 2px;
  font-family: monospace;
} */

section.tms {
    background-color: black;
    color: white;
}

.themodestyshow {
    /* font-family: 'Open Sans', sans-serif; */
    font-family: 'Poppins', sans-serif;
    line-height: 25px;
    margin: 40px 0px 0px 0px;
    padding: 27px;
    /* border-right: 5px solid;
  border-left: 5px solid;
  border-radius: 52px; */
    /* box-shadow: 5px -10px 10px #f1f1f1; */
}

.themodestyshow h1 {
    /* margin: 19px; */
    padding: 10px 0px;
    text-align: center;
    font-size: 20px;
}

.themodestyshow-3 {
    padding: 10px 10px;
    border: 3px solid #e7ca70;
    border-radius: 20px 0px;
    background-color: #ffffff;
    margin-bottom: 90px;
    color: black;
}

section.sponser_main_main {
    margin: 0px 0px;
    background-image: url(/static/media/new.66af227a.jpg);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

section.benefits {
    background-color: #e1dfdf;
}


/* section.benefits {
  color: white;
  background-color: black;
} */

.tms-3 {
    padding-bottom: 40px;
}

.themodestyshow h3 {
    margin: 12px 0px;
    padding: 10px 0px;
    text-align: center;
    font-size: 20px;
    font-weight: normal;
}

.themodestyshow-2 {
    padding: 0px 30px;
    line-height: 30px;
    font-size: 16px;
    font-weight: 100;
}

.themodestyshow-2 ul {
    padding: 10px 20px;
}

.themodestyshow-2 p {
    /* padding: 10px 10px; */
    margin-bottom: 0;
}

.themodestyshow h1 strong {
    color: #e3c158;
    font-weight: unset;
    font-size: 45px;
}


/* .themodestyshow_content {
  padding: 10px 30px;
  border-right: 5px solid;
  border-left: 5px solid;
  border-radius: 30px;
} */

.sponser {
    padding: 50px 50px;
    text-align-last: center;
    /* filter: blur(); */
    background-color: black;
    opacity: .9;
    color: white;
}

.themodestyshow_content h1 img {
    width: 150px;
    vertical-align: -webkit-baseline-middle;
    padding-bottom: 10px;
}

.themodestyshow_content h1 {
    padding: 0px 20px;
    font-size: 30px;
    font-weight: 600;
}

.themodestyshow_content p {
    padding: 0px 20px;
    line-height: 30px;
    font-size: 16px;
    font-weight: 100;
}

.sponser h1 strong {
    color: #e3c158;
    font-weight: 600;
    font-size: 45px;
    padding: 20px 0px;
}

.designer_benefits h1 {
    color: #e3c158;
    font-weight: 600;
    font-size: 45px;
    padding: 20px 0px 0px 0px;
    text-align: center;
}

ul.provided strong {
    color: #ebd38a;
}


/* .designer_benefits p.first {
  padding-bottom: 50px;
} */


/* .designer_benefits p.sixth {
  padding-bottom: 50px;
} */


/* .designer_benefits p {
  /* text-align: center; */


/* color: black;
  background-color: #ffffff; */


/* } */

.designer_benefits p {
    padding: 30px 10px 20px 10px;
    font-size: 15px;
    font-weight: 400;
    /* text-align: center; */
    border: 1px solid rgb(212 181 87);
    border-radius: 0px 30px;
    background-color: #fff;
    color: black;
    /* max-width: 400px; */
    /* min-width: 415px; */
}


/* .designer_benefits p.sixth {
  padding-bottom: 40px;
}

.designer_benefits p.fifth {
  padding-bottom: 40px;
} */

.designer_benefits h3 {
    padding: 18px 10px;
    text-align: center;
}

.designer_benefits {
    position: relative;
}

.designer_benefits p .fa {
    margin: -42px -15px;
    position: absolute;
    color: #e3c158;
    font-size: 40px;
}

.designer_benefits ul.provided {
    padding: 30px;
}

.designer_benefits strong {
    color: rgb(212 181 87);
}

.designer_benefits ul li {
    padding: 5px;
}

.designer_benefits_1 ul li p {
    font-size: 16px;
}


/* .designer_benefits_1 {
  padding: 20px 50px;
  line-height: 30px;
  font-size: 16px;
  border-right: 5px solid;
  border-left: 5px solid;
  border-radius: 30px;
} */

.designer_benefits {
    padding: 60px 30px;
    opacity: 0.9;
    margin-top: 10rem;
    color: #fff;
    background-color: #000;
}

.designer_benefits_1 {
    padding: 20px 50px;
    line-height: 33px;
    font-size: 16px;
    font-weight: 100;
    margin-bottom: 30px;
    background-color: black;
    opacity: 70%;
    color: white;
}

.designer_benefits_2 {
    padding: 20px 30px 85px 50px;
    line-height: 33px;
    font-size: 16px;
    font-weight: 100;
    margin-bottom: 30px;
    background-color: black;
    opacity: 70%;
    color: white;
}

.designer_benefits ol {
    padding: 5px 25px;
    font-weight: 100;
}


/* Style the input fields */

.name p input {
    padding: 10px;
    width: 100%;
    font-size: 19px;
    font-family: Raleway;
    border: 1px solid #aaaaaa;
    border-radius: 20px;
    outline: none;
    /* margin: 15px; */
    background-color: transparent;
    color: #e3c158;
    height: 47px;
}

.name_1 p input[type="checkbox"] {
    width: auto;
    height: auto;
}

 ::-webkit-input-placeholder {
    /* Edge */
    /* color: #e3c158; */
    text-align: left;
}

 :-ms-input-placeholder {
    /* Internet Explorer */
    /* color: #e3c158; */
    text-align: left;
}

 ::placeholder {
    /* color: #e3c158; */
}

.name_1 p input {
    padding: 10px;
    width: 100%;
    font-size: 17px;
    font-family: Raleway;
    border: 1px solid #aaaaaa;
    border-radius: 20px;
    outline: none;
    /* margin: 15px; */
    background-color: transparent;
    color: #e3c158;
    height: 47px;
}

.name p select option {
    background-color: #0c0c0c;
    color: #e3c158;
}

.name p select {
    width: 100%;
    padding: 10px;
    border: 1px solid grey;
    border-radius: 20px;
    /* margin: 15px; */
    background-color: transparent;
    color: #e3c158;
    font-family: Raleway;
    font-size: 19px;
}

.name_1 p select option {
    background-color: #0c0c0c;
    color: #e3c158;
}

.name_1 p {
    margin: 30px 0px 0px;
}

.name_2 p select {
    width: 100%;
    padding: 10px;
    border: 1px solid grey;
    border-radius: 20px;
    /* margin: 15px; */
    background-color: transparent;
    color: #e3c158;
    font-family: Raleway;
    font-size: 19px;
}

.name_2 p select option {
    background-color: #0c0c0c;
    color: #e3c158;
}

.name_2 p {
    margin: 30px 0;
}


/* Mark input boxes that gets an error on validation: */

input.invalid {
    background-color: #ffdddd;
}


/* Hide all steps by default: */

#regForm .tab {
    display: none;
}


/* Make circles that indicate the steps of the form: */

.step {
    height: 30px;
    width: 30px;
    margin: 0 2px;
    background-color: #060606;
    border: 1px solid #e7cf6f;
    border-radius: 50%;
    display: inline-block;
    opacity: 0.5;
}


/* Mark the active step: */

.step.active {
    opacity: 1;
    padding-top: 3px;
    color: #f7f9f7;
}

span.step {
    padding-top: 3px;
    vertical-align: bottom;
    font-size: 15px;
    color: #f7f7f7;
}


/* Mark the steps that are finished and valid: */

.step.finish {
    background-color: #04AA6D;
}

.regisform .tab {
    background-color: #1e1e1e;
    text-align: center;
}

.regisform button#nextBtn {
    padding: 8px 30px;
    border-radius: 20px;
    outline: none;
    border: 1px solid grey;
    margin-top: 15px;
    background-color: transparent;
    color: #e3c158;
}

.regisform button#prevBtn {
    padding: 8px 30px;
    border: 1px solid grey;
    border-radius: 20px;
    outline: none;
    background-color: transparent;
    color: #e3c158;
}

button {
    font-family: "Poppins", sans-serif;
}

.name_content p {
    padding: 0px 0 0 20px;
    color: white;
    font-size: 12px;
    font-weight: 100;
    text-align: left;
}

input[type="checkbox"] {
    float: left;
}

section.new_1 {
    /* background-image: url("kaaf.png"); */
    background-size: 700px;
    background-repeat: no-repeat;
    background-color: black;
    background-position: right;
    /* text-align: -webkit-center; */
    padding-top: 100px;
    /* padding-bottom: 100px; */
    /* padding-left: 200px; */
}

.regisform button#nextBtn:hover {
    background-color: #e3c158;
    color: white;
}

.regisform button#prevBtn:hover {
    background-color: green;
    color: white;
}


/* .container.p-0.m-0 {
  background-color: black;
} */

.name p {
    margin: 30px 0px;
}

::-webkit-calendar-picker-indicator {
    -webkit-filter: invert(1);
            filter: invert(1);
}

.newsubmit button {
    padding: 3px 16px;
    font-size: 20px;
    color: #1e1e1e;
    background-color: #e3c158;
    outline: none;
    border-radius: 6px;
    border: 1px solid #fbf8f8;
}


/* Style the form */

#regForm {
    background-color: #0c0c0c;
    /* margin: 100px auto; */
    padding: 40px;
    width: 75%;
    /* min-width: 300px; */
    border-radius: 10px;
    -webkit-backdrop-filter: blur(22px);
            backdrop-filter: blur(22px);
    box-shadow: inset 0 0 0 200px rgba(255, 255, 255, 0.074);
    color: #e6e6e6;
    padding: 30px;
    border-radius: 12px;
    grid-gap: 15px;
    gap: 15px;
    /* min-width: 850px;
	min-height: 250px; */
    border: 3px solid rgba(255, 255, 255, 0.30);
}


/* Style the input fields */

 ::-webkit-input-placeholder {
    /* Edge */
    /* color: #e3c158; */
}

 :-ms-input-placeholder {
    /* Internet Explorer */
    /* color: #e3c158; */
}

 ::placeholder {
    /* color: #e3c158; */
}

.name_1 p select option {
    background-color: #0c0c0c;
    color: #e3c158;
}

.name p select option {
    background-color: #0c0c0c;
    color: #e3c158;
}

.name_1 p select {
    width: 100%;
    padding: 10px;
    border: 1px solid grey;
    border-radius: 20px;
    /* margin: 15px; */
    background-color: transparent;
    color: #e3c158;
    height: 47px;
}


/* Mark input boxes that gets an error on validation: */

input.invalid {
    background-color: #ffdddd;
}


/* Hide all steps by default: */

#regForm .tab {
    display: none;
}

.step_main {
    padding: 20px 0px;
}


/* Make circles that indicate the steps of the form: */

span.step\.active {
    height: 30px;
    width: 30px;
    margin: 0 2px;
    background-color: #060606;
    border: 2px solid #e7cf6f;
    display: inline-block;
    color: white;
    padding: 3px 0px;
    border-radius: 100%;
}


/* Mark the active step: */

.step.active {
    opacity: 1;
    padding-top: 5px;
    color: #f7f9f7;
}


/* Mark the steps that are finished and valid: */

.step.finish {
    background-color: #04AA6D;
}

#regForm .tab {
    background-color: #1e1e1e;
    text-align: center;
}

#regForm button#nextBtn {
    padding: 8px 30px;
    border-radius: 20px;
    outline: none;
    border: 1px solid grey;
    margin-top: 15px;
    background-color: transparent;
    color: #e3c158;
}

.name_1 p select {
    width: 100%;
    padding: 10px;
    border: 1px solid grey;
    /* margin: 15px; */
    border-radius: 20px;
}

#regForm button#prevBtn {
    padding: 8px 30px;
    border: 1px solid grey;
    border-radius: 20px;
    outline: none;
    background-color: transparent;
    color: #e3c158;
}

button {
    font-family: "Poppins", sans-serif;
}

.name_content p {
    padding: 0px 20px;
    color: white;
    font-size: 12px;
    font-weight: 100;
}

input[type="checkbox"] {
    float: left;
}


/* .desiner_registration img {
    padding-bottom: 15px;
} */


/* section.new_1 {
    background-image: url("../images/red-carpet-2.png");
    background-size: 500px;
    background-repeat: no-repeat;
    background-color: black;
    background-position: left;
    /* text-align: -webkit-center; */


/* padding-top: 200px;
    padding-bottom: 200px;
    padding-left: 280px;
} */

*/ #regForm button#nextBtn:hover {
    background-color: #e3c158;
    color: white;
}

#regForm button#prevBtn:hover {
    background-color: green;
    color: white;
}


/* .container.p-0.m-0 {
  background-color: black;
} */

.name p {
    margin: 30px 0px;
}

.name_1 p {
    margin: 30px 0px 0px;
}

::-webkit-calendar-picker-indicator {
    -webkit-filter: invert(1);
            filter: invert(1);
}


/* the modesty show responsive css */


/* the modesty show responsive css */

.home_brands_box {
    text-align: center;
}

.home_brands_box img {
    width: 100%;
    height: 154px;
    border-radius: 9px;
    object-fit: cover;
    box-shadow: 1px 0px 9px -2px #333;
}

.home_brands_box h3 a {
    font-size: 1.25rem;
    font-weight: 300;
    color: rgb(26, 26, 26);
}

@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    .login_signup_form input[type="checkbox"] {
        margin: 0 5px 0 0;
        width: 35px;
        height: 35px;
        -webkit-margin-before: 0;
                margin-block-start: 0;
    }
    .login_signup_form select {
        background-color: #fff;
        color: #000;
    }
    .my_profile_tab select {
        background-color: #fff;
        color: #000;
    }
}


/* Community CSS Start */

.sorting_community_bar {
    padding: 10px;
    background-color: #f1f1f1;
    margin-bottom: 20px;
}

.sorting_community_bar input {
    width: 100%;
    border-radius: 6px;
    outline: none;
    border: none;
    box-shadow: 0 0 13px -3px;
    padding: 3px 5px;
    height: 30px;
    background-color: #f5f5f5;
    color: #333;
}

.sorting_community_bar select {
    width: 100%;
    padding: 1px 5px;
    height: 30px;
    outline: none;
    border: none;
    box-shadow: 0 0 13px -3px;
    border-radius: 6px;
    background-color: #f5f5f5;
    color: #333;
}


/* Community CSS End */


/* Product Listing Filter CSS Start */

.filter_title_resp {
    /* display: flex; */
    position: relative;
    /* display: flex; */
    /* flex-wrap: wrap; */
    /* border-right: 1px solid #333; */
    align-items: center;
    /* justify-content: space-between; */
    padding: 6px 10px;
    text-align: center;
}

.resp_filter_sorting_bar {
    background-color: #d3d3d3;
    border-radius: 3px;
    margin-bottom: 20px;
    display: none;
}

.sort_product_title_resp {
    padding: 6px 10px;
}

.filter_title_resp select {
    float: right;
    border: 1px solid #cfcfcf;
    outline: none;
    padding: 3px 5px;
    border-radius: 1px;
    font-size: 1rem;
    width: 100%;
}

.filter_title_resp::after {
    content: "";
    width: 1px;
    background-color: #b1b1b1;
    height: 99%;
    position: absolute;
    right: 0;
    top: 0;
}

.sort_product_title_resp select {
    width: 100%;
}


/* 
.filter_title_resp span i {
    margin-left: 10px;
} */

.filter_title_resp span {
    padding: 0px 9px;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0;
}

.sort_product_title_resp select {
    width: 100%;
    border: 1px solid #cfcfcf;
    outline: none;
    padding: 3px 5px;
    border-radius: 30px;
    font-size: 1rem;
}

.filter_options_resp {
    height: auto;
    width: 100%;
    /* display: none; */
    background-color: #f1f1f1;
    border-radius: 20px 20px 0 0;
    /* margin: 0 10px 0 7px; */
    padding: 1.5rem;
    box-shadow: 0px -5px 10px -3px #7e7e7e;
}


/* Product Listing Filter CSS End */


/* Checkout Adress CSS Start  */

.checkout_address_form {
    border-left: 1px solid #e6e6e6;
    padding-left: 20px;
}

.checkout_address_form input {
    width: 100%;
    padding: 11.5px 10px;
    border: 1px solid #aaaaaa;
    outline: none;
    margin: 9px 0;
    height: 47px;
}

.checkout_address_form input::-webkit-input-placeholder {
    color: #000;
    font-size: 16px;
}

.checkout_address_form input:-ms-input-placeholder {
    color: #000;
    font-size: 16px;
}

.checkout_address_form input::placeholder {
    color: #000;
    font-size: 16px;
}

.checkout_address_form input:focus {
    border: 1px solid #000;
}

.checkout_address_form input[type="checkbox"] {
    width: -webkit-min-content;
    width: min-content;
    margin: 4px 5px 0 0;
    height: auto;
}

.checkout_address_form label {
    font-size: .9rem;
}

.checkout_address_form button {
    width: auto;
    padding: 7px 12px;
    border-radius: 3px;
    border: none;
    background-color: #000000;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    margin: 10px 0;
    outline: none;
}

.checkout_address_form p {
    text-align: center;
    font-size: .9rem;
    margin-top: 15px;
}

.checkout_address_form p a {
    color: #fff;
    font-weight: 500;
    background-color: #000;
    padding: 6px 13px;
    border-radius: 3px;
    margin-left: 5px;
}

.checkout_address_form button:hover {
    background-color: #484848;
}

.checkout_address_form select {
    width: 100%;
    padding: 11px 6px;
    border: 1px solid #aaaaaa;
    outline: none;
    margin: 9px 0;
    height: 47px;
}

.checkout_address_form select option {
    margin: 15px 0;
}

.checkout_address_form select:focus {
    margin: 5px 0;
}

.checkout_address_form select::-webkit-input-placeholder {
    font-family: FontAwesome;
}

.checkout_address_form select:-ms-input-placeholder {
    font-family: FontAwesome;
}

.checkout_address_form select::placeholder {
    font-family: FontAwesome;
}


/* Checkout Address CSS End  */


/* 
checkout page by anand */

.section_title_copy {
    position: relative;
}

.section_title_copy h3 {
    /* text-align: center; */
    /* text-transform: uppercase; */
    font-size: 2rem;
    font-weight: 600;
    /* text-shadow: 1px 1px 0px #e3c17d; */
}

.section_title_copy::after {
    content: "";
    width: 100%;
    height: 3px;
    /* border-radius: 5px; */
    position: absolute;
    background-color: #000;
    bottom: 0;
}

.section_title_copy h3 span {
    color: #000;
    text-shadow: none;
    font-weight: 400;
    float: right;
}

.home_brands_box_copy {
    text-align: center;
    padding: 20px 0px;
}

.home_brands_box_copy img {
    width: 100%;
    height: 200px;
    border-radius: 9px;
    object-fit: cover;
    box-shadow: 1px 0px 9px -2px #333;
}

@media only screen and (max-width: 576px) {
    .home_brands_box_copy img {
        height: 166px;
        max-height: 166px;
    }
}

.section_title_copy h3 {
    font-size: 1.25rem;
    font-weight: 400;
    padding: 0px 0px 2px 0px;
}
/* CSS For Footer End */

@charset "UTF-8";

/* Accordion styles */

.footer_mob_tab input {
    display: none;
}

.footer_mob_tabs {
    /* border-radius: 8px; */
    overflow: hidden;
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
}

.footer_mob_tab {
    width: 100%;
    color: #b1b1b1;
    overflow: hidden;
    background-color: #d3d3d3;
    box-shadow: 0px 2px 6px 0px #b1b1b1;
}

.footer_mob_tab-label {
    display: flex;
    justify-content: space-between;
    padding: 12px 1.5rem;
    /* border-bottom: 0px solid #dbdbdb; */
    background: #000;
    font-weight: 400;
    cursor: pointer;
    margin-bottom: 0;
    line-height: 1.44;
    /* box-shadow: 1px 1px 5px 0px #dbdbdb; */
    font-size: 14px;
    text-transform: uppercase;
}

.footer_mob_tab-content li {
    letter-spacing: -0.2px;
    color: #e5e5e5;
    font-weight: 300;
    padding-bottom: 11px;
    font-size: 13px;
    text-decoration: underline;
}


/* .footer_mob_tab-label:hover {
    background: #222121;
} */

.footer_mob_tab-label::after {
    content: "+";
    width: 1em;
    height: 1em;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    transition: all 0.35s;
}

.footer_mob_tab-content {
    max-height: 0;
    list-style: none;
    padding: 0 1.5rem;
    color: #ffffff;
    background: black;
    box-shadow: 0px 0px 5px 0px #dbdbdb;
    transition: all 0.35s;
    margin: 0;
    /* border-bottom: none; */
    /* border-top: none; */
}

.footer_mob_tab-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: #2c3e50;
    cursor: pointer;
}

.footer_mob_tab-close:hover {
    background: #1a252f;
}

.footer_column_resp h3 {
    background: #000;
    font-weight: 500;
    margin-bottom: 13px;
    text-transform: uppercase;
    text-align: center;
    font-size: 1.1rem;
    color: #d3d3d3;
}


/* input:checked+.footer_mob_tab-label {
    background: #1a252f;
    background: #9f6e0a;
} */

input:checked+.footer_mob_tab-label::after {
    /* transform: rotate(90deg); */
    content: "-";
}

input:checked~.footer_mob_tab-content {
    max-height: 100vh;
    padding: 1rem 1.5rem;
}

.footer_column_resp {
    position: relative;
    background-color: #000;
    padding: 2rem 0;
    text-align: center;
    margin-top: 0px;
    border-bottom: 1px solid #d3d3d3;
}


/* CSS For Footer Start */

@media( min-width: 2560px) {
    .tms-3 {
        padding-bottom: 40px;
    }
    /* .name_2 p select {
        margin: 30px 0px 0px;
    } */
}

@media( min-width: 1440px) {
    /* .name_2 p select {
        margin: 30px 0px 0px;
    } */
    /* p.second {
        max-height: 142px;
    } */
    /* .designer_benefits p.second {
        min-height: 122px;
    } */
    /* p.sixth {
        padding-bottom: 43px;
    } */
}

@media (min-width: 1024px) and (max-width: 1439px) {
    .desiner_registration img {
        padding-bottom: 15px;
        /* width: 400px; */
    }
    section.new_1 {
        padding-left: 0px;
    }
    .designer_benefits p.sixth {
        padding-bottom: 40px;
    }
    .sponser {
        padding: 50px 25px;
    }
    /* .themodestyshow-3 {
        max-height: 200px;
        min-height: 215px;
    } */
}

@media only screen and (max-width: 1200px) {
    .prduct_card a img {
        height: 215px;
    }
    .freelancer_hire_btn {
        padding: 3px 7px;
    }
    .comm_flag_box img {
        width: 40px;
        height: 27px;
    }
    img.freelancer_card_img {
        height: 100px;
    }
    .freelancer_name h3 {
        font-size: 17px;
    }
    .freelancer_rank {
        font-size: 18px;
    }
    .product_filter h3,
    .filter_title span {
        font-size: 1rem;
    }
    .filter_title span {
        top: 8px;
    }
    .register_community_strip h3 {
        font-size: 17px;
    }
    .comm_flag_main_box img {
        width: 65px;
        height: 42px;
    }
}

@media only screen and (max-width: 1024px) {
    .header_text_resp {
        display: none !important;
    }
    .action_buttons span a {
        margin-left: 10px;
        font-size: 2rem;
    }
    .follow_us span {
        width: 35px;
        height: 35px;
    }
    .category_circle img {
        height: 100px;
    }
    .pledge_box h3 {
        font-size: 15px;
    }
    .hire_banner_bg.text-center {
        padding: 1rem 0;
    }
    .hire_banner_bg.text-center h3 {
        font-size: 1.5rem;
    }
    .hire_banner_img_box a button {
        padding: 10px 20px;
        right: 2em;
        bottom: 41px;
    }
    /* .hire_banner_img_box img {
        height: 21rem;
    } */
    .home_brands_box img {
        height: 140px;
    }
    .section_title h3 {
        font-size: 26px;
    }
    .navbar a {
        padding: 7px 5px;
        font-size: 13px;
    }
    a.header_comm_box {
        font-size: 14px !important;
    }
    .home_community_box img {
        height: 130px;
    }
    .login_signup_form input[type="checkbox"] {
        margin: 0 5px 0 0;
        width: 25px;
        height: 25px;
        -webkit-margin-before: 0;
                margin-block-start: 0;
    }
    .checkout_address_form input[type="checkbox"] {
        margin: 0 5px 0 0;
        width: 25px;
        height: 25px;
        -webkit-margin-before: 0;
                margin-block-start: 0;
    }
    .regisform {
        width: 100%;
        padding: 44px 13px;
    }
    section.new_1 {
        padding-top: 50px;
    }
}

@media( min-width: 768px) and (max-width: 1023px) {
    .step_main {
        padding-left: 0px;
    }
    section.new_1 {
        padding-top: 50px;
    }
    .regisform {
        width: 100%;
        padding: 44px 13px
    }
    section.new_1 {
        padding-left: 0px;
    }
    .themodestyshow_content h1 {
        text-align: center;
    }
    .designer_benefits p.sixth {
        padding-bottom: 40px;
    }
    /* .name_2 p select {
        margin: 30px 0px 0px;
    } */
    /* .designer_benefits p.second {
        min-height: 142px;
        max-height: 122px;
    } */
}

@media only screen and (max-width: 991px) {
    .header_text_resp {
        display: none !important;
    }
    .follow_us span {
        width: 25px;
        height: 25px;
    }
    .follow_us span i {
        font-size: 15px;
    }
    /* .hire_banner_img_box img {
        height: 15rem;
    } */
    .prduct_card a img {
        height: 245px;
    }
    .brand_name_product {
        font-size: 16px;
    }
    .product_brief h3 {
        font-size: 23px;
        margin-bottom: 0;
    }
    span.product_current_price {
        font-size: 18px;
        line-height: 1;
    }
    span.product_actual_price {
        font-size: 16px;
        line-height: 1;
    }
    span.product_percent_price {
        line-height: 1.2;
        font-size: 0.8rem;
    }
    .product_brief {
        padding: 0 0 3px;
        margin-bottom: 8px;
    }
    .product_description {
        padding: 16px 0 0px;
    }
    .product_type_one,
    .product_type_two {
        padding: 5px 12px;
    }
    .artists_box {
        padding-top: 6px;
        font-size: 15px;
    }
    .preview_wish_button button,
    .preview_button button {
        font-size: 14px;
        padding: 4px 6px;
    }
    .product_description p {
        margin-bottom: 8px;
    }
    .themodestyshow-3 {
        margin-bottom: 35px;
    }
    .tms-3 {
        padding-bottom: 10px;
    }
    .register_community_strip h3 {
        font-size: 14px;
    }
    .sign_up_community_btn {
        padding: 4px 8px;
    }
    /* img.freelancer_card_img {
        height: 76px;
    } */
    .freelancer_hire_btn_main,
    .freelancer_hire_btn {
        padding: 4px 10px;
        font-size: 18px;
    }
    .comm_flag_main_box img {
        width: 51px;
        height: 33px;
    }
    .freelancer_rank_main {
        font-size: 26px;
    }
    .freelancer_name_main h3 {
        font-size: 25px;
    }
    .freelancer_name_main h4 {
        font-size: 19px;
    }
    .order_history_prodct_card {
        padding: 10px;
    }
    .freelancer_rank_main img {
        width: 50px;
    }
}

@media only screen and (max-width: 767px) {
    .website_logo {
        margin-right: 0;
    }
    /* .navbar a {
        font-size: 24px;
    } */
    a.header_comm_box {
        font-size: 24px;
    }
    .follow_us span {
        width: 30px;
        height: 30px;
    }
    .section_title h3 {
        font-size: 1.5rem;
        font-weight: 600;
        padding: 0px 0px 2px 0px;
    }
    .section_title h3 span {
        font-weight: 400;
        font-size: 21px;
    }
    .hire_banner_bg.text-center {
        padding: 1rem 0;
    }
    .hire_banner_bg.text-center h3 {
        font-size: 1.3rem;
    }
    .hire_banner_img_box a button {
        bottom: 23px;
        padding: 10px 18px;
        font-size: 18px;
        right: 2em;
    }
    .category_circle img {
        height: 96px;
    }
    .product_type_btn {
        font-size: 14px;
        line-height: 1.2;
    }
    .brand_name_product {
        font-size: 14px;
    }
    .product_brief h3 {
        font-size: 20px;
        margin-bottom: 2px;
    }
    span.product_current_price {
        font-size: 16px;
    }
    .product_price_text {
        font-size: 14px;
    }
    .product_actual_price {
        font-size: 14px;
    }
    h3.choose_size {
        margin-bottom: 6px;
    }
    .product_textile_box {
        display: flex;
    }
    .product_description {
        padding: 12px 0 0px;
    }
    .preview_wish_button button,
    .preview_button button {
        font-size: 12px;
        padding: 4px 4px;
    }
    .artists_box {
        padding-top: 2px;
        font-size: 14px;
    }
    .themodestyshow {
        margin: 20px 0 0 0;
        padding: 10px;
    }
    .name_2 p {
        margin: 0 0 30px 0;
    }
    .community_category {
        padding: 3px 0;
    }
    .product_filter {
        margin-bottom: 10px;
    }
    .checkout_amount {
        margin-top: 10px;
    }
    img.freelancer_card_img {
        height: 140px;
    }
    .freelancer_listing .product_filter {
        display: none;
    }
    .community_resp_search {
        display: block;
    }
    .community_filter {
        display: flex;
    }
    .sorting_community_bar {
        display: none;
    }
    .product_filter,
    .sorting_resp_hide {
        display: none;
    }
    .register_community_strip {
        margin-bottom: 20px;
    }
    .account_menu_text_resp {
        display: none;
    }
    .tab {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }
    .tab button {
        width: auto;
        text-align: center;
    }
    .tabcontent {
        padding: 20px 10px;
    }
    .comm_flag_main_box img {
        width: 40px;
        height: 26px;
    }
    .freelancer_rank_main {
        font-size: 22px;
        line-height: 1;
    }
    .freelancer_name_main h3 {
        font-size: 20px;
        margin-top: 10px;
    }
    .freelancer_name_main h4 {
        font-size: 16px;
    }
    .freelancer_points {
        font-size: 16px;
    }
    .freelancer_hire_btn_main,
    .freelancer_hire_btn {
        padding: 3px 8px;
        font-size: 14px;
    }
    .whatsapp_floating_btn {
        bottom: 75px;
        right: 25px;
    }
    .resp_filter_sorting_bar {
        display: block;
    }
    .head_app_install_box {
        display: flex;
    }
    .mob_bottom_menu {
        display: block;
    }
}

@media only screen and (max-width: 576px) {
    .home_community_box img {
        height: 110px;
    }
    .home_community_box h3 {
        line-height: 0.9;
    }
    .home_community_box h3 a {
        font-size: 1rem;
    }
    .home_brands_box img {
        height: auto;
        max-height: 100px;
    }
    .category_circle img {
        height: auto;
        min-height: 80px;
    }
    .category_circle h3 {
        font-size: 18px;
    }
    .pledge_box h3 {
        font-size: 14px;
    }
    .hire_banner_bg.text-center h3 {
        font-size: 1rem;
    }
    .hire_banner_bg.text-center {
        padding: 1rem 0;
    }
    /* .hire_banner_img_box img {
        height: 15rem;
    } */
    .hire_banner_img_box a button {
        bottom: 18px;
        padding: 8px 14px;
        font-size: 16px;
        right: 1em;
    }
    .footer_mob_resp {
        display: block;
        box-shadow: 0px 2px 6px 0px #b1b1b1;
        padding: 0px 0 2px 0;
    }
    .footer_bg_dark {
        display: none;
    }
    .footer_column_resp a img {
        width: 130px;
    }
    .footer_column_resp a img {
        width: 140px;
    }
    .play_store_resp {
        margin-right: 15px;
    }
    .preview_wish_button button,
    .preview_button button {
        font-size: 15px;
        padding: 4px 4px;
    }
    .designer_benefits h1,
    .sponser h1 strong,
    .themodestyshow h1 strong {
        font-size: 34px;
    }
    .designer_benefits h3,
    .sponser h1,
    .themodestyshow h3 {
        font-size: 20px;
    }
    .sponser {
        padding: 10px 0;
    }
    .designer_benefits {
        padding: 10px 30px;
    }
    .sponser h1 {
        text-align: center;
    }
    .name_content p {
        font-size: 13px;
        padding-left: 30px;
    }
    img.freelancer_card_img {
        height: auto;
    }
    .tab {
        display: inline-flex;
        margin-bottom: 20px;
    }
    .tab button {
        padding: 8px;
    }
    .freelancer_main_img {
        border-radius: 50%;
    }
    .freelancer_name_main h3 {
        font-size: 17px;
        margin: 6px 0;
    }
    .freelancer_name_main h4 {
        font-size: 14px;
        line-height: 0.3;
    }
    .freelancer_points {
        font-size: 14px;
        line-height: 0.4;
        bottom: -4px;
    }
    .freelancer_hire_btn_main,
    .freelancer_hire_btn {
        padding: 2px 7px;
        font-size: 13px;
        bottom: -5px;
    }
    .freelancer_rank_main {
        font-size: 18px;
        line-height: 0.5;
    }
    .freelancer_rank_main img {
        width: 40px;
    }
}

@media only screen and (max-width: 540px) {
    .footer_bg_dark {
        text-align: center;
    }
}

@media only screen and (max-width: 425px) {
    /* .primary_header_resp {
        display: block;
        padding: 4px 0;
    }
    .primary_header {
        display: none;
    } */
    .website_logo a img {
        max-width: 140px;
    }
    .section_title h3 {
        font-size: 1.25rem;
        font-weight: 500;
        padding: 0px 0px 2px 0px;
    }
    .section_title h3 span {
        font-weight: 300;
        font-size: 20px;
    }
    .prduct_card {
        min-height: auto;
        height: auto;
    }
    /* .product_price_title {
        min-height: 123px;
    } */
    .action_buttons span a {
        margin-left: 5px;
        font-size: 1.6rem;
    }
    .action_buttons span a i {
        font-size: 1.4rem;
    }
    .footer_bg_dark {
        text-align: center;
    }
    /* .website_search {
        padding: 0 15px;
    } */
    .bg_black {
        background-color: #000;
    }
    /* .website_search button {
        right: 24px;
        top: 5px;
        font-size: 20px;
    } */
    /* .megamenu {
        display: none;
    } */
    .product_heading h3 a {
        margin-bottom: 0;
    }
    .product_heading h3 {
        margin-bottom: 0;
    }
    .prduct_card a img {
        height: 165px;
    }
    .span.wishlist_icon {
        width: 26px;
        height: 26px;
    }
    .product_price_title h3 {
        margin-bottom: 0;
    }
    .product_reviews_stars .App span,
    .product_reviews_stars {
        line-height: 0;
    }
    .react-simple-star-rating {
        line-height: 0;
    }
    span.current_price,
    span.actual_price,
    span.percent_price {
        font-size: 12px;
        margin-right: 7px;
    }
    .footer_credit p {
        font-size: 0.7rem;
    }
    .footer_credit p a {
        font-size: 0.8rem;
    }
    .designer_benefits h1,
    .sponser h1 strong,
    .themodestyshow h1 strong {
        font-size: 30px;
    }
    .designer_benefits h3,
    .sponser h1,
    .themodestyshow h3 {
        font-size: 17px;
        padding: 10px 0;
    }
    .register_community_strip h3 {
        font-size: 13px;
    }
    .register_community_strip {
        padding: 11px 5px;
    }
    .sign_up_community_btn {
        padding: 2px 5px;
    }
    .comm_flag_box img {
        width: 30px;
        height: 21px;
    }
    .freelancer_points {
        position: inherit;
        margin-top: 12px;
    }
    .freelancer_hire_btn_main {
        padding: 3px 8px;
        font-size: 11px;
        bottom: -5px;
    }
    .freelancer_hire_btn {
        padding: 3px 8px;
        font-size: 13px;
        bottom: -5px;
    }
    .pledge_box i {
        font-size: 25px;
    }
    .pledge_box h3 {
        font-size: 14px;
        color: #d3d3d3;
        font-weight: 400;
    }
    .order_status_detail {
        font-size: 13px;
    }
    .order_card_details h4 {
        font-size: 14px;
        margin-bottom: 0;
    }
    .order_card_details h3 {
        font-size: 14px;
        margin-bottom: 4px;
    }
    .order_purchase_price {
        font-size: 14px;
        margin-bottom: 0;
    }
    .order_purchase_time_date {
        font-size: 13px;
    }
    .order_product_quantity {
        font-size: 13px;
    }
    .order_status_processing,
    .order_status_dispatched,
    .order_status_cancel {
        line-height: 1.2;
        font-size: 13px;
    }
    .order_card_details button {
        padding: 4px 8px;
        width: 100%;
        font-size: 14px;
        font-weight: 400;
    }
    .order_history_prodct_card img {
        height: 10rem;
    }
    .order_rating_card h4 {
        font-size: 14px;
        margin-bottom: 0;
    }
    .order_rating_card h3 {
        font-size: 14px;
        margin-bottom: 4px;
    }
    .order_rating_card button {
        padding: 2px 8px;
        /* width: 100%; */
        font-size: 14px;
        font-weight: 400;
    }
    .order_rating_card input {
        padding: 3px 10px;
    }
    .comm_flag_main_box img {
        width: 28px;
        height: 20px;
        margin-right: 11px;
    }
    .freelancer_name_main h3 {
        font-size: 15px;
        margin: 5px 0;
    }
    .freelancer_name_main h4 {
        font-size: 12px;
    }
    .freelancer_rank_main img {
        width: 30px;
    }
    .freelancer_rank_main {
        font-size: 16px;
        line-height: 0.5;
    }
    .freelancer_rank img {
        width: 30px;
    }
    .freelancer_rank span {
        font-size: 16px;
    }
    .freelaancer_view_page {
        padding: 1rem 0 0 0;
    }
    .head_app_install_box {
        font-size: 13px;
    }
    .head_app_install_box>span>span {
        font-size: 16px;
    }
}

@media (max-width: 375px) {
    .section_title h3 {
        font-size: 1.25rem;
        font-weight: 400;
        padding: 0px 0px 2px 0px;
    }
    .section_title h3 span {
        font-weight: 300;
        font-size: 17px;
        line-height: 1.6;
    }
    /* .prduct_card {
        min-height: 320px !important;
    } */
    .action_buttons span a {
        margin-left: 5px;
    }
    .footer_bg_dark {
        text-align: center;
    }
    .regisform {
        width: 100%;
        padding: 44px 13px;
    }
    section.new_1 {
        padding-top: 50px;
    }
    .themodestyshow-2 {
        padding: 0px;
    }
    .themodestyshow-2 ul {
        padding: 0px;
    }
    .themodestyshow_content p {
        padding: 0px;
    }
    .designer_benefits_1 {
        padding: 0px 0px;
    }
    .desiner_registration img {
        width: 100%;
    }
    .regisform button#nextBtn {
        margin-top: -2px;
    }
    .register_community_strip h3 {
        font-size: 12px;
    }
    .whatsapp_floating_btn {
        width: 50px;
        height: 50px;
        font-size: 26px;
    }
    .whatsapp_floating_btn i {
        margin-top: 13px;
    }
    .pledge_box h3 {
        font-size: 12px;
    }
}

@media (max-width: 360px) {
    .category_circle h3 {
        font-size: 13px;
    }
    .category_circle img {
        height: 60px;
        min-height: 60px;
    }
    .comm_flag_main_box {
        line-height: 0.5;
    }
    .comm_flag_main_box img {
        width: 26px;
        height: 17px;
        margin-right: 7px;
    }
    .percent_price {
        display: none;
    }
    .order_card_details button {
        padding: 2px 8px;
        width: 100%;
        font-size: 13px;
        font-weight: 400;
        margin-top: 6px;
    }
    .freelancer_rank_main img {
        width: 25px;
    }
    .freelancer_rank_main {
        font-size: 13px;
        line-height: 0.5;
    }
    .freelancer_name_main h3 {
        font-size: 13px;
        margin: 5px 0;
    }
    .freelancer_name_main h4 {
        font-size: 10px;
    }
    .freelancer_hire_btn_main,
    .freelancer_hire_btn {
        padding: 2px 8px;
        font-size: 10px;
        bottom: -8px;
    }
}

@media (max-width: 320px) {
    /* .section_title h3 {
        font-size: 1.25rem;
        font-weight: 200;
        padding: 0px 0px 2px 0px;
    } */
    /* .section_title h3 span {
        font-weight: 100;
        font-size: 20px;
    } */
    /* .hire_banner_img_box img {
        height: 12rem;
    } */
    .hire_banner_img_box a button {
        padding: 5px 10px;
        font-size: 13px;
    }
    .hire_banner_bg.text-center h3 {
        font-size: 15px;
    }
    /* .community_card {
        min-height: 150px;
        max-height: 200px;
    } */
    .freelancer_hire_btn {
        font-weight: 100;
        font-size: 10px;
        text-align-last: center;
        font-weight: 100;
        font-size: 10px;
    }
    .freelancer_name h3 {
        font-size: 16px;
        font-weight: 500;
        text-align-last: left;
    }
    .action_buttons span a {
        margin-left: 5px;
    }
    .footer_bg_dark {
        text-align: center;
    }
    /* .navbar a {
        padding: 14px 10px;
    } */
    .navbar {
        flex-wrap: nowrap;
    }
    .themodestyshow-3 {
        margin-bottom: 30px;
    }
    .name_2 p {
        margin: -30px 0px 28px;
    }
    .desiner_registration img {
        width: 100%;
    }
    .designer_benefits p {
        margin: 10px 0px;
    }
    .themodestyshow h1 strong {
        line-height: 45px;
    }
    .regisform button#nextBtn {
        margin-top: -2px;
    }
    section.new_1 {
        padding-left: 0px;
    }
    .regisform {
        width: 100%;
        padding: 44px 13px;
    }
    section.new_1 {
        padding-top: 50px;
    }
    .themodestyshow-2 {
        padding: 0px;
    }
    .themodestyshow-2 ul {
        padding: 0px;
    }
    .themodestyshow_content p {
        padding: 0px;
    }
    .themodestyshow_content h1 {
        padding: 0px 0px;
        font-size: 26px;
    }
    .designer_benefits_1 {
        padding: 0px 0px;
    }
    .designer_benefits h1 {
        font-size: 35px;
        text-align: center;
    }
    .designer_benefits p.sixth {
        padding-bottom: 20px;
    }
}

@media( max-width: 320px) {
    .regisform {
        width: 100%;
        padding: 44px 13px
    }
    .themodestyshow-2 {
        padding: 0px;
    }
    .themodestyshow-2 ul {
        padding: 0px;
    }
    .themodestyshow_content p {
        padding: 0px;
    }
    .themodestyshow_content h1 {
        padding: 0px 0px;
        font-size: 26px;
    }
    .designer_benefits_1 {
        padding: 0px 0px;
    }
    .themodestyshow h1 strong {
        font-size: 30px;
    }
    .themodestyshow h3 {
        font-size: 16px;
    }
    .themodestyshow {
        padding: 0px;
    }
    .themodestyshow-3 {
        margin-bottom: 30px;
    }
    .sponser {
        padding: 20px 13px;
        text-align: center;
    }
    .sponser h1 strong {
        font-size: 30px;
        padding: 0px 0px;
    }
    .designer_benefits {
        padding: 20px 20px;
        margin-top: 100px;
    }
    .designer_benefits h3 {
        padding: 10px;
    }
    .tms-3 {
        padding-bottom: 20px;
    }
    .desiner_registration img {
        width: 100%;
    }
    .name_2 p {
        margin: -30px 0px 28px;
    }
}

@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    .login_signup_form input[type="checkbox"] {
        margin: 0 5px 0 0;
        width: 10px;
        height: 10px;
        -webkit-margin-before: 0;
                margin-block-start: 0;
    }
    .login_signup_form select {
        background-color: #fff;
        color: #000;
        border-radius: 5px;
    }
    .my_profile_tab select {
        background-color: #fff;
        color: #000;
        border-radius: 5px;
    }
    .checkout_address_form input[type="checkbox"] {
        margin: 0 5px 0 0;
        width: 10px;
        height: 10px;
        -webkit-margin-before: 0;
                margin-block-start: 0;
    }
    .checkout_address_form select {
        background-color: #fff;
        color: #000;
        border-radius: 5px;
    }
    .signup_display_flex label {
        font-size: 1rem;
    }
}
/* ---------------------MENU SUBMENU CSS--------------------- */


/* PUSH MENU CSS */

.debugger {
    position: absolute;
    left: 50%;
    top: 0;
}

.nav-wrapper {
    position: relative;
    width: 300px;
    height: 100vh;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    /* box-shadow: 5px 0px 19px 0px #000; */
}

.nav-wrapper.show-menu {
    -webkit-transform: none;
            transform: none;
    box-shadow: 5px 0px 19px 0px #000;
}

.js-nav-toggle {
    position: absolute;
    top: 0;
    right: 0px;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    background-color: #6e6e6e;
}

nav {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #fafafa;
    /* box-shadow: 5px 0px 19px 0px #000; */
}

nav .nav-toggle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0.45em 0.6em;
    background-color: #000;
    color: #fff;
    z-index: 100;
    cursor: pointer;
    transition: backgroun-color 0.2s;
}

nav .nav-toggle:hover {
    background-color: #222121;
}

nav .nav-toggle.back-visible .nav-back {
    opacity: 1;
}

nav .nav-toggle.back-visible .nav-title {
    -webkit-transform: translateX(40px);
            transform: translateX(40px);
}

nav .nav-title {
    position: absolute;
    left: 0;
    top: 0.5em;
    padding-left: 0.7em;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
    font-size: 1.2rem;
}

nav .nav-back {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.2s;
}

span.nav-back i {
    padding-top: 9px;
}

nav a {
    display: block;
    position: relative;
    padding: 0.7em;
    border-bottom: 1px solid #e2e2e2;
    color: #000;
    text-decoration: none;
    transition: color 0.15s, background-color 0.15s;
}

nav a:hover {
    color: #222121;
    background-color: #efefef;
    font-weight: 500;
}

nav ul {
    list-style: none;
    padding: 45px 0 0;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

nav ul ul {
    display: none;
    left: 100%;
}

nav li.has-dropdown>a {
    padding-right: 2.5em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

nav li.has-dropdown>a:after {
    font-family: FontAwesome;
    content: "\f054";
    position: absolute;
    right: 1em;
}

nav li.nav-dropdown-open ul {
    display: block;
}

.mobile_menu {
    position: fixed;
    top: 0;
    height: 100%;
    width: 0;
    /*display: none;*/
    z-index: 1;
}

.mobile_hamburger {
    display: none;
}

@media (max-width: 425px) {
    .desktop_menu {
        display: none;
    }
    .mobile_hamburger {
        font-size: 1.4rem;
        color: #000000;
        display: block;
    }
    .mobile_menu {
        /*display: block;*/
    }
}

a.js-nav-toggle.nav_changer span i {
    color: #f7f7f7;
    font-size: 23px;
}


/* PUSH MENU CSS */

.mobile_hamburger i.fa.fa-bars {
    margin-left: 10px;
    font-size: 1.4rem;
    vertical-align: middle;
}


/* REPONSIVE PUSH MENU CSS */

@media (max-width: 425px) {
    .mobile_menu {
        display: block;
    }
    .nav-changer {
        font-size: 25px;
        color: #333333;
        display: block;
    }
}
/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */

.owl-carousel,
.owl-carousel .owl-item {
    -webkit-tap-highlight-color: transparent;
    position: relative
}

.owl-carousel {
    display: none;
    width: 100%;
    z-index: 1
}

.owl-carousel .owl-stage {
    position: relative;
    touch-action: manipulation;
    -moz-backface-visibility: hidden
}

.owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0
}

.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0)
}

.owl-carousel .owl-item,
.owl-carousel .owl-wrapper {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0)
}

.owl-carousel .owl-item {
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-touch-callout: none
}

.owl-carousel .owl-item img {
    display: block;
    width: 100%
}

.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
    display: none
}

.no-js .owl-carousel,
.owl-carousel.owl-loaded {
    display: block
}

.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
    background: 0 0;
    color: inherit;
    border: none;
    padding: 0!important;
    font: inherit
}

.owl-carousel.owl-loading {
    opacity: 0;
    display: block
}

.owl-carousel.owl-hidden {
    opacity: 0
}

.owl-carousel.owl-refresh .owl-item {
    visibility: hidden
}

.owl-carousel.owl-drag .owl-item {
    touch-action: pan-y;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.owl-carousel.owl-grab {
    cursor: move;
    cursor: grab
}

.owl-carousel.owl-rtl {
    direction: rtl
}

.owl-carousel.owl-rtl .owl-item {
    float: right
}

.owl-carousel .animated {
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both
}

.owl-carousel .owl-animated-in {
    z-index: 0
}

.owl-carousel .owl-animated-out {
    z-index: 1
}

.owl-carousel .fadeOut {
    -webkit-animation-name: fadeOut;
            animation-name: fadeOut
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

.owl-height {
    transition: height .5s ease-in-out
}

.owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity .4s ease
}

.owl-carousel .owl-item .owl-lazy:not([src]),
.owl-carousel .owl-item .owl-lazy[src^=""] {
    max-height: 0
}

.owl-carousel .owl-item img.owl-lazy {
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d
}

.owl-carousel .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: #000
}

.owl-carousel .owl-video-play-icon {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 50%;
    top: 50%;
    margin-left: -40px;
    margin-top: -40px;
    cursor: pointer;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    transition: -webkit-transform .1s ease;
    transition: transform .1s ease;
    transition: transform .1s ease, -webkit-transform .1s ease
}

.owl-carousel .owl-video-play-icon:hover {
    -webkit-transform: scale(1.3, 1.3);
            transform: scale(1.3, 1.3)
}

.owl-carousel .owl-video-playing .owl-video-play-icon,
.owl-carousel .owl-video-playing .owl-video-tn {
    display: none
}

.owl-carousel .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: opacity .4s ease
}

.owl-carousel .owl-video-frame {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%
}
/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
.owl-theme .owl-dots,.owl-theme .owl-nav{text-align:center;-webkit-tap-highlight-color:transparent}.owl-theme .owl-nav{margin-top:10px}.owl-theme .owl-nav [class*=owl-]{color:#FFF;font-size:14px;margin:5px;padding:4px 7px;background:#D6D6D6;display:inline-block;cursor:pointer;border-radius:3px}.owl-theme .owl-nav [class*=owl-]:hover{background:#869791;color:#FFF;text-decoration:none}.owl-theme .owl-nav .disabled{opacity:.5;cursor:default}.owl-theme .owl-nav.disabled+.owl-dots{margin-top:10px}.owl-theme .owl-dots .owl-dot{display:inline-block;zoom:1}.owl-theme .owl-dots .owl-dot span{width:10px;height:10px;margin:5px 7px;background:#D6D6D6;display:block;-webkit-backface-visibility:visible;transition:opacity .2s ease;border-radius:30px}.owl-theme .owl-dots .owl-dot.active span,.owl-theme .owl-dots .owl-dot:hover span{background:#869791}
/* @import url('../src/assets/css/bootstrap-formhelpers.css'); */


/* @import url('../src/assets/css/fonts.css'); */


/* @import url('../src/assets/css/global.css'); */

/* @import url('../src/assets/css/style2.css');
@import url('../src/assets/css/style_form.css'); */

/* @import url('../src/assets/css/style.css');
@import url('../src/assets/css/style.css');
@import url('../src/assets/css/style.css'); */
