* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
body {
    font-family: "Poppins", sans-serif;
}

button {
    font-family: "Poppins", sans-serif;
}

textarea {
    font-family: "Poppins", sans-serif;
}

a,
a:hover {
    text-decoration: none;
    color: inherit;
}


/* CSS For Remove Arrows From Input Type Number  */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}


/* CSS For Remove Arrows From Input Type Number  */

@media (min-width: 992px) {
    .col-lg-25 {
        flex: 0 0 auto;
        width: 20%;
    }
}


/* Desktop Header CSS  */

.primary_header {
    padding: 8px 0;
}

.primary_header_resp {
    display: none;
}

.website_logo a img {
    width: 100%;
    max-width: 160px;
}

.website_search {
    position: relative;
}

.website_search input {
    width: 100%;
    padding: 7px 10px;
    border: 1px solid #d1d1d1;
    outline: none;
    border-radius: 6px;
}

.website_search button {
    position: absolute;
    right: 9px;
    top: 7px;
    color: #757575;
    background-color: #fff;
    border: none;
    font-size: 1rem;
    padding-left: 2px;
}

.action_buttons {
    text-align: right;
}

.action_buttons span a {
    color: #000;
    margin-left: 15px;
    font-weight: 500;
    font-size: 0.9rem;
}

.action_buttons span a i {
    font-size: 1.2rem;
}

.action_buttons span {
    display: inline-block;
}

.header_text_resp {
    padding-left: 2px;
}

.desktop_menu {
    background-color: #000;
}

.desktop_menu_list ul {
    padding: 0;
    margin-bottom: 0;
}

.desktop_menu_list ul li {
    display: inline-flex;
    padding: 10px 20px;
    margin: 0;
    line-height: none;
}

.desktop_menu_list ul li a {
    color: #fff;
    font-size: 1.2rem;
    font-weight: 400;
}

.desktop_menu_list ul li:hover {
    background-color: #ffc600;
}

.desktop_menu_list ul li:hover a {
    color: #000;
}

a.header_comm_box {
    align-items: center;
    font-weight: 400;
    text-transform: uppercase;
    float: right !important;
    font-size: 16px !important;
}


/* Desktop Header CSS  */


/* Mobile Header install app CSS  */

.head_app_install_box {
    background-color: #222121;
    padding: 10px 12px;
    /* display: flex; */
    justify-content: space-between;
    color: #fff;
    display: none;
    align-items: center;
}

.head_app_install_box>span>span {
    color: #14ff12;
    font-weight: 600;
    font-size: 17px;
    align-items: center;
}

.install_btn_box {
    padding-left: 5px;
}

.install_btn_box a {
    align-items: center;
    color: #fff;
}

.install_btn_box a:hover {
    color: #fff;
}

.install_btn_box a button {
    background-color: #04b302;
    border: none;
    padding: 3px 8px;
    border-radius: 5px;
    font-weight: 500;
    /* text-decoration: underline; */
    align-items: center;
    color: inherit;
    display: flex;
}

.install_btn_box a button i {
    margin-right: 5px;
    font-size: 44px;
}


/* Mobile Header install app CSS  */


/* Mobile Main Navbar Bottom CSS  */

.mob_bottom_menu {
    display: none;
}

.mob_bottom_menu {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 55px;
    background: #222121;
    padding: 0px 10px;
    z-index: 99;
    box-shadow: 0px -3px 10px 0 #6c6b6b;
}

.mob_bottom_menu ul {
    list-style: none;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    height: 100%;
    width: 100%;
}

.mob_bottom_menu ul li {
    font-size: 0.7rem;
    flex: 0 0 auto;
    width: 20%;
}

.mob_bottom_menu ul li a {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    font-size: inherit;
    text-decoration: none;
}

.mob_bottom_menu ul li a span {
    color: #d7d7d7;
    font-size: inherit;
    margin-top: 4px;
    display: block;
}

.mob_bottom_menu ul li img {
    width: 25px;
}

.mob_menu_community a {
    margin-top: -30px;
}

.mob_menu_community img {
    background-color: #fff;
    padding: 10px;
    width: 50px !important;
    box-shadow: 0 0 8px 0 #333;
    top: -50px;
    border-radius: 30px;
}


/* Mobile Main Navbar Bottom CSS  */


/* Banner CSS */

.home_slider {
    padding: 1rem;
    /* background-color: #eae6dd; */
    /* background-color: #000; */
    height: 30rem;
}

.red_carpet_r {
    max-height: 30rem;
    object-fit: cover;
    width: auto;
    /* background-color: #000; */
    margin: auto;
}

.slide-container {
    background-color: #000;
}

.section_slider_img img {
    width: 100% !important;
    display: block;
    margin: 0 auto;
}

.section_slider_text h1 {
    font-size: 7rem;
    font-family: 'Pacifico', cursive;
    color: #000;
    font-weight: 100;
    font-style: italic;
}

.section_slider_text {
    text-align: center;
    background: -webkit-linear-gradient( 315deg, rgba(191, 149, 63, 1) 20%, rgba(252, 246, 186, 1) 35%, rgba(179, 135, 40, 1) 50%, rgba(251, 245, 183, 1) 65%, rgba(170, 119, 28, 1) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section_title h3 span {
    color: #000;
    text-shadow: none;
    font-weight: 400;
    float: right;
}


/* .section_slider_text h3 {
    font-size: 1.25rem;
    font-weight: 400;
} */

.section_slider_text h2 {
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1rem;
    font-size: 2rem;
}

.section_slider_text h3 {
    font-weight: 500;
    text-transform: uppercase;
}

.section_slider_text button {
    font-size: 1.25rem;
    background-color: transparent;
    color: #fff;
    font-weight: 400;
    padding: 8px 20px;
    border: none;
    margin-top: 1rem;
    border-radius: 2px;
}

.section_slider_text button a {
    color: #fff;
}

.brand-carousel .section_slider_text button a {
    color: #ffc600;
}

.brand-carousel .owl-dots {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.brand-carousel .owl-theme .owl-dots .owl-dot span {
    width: 15px;
    height: 15px;
}


/* Banner CSS */


/* Categories Circle Section No. 2 */

.category_circle {
    margin-top: 15px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    position: relative;
}

.home_community_box {
    text-align: center;
}

.home_community_box img {
    width: 100%;
    height: 196px;
    border-radius: 9px;
    object-fit: cover;
    box-shadow: 1px 0px 9px -2px #333;
}

.category_circle img {
    width: 100%;
    height: 130px;
    border-radius: 9px;
    object-fit: cover;
}

.img_bg_dark {
    background-size: cover;
    background-color: #000;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    position: absolute;
    border-radius: 9px;
}

.category_circle h3 {
    font-size: 24px;
    position: absolute;
    font-weight: 400;
    margin-bottom: 0;
}

.home_community_box h3 a {
    font-size: 1.25rem;
    font-weight: 300;
    color: rgb(26, 26, 26);
}

.section_title {
    position: relative;
}

.category_circle h3 a {
    font-size: 1.25rem;
    font-weight: 500;
    color: rgb(26, 26, 26);
}

.section_title h3 {
    /* text-align: center; */
    /* text-transform: uppercase; */
    font-size: 2rem;
    font-weight: 600;
    /* text-shadow: 1px 1px 0px #e3c17d; */
}

.section_title::after {
    content: "";
    width: 100%;
    height: 3px;
    /* border-radius: 5px; */
    position: absolute;
    background-color: #000;
    bottom: 0;
}

.section_separator {
    content: "";
    width: 150px;
    height: 3px;
    border-radius: 5px;
    position: absolute;
    background-color: #000;
    /* left: 44%; */
    bottom: -10%;
}


/* Categories Circle Section No. 2 */


/* Section 3 CSS */

.featured_sale_banner a img {
    width: 100%;
    border-radius: 2px;
}


/* Section 3 CSS */


/* CSS For Product Card */

.prduct_card {
    /* border: 1px solid #e6e6e6; */
    margin-bottom: 20px;
    position: relative;
    border-radius: 9px;
    box-shadow: 0px 3px 11px -4px grey;
}

span.wishlist_icon {
    position: absolute;
    display: flex;
    width: 31px;
    height: 31px;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    top: 13px;
    right: 10px;
    box-shadow: 0 0 10px 0;
    cursor: pointer;
}

span.wishlist_icon i {
    color: #aaaaaa;
}

span.wishlist_icon i:hover {
    color: red;
}

.prduct_card a img {
    width: 100%;
    height: 245px;
    object-fit: cover;
    object-position: top;
    /* border-radius: 9px 9px 0 0; */
}


/* @media (min-width: 576px) {
    .prduct_card a img {
        height: 244px;
        object-fit: cover;
        object-position: top;
    }
}

@media (max-width: 575px) {
    .prduct_card a img {
        height: 320px;
        object-fit: cover;
        object-position: top;
    }
}

@media (max-width: 375px) {
    .prduct_card a img {
        height: 220px;
    }
}

@media (max-width: 320px) {
    .prduct_card a img {
        height: 180px;
    }
} */

.product_price_title {
    padding: 10px;
    text-align: center;
}

.product_heading {
    padding: 10px;
    text-align: center;
    background: black;
    border-radius: 9px 9px 0 0;
}

.product_heading h3 {
    line-height: 0.8rem;
    margin-bottom: 0;
    text-transform: uppercase;
}

.product_heading h3 a {
    font-size: 0.9rem;
    color: #fff !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    -webkit-box-orient: vertical;
    font-weight: 500;
    /* margin-bottom: 10px; */
    cursor: pointer;
}

.product_heading h3 a:hover {
    color: #d3d3d3!important;
}

.product_price_title h3 {
    line-height: 1.25rem;
    text-transform: uppercase;
    margin-bottom: 0;
}

.product_price_title h3 a {
    font-size: 0.9rem;
    color: rgb(77, 77, 77);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    -webkit-box-orient: vertical;
    font-weight: 500;
    margin-bottom: 10px;
}

.product_price_title h3 a:hover {
    color: rgb(0, 0, 0);
}

span.current_price {
    font-weight: 700;
    color: #c22704;
    margin-right: 10px;
    font-size: 14px;
}

span.actual_price {
    font-weight: 500;
    color: #777777;
    text-decoration: line-through;
    margin-right: 10px;
    font-size: 13px;
    display: inline-block;
}

span.percent_price {
    font-weight: 500;
    color: #000000;
    text-transform: uppercase;
}

.product_reviews_stars {
    line-height: 0.6;
}


/* CSS For Product Card */


/* CSS For Featured Banner  */

.featured_advertisement a img {
    width: 100%;
    margin: auto;
}


/* CSS For Featured Banner  */


/* CSS For Brand Carousel */

.brand-carousel button {
    display: none !important;
}


/* CSS For Brand Carousel */


/* CSS For Customer Pledge Section  */

.pledge {
    background-color: #222121;
    border-bottom: 1px solid #4a4a4a;
    /* background: linear-gradient( 315deg, rgba(191, 149, 63, 1) 12%, rgba(252, 246, 186, 1) 35%, rgba(179, 135, 40, 1) 50%, rgba(251, 245, 183, 1) 75%, rgba(170, 119, 28, 1) 94%); */
}

.pledge_box {
    text-align: center;
    margin: 1rem 0;
    text-align: center;
    /* border: 1px solid #d3d3d3; */
    box-shadow: 0px 0px 4px 0px #dbdbdb;
    border-radius: 5px;
    padding: 8px 5px;
}

.pledge_box:not(:last-child) {
    border-radius: 2px solid #000;
}

.pledge_box i {
    font-size: 26px;
    color: #d3d3d3;
}

.pledge_box h3 {
    font-size: 14px;
    color: #d3d3d3;
    margin-top: 8px;
    margin-bottom: 0;
    font-weight: 400;
}


/* .border_box {
    border-right: 2px solid #000;
} */


/* CSS For Customer Pledge Section  */


/* CSS For Footer  */

.footer_column {
    position: relative;
}

.footer_mob_resp {
    display: none;
    background-color: #d3d3d3;
}

.footer_column ul li:after {
    position: absolute;
    content: "";
    width: 0;
    left: 0;
    top: 21px;
    border-radius: 5px;
    height: 2px;
    background-color: #d7d7d7;
    transition: 0.25s ease-in-out;
}

.footer_column ul li:hover:after {
    width: 20%;
}

.footer_column h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #d7d7d7;
    border-bottom: 1px solid #292929;
    /* box-shadow: 0px 5px 10px -7px #dbdbdb; */
    /* color: #e1cb83; */
    padding-bottom: 5px;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.footer_column ul {
    padding: 0;
    margin-bottom: 0;
}

.footer_column ul li {
    list-style: none;
    font-size: 1rem;
    margin: 10px 0;
    position: relative;
    display: block;
}

.footer_column ul li a {
    color: #c9c9c9;
}

.footer_column ul li a {
    text-decoration: none;
}

.follow_us {
    line-height: 0;
}

.follow_us span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    width: 40px;
    height: 40px;
    /* background-color: #fff; */
    /* background: linear-gradient( 300deg, rgba(191, 149, 63, 1) 25%, rgba(252, 246, 186, 1) 100%); */
    background: linear-gradient(300deg, #a7a7a7 25%, #ffffff 100%);
    color: #000;
    margin-bottom: 20px;
    margin-right: 5px;
    transition: 0.5s ease-in-out;
}

.follow_us span i {
    color: #000;
    font-size: 20px;
}

.footer_column_resp a img {
    border-radius: 5px;
    box-shadow: 0px 0px 7px 2px #878787;
}

.footer_credit p {
    color: #fff;
}

.footer_credit p a {
    color: #c9c9c9;
    margin-left: 5px;
    font-size: 1rem;
    font-weight: 500;
}

span.facebook_icon:hover {
    background-color: #4267b2;
}

span.instagram_icon:hover {
    background-color: #c13584;
}

span.twitter_icon:hover {
    background-color: #1da1f2;
}

span.youtube_icon:hover {
    background-color: #ff0000;
}

.footer_column a img {
    width: 110px;
    margin-bottom: 8px;
    margin-right: 12px;
    border-radius: 5px;
    box-shadow: 0px 0px 7px 0px #7a7a7a;
}

.footer_bg_dark {
    background-color: #000;
}

.footer_credit {
    text-align: center;
    background-color: #000;
    box-shadow: 0px 4px 10px 0px #fff;
}

.footer_credit img {
    width: fit-content;
}

.footer_credit p {
    font-size: 0.9rem;
    padding: 10px 0;
    margin-bottom: 0;
}


/* CSS For Footer  */


/* ========================================= CSS For Product Listing Page =============================================== */

.product_filter .collapsible {
    background-color: #f1f1f1;
    color: #000;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid #e6e6e6;
    border-top: 1px solid #e6e6e6;
}

.community_resp_search,
.community_filter {
    display: none;
}

.community_filter {
    overflow-x: scroll;
    background-color: #222121;
    margin-bottom: 10px;
    padding: 1px 2px;
    border-radius: 5px;
}

.community_filter button {
    background-color: #f5f5f5;
    padding: 1px 6px;
    display: inline-block;
    margin: 6px;
    /* box-shadow: 0 0 13px -5px; */
    color: #333;
    border-radius: 32px;
    border: 1px solid #a5a5a5;
    width: max-content;
}


/* width */

.community_filter::-webkit-scrollbar {
    width: auto;
    height: 7px;
}


/* Track */

.community_filter::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}


/* Handle */

.community_filter::-webkit-scrollbar-thumb {
    background: #959595;
    border-radius: 10px;
}


/* Handle on hover */

.community_filter::-webkit-scrollbar-thumb:hover {
    background: #b30000;
}

.community_resp_search {
    background-color: #222121;
    padding: 8px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.community_resp_search input {
    width: 100%;
    border-radius: 6px;
    outline: none;
    border: none;
    /* box-shadow: 0 0 13px -3px; */
    padding: 1px 5px;
    height: 30px;
    background-color: #f5f5f5;
    color: #333;
}

.community_resp_search select {
    width: 100%;
    padding: 1px 5px;
    height: 30px;
    outline: none;
    border: none;
    /* box-shadow: 0 0 13px -3px; */
    border-radius: 6px;
    background-color: #f5f5f5;
    color: #333;
}

.product_filter .active,
.collapsible:hover {
    background-color: #e8e8e8;
}

.product_filter .collapsible:after {
    content: "\002B";
    color: #000;
    font-weight: bold;
    float: right;
    margin-left: 5px;
}

.product_filter .active:after {
    content: "\2212";
}

.product_filter .content {
    padding: 8px 18px;
    width: 100%;
    /* max-height: 100; */
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    background-color: #f6f6f6;
}

.product_filter .content input {
    margin: 6px 5px 6px 0;
}

.product_filter .content div {
    clear: left;
    margin: 6px 0;
}

.product_filter .content div label {
    margin-bottom: 0;
}

.product_filter {
    background-color: #f1f1f1;
}

.product_filter h3 {
    padding: 9px 9px 9px;
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 0;
}

.filter_title {
    position: relative;
}

.filter_title span {
    position: absolute;
    right: 15px;
    top: 6px;
    font-size: 1.25rem;
}


/* Filter Accordion styles */

.tabs {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
}

.tab {
    width: 100%;
    color: white;
    overflow: hidden;
}

.tab-label {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    background: #2c3e50;
    font-weight: bold;
    cursor: pointer;
    /* Icon */
}

.tab-label:hover {
    background: #1a252f;
}

.tab-label::after {
    /* content: "❯"; */
    width: 1em;
    height: 1em;
    text-align: center;
    transition: all 0.35s;
}

.tab-content {
    max-height: 0;
    padding: 0 1em;
    color: #2c3e50;
    background: white;
    transition: all 0.35s;
}

.tab-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: #2c3e50;
    cursor: pointer;
}

.tab-close:hover {
    background: #1a252f;
}

input:checked+.tab-label {
    background: #1a252f;
}


/* input:checked + .tab-label::after {
    transform: rotate(90deg);
  } */

input:checked~.tab-content {
    max-height: 100vh;
    padding: 1em;
}

.sorting_bar {
    padding: 10px;
    background-color: #f1f1f1;
    margin-bottom: 20px;
}

.sorting_bar h3 {
    font-size: 1.1rem;
    margin-bottom: 0;
    font-weight: 600;
}

.sorting_bar select {
    float: right;
    border: 1px solid #cfcfcf;
    outline: none;
    padding: 3px 5px;
    border-radius: 1px;
    font-size: 1rem;
}

.pagination_bar {
    text-align: right;
}

.pagination_bar button {
    border: none;
    padding: 8px 15px;
    background-color: #f1f1f1;
    color: #000;
    font-size: 1.15rem;
    text-align: center;
    border-radius: 2px;
    margin-left: 5px;
}

.pagination_bar button:hover {
    background-color: #000;
    color: #fff;
}


/* ========================================= CSS End For Product Listing Page =============================================== */


/* ========================================= CSS Start For Product Page =============================================== */

.product_brief h3 {
    font-size: 28px;
    font-weight: 400;
    color: #333;
    margin-top: 5px;
}

span.product_current_price {
    font-weight: 500;
    color: #c22704;
    margin-right: 10px;
    font-size: 21px;
}

span.product_price_text {
    margin-right: 5px;
    color: #6c706e;
}

span.product_actual_price {
    font-weight: 400;
    color: #6c706e;
    text-decoration: line-through;
    margin-right: 10px;
    font-size: 18px;
}

span.rating_count {
    margin-left: 6px;
}

span.product_percent_price {
    padding: 3px 8px;
    background-color: #a37e00;
    color: #fff;
    border-radius: 2px;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 500;
}

.product_textile {
    text-align: center;
    border: 1px solid #c3c4ca;
    padding: 4px;
    margin-right: 7px;
}

.product_textile h3 a {
    color: #333;
    font-size: 14px;
    font-weight: 500;
}

.product_type_btn {
    font-weight: 400;
    color: #333340;
    display: flex;
}

.product_type_one {
    padding: 5px 17px;
    border: 1px solid #000;
    background-color: #000;
    color: #fff;
}

.product_type_two {
    padding: 5px 17px;
    border: 1px solid #000;
}

.product_textile h3 {
    margin-block-start: -0.6rem;
    margin-block-end: -0.2em;
}

.product_textile_box {
    display: flex;
    margin-bottom: 22px;
}

.product_textile img {
    width: 90px;
    height: 60px;
    object-fit: cover;
}

.brand_name_product {
    text-transform: uppercase;
    font-size: 21px;
    background-color: #000;
    color: #fff;
    padding: 2px 9px;
}

.product_preview_pricing {
    display: flex;
    align-items: center;
}

.product_brief {
    padding: 0 0 15px;
    border-bottom: 1px solid #e2e2e2;
    margin-bottom: 15px;
}

.product_preview img.xzoom5 {
    width: 100% !important;
}

.xzoom-thumbs {
    text-align: left !important;
    margin: 10px 0;
}

.xzoom-gallery,
.xzoom-gallery2,
.xzoom-gallery3,
.xzoom-gallery4,
.xzoom-gallery5 {
    margin-left: 0 !important;
}

.product_rating span {
    color: #ffc600;
}

span.rating_count a {
    color: #333;
    font-weight: 400;
    text-decoration: underline;
    font-size: 14px;
}

.product_size {
    display: flex;
    margin-bottom: 15px;
}

.out_of_stock {
    margin-bottom: 10px;
    background-color: #c70101;
    color: #fff;
    padding: 0 4px;
    display: inline-block;
    border-radius: 3px;
    font-size: 14px;
}

.product_size span {
    display: flex;
    height: 45px;
    min-width: 45px;
    margin-right: 8px;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    border-radius: 50%;
    font-weight: 500;
    background-color: #fff;
    border: 1px solid #bfc0c6;
    color: #000;
}

.product_description {
    padding: 25px 0 10px;
}

.product_description p {
    font-size: .9rem;
    line-height: 1.8em;
    color: #0F1111;
}

.product_description ul {
    /* padding-left: 15px; */
    margin-top: 10px;
}

.product_description ul li {
    font-size: .9rem;
    line-height: 1.8em;
    color: #0F1111;
    list-style: none;
}

.product_description ul li:before {
    content: "\f00c";
    font-family: FontAwesome;
    margin-right: 5px;
}

h3.choose_size {
    font-weight: 500;
    margin-bottom: 10px;
    color: #717785;
    font-size: 1rem;
}

.preview_button button {
    width: 100%;
    padding: 8px;
    border-radius: 2px;
    border: none;
    background-color: #a37e00;
    color: #fff;
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
}

.preview_button button:hover {
    background-color: #a37e00c7;
}

.preview_wish_button button {
    width: 100%;
    padding: 8px;
    border-radius: 2px;
    border: none;
    background-color: #7f7f7f;
    color: #fff;
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
}

.preview_wish_button button:hover {
    background-color: #6d6d6d;
}

.artists_row_product {
    border-top: 1px solid #c3c4ca;
}

.artists_box {
    display: flex;
    text-align: center;
    font-weight: 500;
    padding-top: 1rem;
}

.artists_center {
    float: right;
}

.artists_category {
    width: 50%;
    color: #717785;
}

.artists_name {
    width: 50%;
    color: #333
}

.artists_center {
    color: #717785;
}


/* CSS For Similar Product Section  */

.smilar_product_list {
    margin-top: 50px;
    border-top: 1px solid #f1f1f1;
    padding: 20px 0;
}

.simila_product_card {
    margin: 10px 2px;
}

.simila_product_card,
.product_price_title h3 a {
    margin-bottom: 0;
}

@media (min-width: 576px) {
    .simila_product_card a img {
        height: 200px;
        object-fit: cover;
        object-position: top;
    }
}

.smilar_product_list h2 {
    font-weight: 600;
    margin-bottom: 10px;
}

.similar_product_carousel {
    position: relative;
}

.similar_product_carousel .owl-nav button span {
    background-color: #000;
    padding: 2px 15px;
    font-size: 1.5rem;
    color: #fff;
}

.similar_product_carousel .owl-nav {
    position: absolute;
    top: -56px;
    right: -3px;
}

.similar_product_carousel .owl-dots {
    margin-top: 20px;
}


/* CSS For Similar Product Section  */


/* CSS For Review Section  */

.write_review textarea {
    width: 100%;
    min-height: 200px;
    padding: 10px;
    outline: none;
    border: 1px solid #e6e6e6;
    font-family: "Poppins", sans-serif;
    margin-top: 10px;
}

.write_review button {
    width: 100%;
    padding: 7px;
    border: none;
    border-radius: 2px;
    background-color: #04b302;
    font-size: 1rem;
    color: #fff;
}

.write_review button:hover {
    background-color: #04a102;
}

.total_reviews {
    border-top: 1px solid #e6e6e6;
}

.total_reviews h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0;
    line-height: 1em;
}

.total_reviews span {
    font-size: .9rem;
    color: #4c4c4c;
}

.rating_counts {
    margin-top: 20px;
    margin-bottom: 20px;
}

.rating_counts p i {
    color: #ffc600;
    float: right;
}

.rating_counts p {
    color: #4c4c4c;
    font-size: .9rem;
}

.rating_counts p span {
    font-weight: 600;
}

.write_review h3 {
    font-weight: 600;
    margin-bottom: 5px;
}

.write_review {
    border-top: 1px solid #e6e6e6;
    padding-top: 15px;
}


/* CSS For Star Rating  */


/****** Style Star Rating Widget *****/

.rating {
    border: none;
    float: left;
}

.rating>input {
    display: none;
}

.rating>label:before {
    margin-right: 5px;
    font-size: 1.25em;
    font-family: FontAwesome;
    display: inline-block;
    content: "\f005";
}

.rating>.half:before {
    content: "\f089";
    position: absolute;
}

.rating>label {
    color: #ddd;
    float: right;
}


/***** CSS Magic to Highlight Stars on Hover *****/

.rating>input:checked~label,

/* show gold star when clicked */

.rating:not(:checked)>label:hover,

/* hover current star */

.rating:not(:checked)>label:hover~label {
    color: #ffc600;
}


/* hover previous stars in list */

.rating>input:checked+label:hover,

/* hover current star when changing rating */

.rating>input:checked~label:hover,
.rating>label:hover~input:checked~label,

/* lighten current selection */

.rating>input:checked~label:hover~label {
    color: #FFED85;
}


/* CSS End For Star Rating  */


/* CSS For All Reviews  */

.read_reviews {
    border-top: 1px solid #e6e6e6;
}

.read_reviews h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0;
    line-height: 1em;
}

.name_reviews {
    display: flex;
    align-items: center;
}

.name_reviews img {
    width: 35px;
    height: 35px;
    object-position: top;
    object-fit: cover;
    margin-right: 10px;
    border: 1px solid #cbcbcb;
}

.name_reviews h3 {
    font-size: .9rem;
    font-weight: 400;
}

.review_content span i {
    font-size: .9rem;
    color: #ffc600;
}

.single_review {
    padding: 15px 0;
    border-bottom: 1px solid #e6e6e6;
}

.name_reviews p {
    font-size: .75rem;
    color: #949494;
    margin-top: 3px;
}

.review_content p {
    font-size: .9rem;
    margin: 8px 0;
    color: #4c4c4c;
}


/* CSS End For All Reviews  */


/* CSS For Review Section  */


/* ========================================= CSS End For Product Page =============================================== */


/* ============================================ CSS Start For About Us Page ========================================== */

.about_text p {
    margin: 10px 0;
    text-align: justify;
    font-weight: 400;
    font-size: .95rem;
}

.about_img img {
    width: 100%;
    border-radius: 0px;
    box-shadow: 10px 10px 0px 0px #ffc600;
}

.about_text h3 {
    font-size: 2rem;
    font-weight: 700;
    color: #000000;
    text-shadow: 2px 1px 0px #ffc600;
}


/* ============================================ CSS End For About Us Page ========================================== */


/* ============================================ CSS Start For My Account Page ========================================== */

.tab {
    background-color: #f1f1f1;
}

.tab button {
    display: block;
    background-color: inherit;
    color: black;
    padding: 15px;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;
    font-size: 1rem;
    font-weight: 500;
}

.tab button:hover {
    background-color: #ddd;
}

.tab button.active {
    background-color: #a37e00;
    color: #fff;
}

.tabcontent {
    background-color: #f1f1f1;
    padding: 20px 50px;
    height: 100%;
}

.my_profile_tab h2 {
    font-weight: 500;
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.tab button span i {
    width: 25px;
    font-size: 20px;
}

.my_profile_tab input {
    width: 100%;
    padding: 12px 10px;
    border: 1px solid #aaaaaa;
    outline: none;
    margin: 7px 0;
}

.my_profile_tab select {
    width: 100%;
    padding: 11px 10px;
    border: 1px solid #aaaaaa;
    outline: none;
    margin: 7px 0;
}

.my_profile_tab h4 {
    font-weight: 500;
    font-size: .9rem;
}

.my_profile_tab button {
    padding: 10px;
    width: 100%;
    border: none;
    margin-top: 5px;
    background-color: #000000;
    color: #fff;
    font-size: 1rem;
}

.my_profile_tab table {
    border-collapse: collapse;
    width: 100%;
    background-color: #fff;
}

.my_order_table {
    overflow-x: scroll;
    /* overflow: hidden; */
}

.my_profile_tab td,
th {
    border: 1px solid #aaaaaa;
    text-align: left;
    padding: 8px;
    text-align: center;
}

.my_profile_tab select {
    width: 100%;
    padding: 11px 6px;
    border: 1px solid #aaaaaa;
    outline: none;
    margin: 7px 0;
    height: 52px;
}

.my_profile_tab select option {
    margin: 13px 0;
}


/* .my_profile_tab select:focus {
    margin: 5px 0;
} */


/* 
.login_signup_form select::placeholder {
    font-family: FontAwesome;
} */


/* .my_profile_tab tr:nth-child(even) {
  background-color: #fff;
}

.my_profile_tab tr:nth-child(odd) {
  background-color: #f1f1f1;
} */


/* ============================================ CSS End For My Account Page ========================================== */


/* CSS Start For Order History Card  */

.order_history_tab_content {
    background-color: #f1f1f1;
    padding: 20px 20px;
    height: 100%;
}

.order_history_prodct_card {
    border: 1px solid #e6e6e6;
    margin-bottom: 20px;
    position: relative;
    border-radius: 9px;
    padding: 20px;
    box-shadow: 0px 3px 11px -4px grey;
    background-color: #f7f7f7;
}

.order_history_prodct_card img {
    width: 100%;
    border-radius: 10px;
    vertical-align: middle;
    height: 12rem;
    object-position: top;
    object-fit: cover;
}

.order_status_detail {
    margin-bottom: 0px;
    color: #333;
}

.order_card_details h4 {
    font-size: 18px;
    margin-bottom: 2px;
}

.order_card_details h3 {
    text-transform: uppercase;
    font-size: 23px;
}

.order_purchase_time_date {
    color: #333;
    line-height: 1;
}

.order_product_quantity {
    font-weight: 500;
    margin-bottom: 1px;
}

.order_status_processing {
    font-weight: 600;
    color: #72bb1d;
    margin-bottom: 0;
}

.order_status_dispatched {
    font-weight: 600;
    color: #e3b107;
    margin-bottom: 0;
}

.order_status_cancel {
    font-weight: 600;
    color: #db1e1e;
    margin-bottom: 0;
}

.order_purchase_price {
    margin-bottom: 2px;
    font-weight: 900;
}

.order_card_details button {
    color: #fff;
    font-weight: 500;
    background-color: #000;
    padding: 6px 16px;
    border-radius: 7px;
    border: none;
    outline: none;
    box-shadow: 0px 0px 4px 0px #000;
    margin-top: 10px;
}


/* =============================== CSS End For Order History Card ============================ */


/* =============================== CSS Start For Order Rating Card =========================== */

.order_rating_card button {
    color: #fff;
    font-weight: 500;
    background-color: #000;
    padding: 6px 16px;
    border-radius: 7px;
    border: none;
    outline: none;
    box-shadow: 0px 0px 4px 0px #000;
    margin-top: 10px;
}

.order_rating_card h4 {
    font-size: 18px;
    margin-bottom: 2px;
}

.order_rating_card h3 {
    text-transform: uppercase;
    font-size: 23px;
}

.order_rating_card input {
    width: 100%;
    padding: 7px 10px;
    border: 1px solid #d1d1d1;
    outline: none;
    border-radius: 2px;
    background-color: #fff;
}

.order_rating_card input::placeholder {
    color: #757575;
}


/* .order_ratings_stars {
    line-height: ;
} */


/* =============================== CSS Start For Order Rating Card =========================== */


/* =============================== CSS Start For My Wishlist Page ============================ */

button.wish_cart_btn {
    width: 100%;
    padding: 8px;
    border-radius: 2px;
    border: none;
    background-color: #20201d;
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    margin-top: 10px;
}

button.wish_cart_btn i {
    margin-left: 10px;
}

button.wish_cart_btn:hover {
    background-color: #444444;
}


/* =============================== CSS End For My Wishlist Page ============================ */


/* =============================== CSS Start For My Cart Page ============================ */

.cart_page {
    background-color: #f1f1f1;
}

.my_cart_title {
    background-color: #fff;
    padding: 15px;
}

.my_cart_title span {
    float: right;
    color: #7f7f7f;
    font-weight: 400;
}

.my_cart_title h3 {
    font-size: 1rem;
    font-weight: 600;
}

.cart_table {
    background-color: #fff;
    margin-top: 10px;
    padding: 10px;
}

.cart_items {
    position: relative;
    margin: 5px;
    border: 1px solid #e6e6e6;
}

.cart_items img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    border-right: 1px solid #e6e6e6;
}

.cart_items span.current_price {
    font-size: 1.25rem;
    font-weight: 600;
}

.cart_items span.actual_price {
    font-weight: 400;
    font-size: .9rem;
}

.cart_items h3 a {
    font-weight: 400;
    font-size: 1rem;
    color: #3d3d3d;
}

.cart_quantity {
    margin-top: 10px;
}

.cart_quantity label {
    font-size: .9rem;
    color: #3d3d3d;
}

.cart_quantity select {
    border: 1px solid #e6e6e6;
    padding: 3px;
    outline: none;
    margin-left: 5px;
}

.remove_item span {
    position: absolute;
    right: 20px;
    top: 15px;
}

.cart_items button {
    padding: 5px 10px;
    border-radius: 2px;
    border: 1px solid #e6e6e6;
    background-color: #ffffff;
    color: #656565;
    font-size: .7rem;
    font-weight: 400;
    margin-top: 18px;
    margin-bottom: 10px;
}

.cart_items button:hover {
    color: #000;
}

.checkout_amount {
    background-color: #fff;
    padding: 15px;
}

.checkout_amount h3 {
    font-size: 1rem;
    font-weight: 600;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 10px;
}

.checkout_amount h4 {
    font-weight: 500;
    font-size: 1.15rem;
    margin-top: 15px;
    margin-bottom: 0;
}

.checkout_amount h4 span {
    float: right;
}

.checkout_amount table td {
    border-bottom: 1px solid #e6e6e6;
    text-align: left;
    padding: 5px 0;
}

.checkout_amount table p {
    font-size: .85rem;
    color: #3d3d3d;
    margin: 10px 0;
}

.checkout_amount table span {
    font-size: .85rem;
    color: #3d3d3d;
    float: right;
}

.checkout_amount table {
    border-collapse: collapse;
    width: 100%;
}

.checkout_amount button {
    width: 100%;
    padding: 8px;
    border-radius: 2px;
    border: none;
    background-color: #000;
    color: #fff;
    font-size: 1.1rem;
    font-weight: 600;
    /* text-transform: uppercase; */
    margin-top: 10px;
}

.checkout_amount button:hover {
    background-color: #a37e00;
}

.checkout_amount img {
    display: block;
    margin: 15px auto 5px;
    width: 80%;
}

.remove_item span i {
    font-size: 20px;
    color: #c22704;
}


/* =============================== CSS End For My Cart Page ============================ */


/* =============================== CSS Start For Checkout Page ============================ */

.delivery_details {
    background-color: #fff;
    padding: 15px;
    margin-top: 10px;
}

.delivery_details_title {
    background-color: #fff;
    padding: 15px;
}

.delivery_details_title h3 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0;
}

.delivery_details input {
    outline: none;
    border: 1px solid #e6e6e6;
    padding: 10px 7px;
    width: 100%;
    margin-bottom: 10px;
}

.delivery_details input::placeholder {
    color: #bdbdbd;
}

.delivery_details input:focus {
    border: 1px solid #000;
}

.delivery_details select:focus {
    border: 1px solid #000;
}

.delivery_details select {
    outline: none;
    border: 1px solid #e6e6e6;
    padding: 9px 4px;
    width: 100%;
    margin-bottom: 10px;
}

.delivery_details button {
    width: 100%;
    padding: 8px;
    border-radius: 2px;
    border: none;
    background-color: #04b302;
    color: #fff;
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 10px;
}

.delivery_details button:hover {
    background-color: #04a102;
}

.delivery_details h3 {
    font-size: .95rem;
    font-weight: 400;
    margin-bottom: 4px;
}


/* =============================== CSS End For Checkout Page ============================ */


/* =============================== CSS Start For Contact Page ============================ */

.contact_us_title {
    background-color: #fff;
    padding: 15px;
}

.contact_us_title h3 {
    font-size: 1rem;
    font-weight: 600;
}

.contact_query_form {
    background-color: #fff;
    padding: 15px;
    margin-top: 10px;
}

.contact_query_form p {
    font-size: .9rem;
    margin-bottom: 10px;
}

.contact_query_form h3 {
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 10px;
}

.contact_query_form input {
    width: 100%;
    padding: 8px;
    border: 1px solid #e6e6e6;
    margin-bottom: 10px;
    outline: none;
}

.contact_query_form input:focus {
    border: 1px solid #000;
}

.contact_query_form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #e6e6e6;
    margin-bottom: 5px;
    outline: none;
    min-height: 200px;
}

.contact_query_form textarea:focus {
    border: 1px solid #000;
}

.contact_query_form button {
    width: 100%;
    padding: 8px;
    border-radius: 2px;
    border: none;
    background-color: #04b302;
    color: #fff;
    font-size: 1.1rem;
    font-weight: 600;
}

.contact_query_form button:hover {
    background-color: #04a102;
}

.contact_informations h3 {
    margin-top: 25px;
    margin-bottom: 5px;
}

.contact_informations h3 i {
    width: 15px;
}

.contact_informations p {
    font-size: .85rem;
}


/* =============================== CSS End For Contact Page ============================ */


/* ============================== CSS Start For Privacy Policy ========================== */


/* .terms_privacy_content h1, h2, h3{
  margin: 15px 0;
  font-weight: 600;
} */

.terms_privacy_content h1 {
    font-size: 2rem;
    font-weight: 600;
    margin: 15px 0;
}

.terms_privacy_content h2 {
    font-size: 1.75rem;
    font-weight: 600;
    margin: 15px 0;
}

.terms_privacy_content h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 15px 0;
}

.terms_privacy_content p {
    margin-bottom: 10px;
    font-size: 1rem !important;
    line-height: 1.8em;
}

.terms_privacy_content ul {
    margin-bottom: 10px;
    padding-left: 20px;
}

.terms_privacy_content ol {
    margin-bottom: 10px;
    padding-left: 20px;
}

.terms_privacy_content ul li {
    line-height: 1.8em;
}

.terms_privacy_content ol li {
    line-height: 1.8em;
}

.terms_privacy_content li {
    font-size: 1rem !important;
}

.terms_privacy_content a {
    text-decoration: underline;
}


/* ============================== CSS End For Privacy Policy ========================== */


/* ============================== CSS Start For Login/Signup ========================== */

.register_page {
    background-color: #f1f1f1;
}

.login_signup_page {
    background-color: #fff;
    /* background-image: url(../images/login_bg.png); */
    padding: 40px;
    box-shadow: 0px 0px 16px 3px #424242;
}

.login_page_bg {
    background-image: url(../images/login_bg.png);
    background-size: cover;
    margin-bottom: 2rem;
}

.login_signup_page h2 {
    text-align: center;
    margin-bottom: 1rem;
}

.social_login h3 {
    font-weight: 600;
    text-align: center;
    margin-bottom: 5px;
}

.social_login button {
    width: 100%;
    padding: 7px;
    margin: 7px 0;
    border: none;
    border-radius: 2px;
    color: #fff;
    font-size: 1.1rem;
}

button.facebook_login {
    background-color: #4267B2;
}

button.google_login {
    background-color: #DB4437;
}

.login_signup_form {
    border-left: 1px solid #e6e6e6;
    padding-left: 20px;
}

.login_signup_form input {
    width: 100%;
    padding: 11.5px 10px;
    border: 1px solid #aaaaaa;
    outline: none;
    margin: 9px 0;
    height: 47px;
}

.login_signup_form input::placeholder {
    color: #000;
    font-size: 16px;
}

.signup_display_flex {
    display: flex;
    margin-top: 3px;
    line-height: 1.8;
}

.signup_display_flex label {
    font-size: 1rem;
}

.login_signup_form input:focus {
    border: 1px solid #000;
}

.login_signup_form input[type="checkbox"] {
    width: min-content;
    margin: 4px 5px 0 0;
    height: auto;
}

.login_signup_form label {
    font-size: .9rem;
}

.login_signup_form button {
    width: 100%;
    padding: 7px;
    border-radius: 3px;
    border: none;
    background-color: #04b302;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    margin: 10px 0;
}

.login_signup_form p {
    text-align: center;
    font-size: .9rem;
    margin-top: 15px;
}

.login_signup_form p a {
    color: #fff;
    font-weight: 500;
    background-color: #000;
    padding: 6px 13px;
    border-radius: 3px;
    margin-left: 5px;
}

.login_signup_form button:hover {
    background-color: #04a102;
}

.login_signup_form select {
    width: 100%;
    padding: 11px 6px;
    border: 1px solid #aaaaaa;
    outline: none;
    margin: 9px 0;
    height: 47px;
}

.login_signup_form select option {
    margin: 15px 0;
}

.login_signup_form select:focus {
    margin: 5px 0;
}

.login_signup_form select::placeholder {
    font-family: FontAwesome;
}


/* ============================== CSS End For Login/Signup ========================== */


/* ======================== CSS For Forgot Password / Update Password ========================== */

.forgot_password {
    text-align: center;
    padding: 40px;
    background-color: #fff;
}

.forgot_password h3 {
    font-size: 1.25rem;
    margin-bottom: 10px;
}

.forgot_password input {
    width: 100%;
    padding: 10px 8px;
    margin-bottom: 10px;
    border: 1px solid #e6e6e6;
    outline: none;
}

.forgot_password input:focus {
    border: 1px solid #000;
}

.forgot_password button {
    width: 100%;
    padding: 7px;
    border-radius: 2px;
    border: none;
    background-color: #04b302;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
}

.forgot_password button:hover {
    background-color: #04a102;
}

.forgot_password p {
    font-size: .9rem;
    margin-top: 15px;
}


/* ======================== CSS End Forgot Password / Update Password ========================== */


/* ======================== CSS For Payment Status ========================== */

.payment_status {
    padding: 50px;
    background-color: #fff;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.payment_status span i {
    font-size: 5rem;
    color: #04a102;
}

.payment_failed span i {
    font-size: 5rem;
    color: red;
}

.payment_status h3 {
    margin-top: 15px;
    font-size: 1.5rem;
    font-weight: 600;
}

.payment_status p {
    margin-top: 10px;
}

.payment_status p span {
    font-weight: 600;
}


/* ======================== CSS For Payment Status ========================== */


/* ======================== CSS For Whatsapp Floating Button ================ */

.whatsapp_floating_btn {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #000;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 0px 0px 10px 2px #6a6a6a;
    z-index: 100;
}

.whatsapp_floating_btn:hover {
    color: #fff;
    background-color: #333;
}

.whatsapp_floating_btn i {
    margin-top: 16px;
}


/* ===================== CSS For Megamenu ======================= */

.navbar {
    overflow: hidden;
    background-color: #000;
}

.navbar a {
    float: left;
    font-size: 16px;
    font-weight: 500;
    color: white;
    text-align: center;
    padding: 4px 16px;
    text-decoration: none;
}

.dropdown {
    float: left;
    overflow: hidden;
}

.dropdown .dropbtn {
    font-size: 16px;
    border: none;
    outline: none;
    color: white;
    padding: 14px 16px;
    background-color: inherit;
    font: inherit;
    margin: 0;
}

.navbar a:hover,
.dropdown:hover .dropbtn {
    background-color: #d7d7d7;
    color: black;
    box-shadow: 0px 0px 6px 0px #ffffff;
    border-radius: 3px;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    width: 100%;
    left: 0;
    box-shadow: 0px 2px 5px 0px #00000040;
    z-index: 1;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.column a {
    float: none;
    color: black;
    padding: 0;
    text-decoration: none;
    display: block;
    text-align: left;
}

.dropdown_menu_list ul {
    padding: 0;
    margin: 0;
}

.dropdown_menu_list ul li {
    margin: 20px 0px;
    list-style: none;
}

.dropdown_menu_list ul li a {
    font-size: .9rem;
}

.megamenu {
    background-color: #000;
}

.dropdown_menu_list h3 {
    margin: 10px 0;
}

.dropdown-content a:hover {
    background-color: inherit !important;
    text-decoration: underline;
}

.drop_down_banner a {
    padding: 0;
    float: inherit;
}

.drop_down_banner a img {
    width: 100% !important;
}

.drop_down_inner {
    background-color: #fff;
    padding: 10px 11px 7px 20px;
}

.dropdown-content {
    z-index: 9999;
}

button.dropbtn i {
    margin-left: 10px;
}


/* ===================== CSS For Megamenu ======================= */

.MuiAccordion-root.Mui-expanded {
    margin: 0!important;
}

.MuiAccordionDetails-root {
    display: flex;
    padding: 0!important;
}


/* CSS For Hiring Banner Start*/

.hire_banner_bg.text-center {
    background: #000;
    padding: 1rem 0;
    text-align: center;
}

.hire_banner_bg.text-center h3 {
    color: #fff;
    font-size: 1.6rem;
    letter-spacing: 2px;
    margin-bottom: 0;
}

.hire_banner_img_box a button {
    position: absolute;
    bottom: 50px;
    padding: 10px 16px;
    cursor: pointer;
    font-size: 20px;
    background-color: #000;
    border: none;
    box-shadow: 0px 0px 10px 1px #d3d3d3;
    color: #fff;
    /* border: 3px solid #a37e00; */
    border-radius: 35px;
    right: 3em;
}

.hire_banner_img_box {
    position: relative;
    width: 100%;
    height: auto;
}

.hire_banner_img_box a button i {
    padding-right: 12px;
}

.hire_banner_img_box img {
    position: relative;
    width: 100%;
    height: auto;
    object-fit: cover;
}


/* CSS For Hiring Banner End*/


/* CSS For Community Listing Page Start */

.community_filter_box {
    background-color: #fff;
    padding: 12px;
    border: 1px solid #bfc0c6;
    box-shadow: 0px 3px 11px -4px grey;
}

.community_category {
    padding: 8px 0;
}

.community_category input {
    margin-right: 10px;
}

.community_category label {
    margin-bottom: 0;
}

.community_category input[type="checkbox"] {
    height: 24px;
}

.register_community_strip {
    background-color: #000;
    color: #fff;
    padding: 16px 8px;
    text-align: center;
}

.register_community_strip h3 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0;
}

.sign_up_community_btn {
    color: #fff;
    background-color: #b58a2c;
    padding: 5px 12px;
    margin-left: 10px;
    border-radius: 4px;
}

.community_card {
    border: 1px solid #e6e6e6;
    margin-bottom: 20px;
    position: relative;
    border-radius: 9px;
    padding: 20px;
    box-shadow: 0px 3px 11px -4px grey;
    background-color: #f1f1f1;
}

img.freelancer_card_img {
    width: 100%;
    border-radius: 50%;
    vertical-align: middle;
    height: 130px;
}

.comm_flag_box img {
    width: 45px;
    margin-right: 10px;
    margin-bottom: 5px;
    height: 30px;
    box-shadow: 0 0 5px 0 #333;
}

.freelancer_name h4 {
    font-size: 15px;
    font-weight: 500;
    color: #747474;
}

.freelancer_name h3 {
    font-size: 19px;
    font-weight: 600;
    /* margin-top: 7px; */
    color: #333;
}

.freelancer_rank {
    font-weight: 600;
    text-align: right;
    font-size: 22px;
    color: #b58a2c;
}

.freelancer_hire_btn {
    position: absolute;
    right: 13px;
    bottom: 0px;
    color: #fff;
    border-radius: 30px;
    background-color: #000;
    padding: 6px 14px;
    font-weight: 300;
    font-size: 14px;
}

.freelancer_hire_btn:hover {
    color: #fff;
}

.freelancer_hire_btn i {
    margin-right: 5px;
}


/* CSS For Community Listing Page End */


/* CSS for Freelancer Main Page */

.freelaancer_view_page {
    padding: 1rem 0;
}

.freelancer_main_img {
    width: 100%;
    border-radius: 10px;
    vertical-align: middle;
    max-height: 20rem;
    object-position: top;
    object-fit: cover;
}

.comm_flag_main_box img {
    width: 80px;
    margin-right: 19px;
    height: 50px;
    box-shadow: 0 0 5px 0 #333;
}

.freelancer_name_main h3 {
    font-size: 28px;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 15px;
}

.freelancer_name_main h4 {
    font-size: 20px;
    font-weight: 500;
    color: #747474;
}

.freelancer_rank_main {
    font-weight: 600;
    float: right;
    font-size: 30px;
    color: #000;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.freelancer_rank_main img {
    width: 70px;
    object-fit: cover;
}

.freelancer_rank_main span {
    position: absolute;
}

.freelancer_rank {
    font-weight: 600;
    float: right;
    font-size: 30px;
    color: #000;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.freelancer_rank img {
    width: 50px;
    object-fit: cover;
}

.freelancer_rank span {
    position: absolute;
    font-size: 24px;
}

.freelancer_hire_btn_main {
    position: absolute;
    right: 16px;
    bottom: 0px;
    color: #fff;
    border-radius: 30px;
    background-color: #000;
    padding: 6px 18px;
    font-weight: 300;
    font-size: 21px;
    align-items: center;
    justify-content: center;
    line-height: initial;
}

.freelancer_hire_btn_main:hover {
    color: #fff;
}

.freelancer_hire_btn_main i {
    margin-right: 10px;
}

.freelancer_points {
    position: absolute;
    bottom: 0px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0;
}

.freelancer_number {
    position: absolute;
    bottom: 5px;
    font-size: 22px;
    font-weight: 600;
}

.freelancer_products {
    position: relative;
}

.freelancer_products h3 {
    font-size: 2rem;
    font-weight: 700;
    text-shadow: 2px 1px 0px #ffc600;
}

.freelancer_products::after {
    content: "";
    width: 71%;
    height: 3px;
    position: absolute;
    background-color: #000;
    bottom: 0;
    right: 0px;
}


/* CONTACT PAGE CSS START */

.contact_outer_container {
    padding: 3rem 0;
}

.contact_bg_dark {
    background-color: #202020;
}

.contact_banner {
    /* background-image: url(../../images/ContactTitle-Image-1.jpg); */
    padding: 4rem;
}

.contact_kaaf_img {
    text-align: center;
}

.contact_kaaf_img img {
    width: 40%;
}


/* -----------------------my-css----------------- */

.contact_outer_container h5 {
    font-weight: bold;
}

.borders {
    border-right: 2px solid #e2e2e2;
}

.borders_responsive {
    border-right: 2px solid #e2e2e2;
}

.hr {
    border-bottom: 2px solid #e2e2e2;
}


/* .app {
	width: 210px;
	height: auto;
	margin-top: 65px;
} */

.app img {
    width: 90%;
    padding: 25px 15px;
    background-color: #f9f8f4;
    border-radius: 16px;
    margin: 18px 0;
}

.social_box {
    padding: 25px 0px;
    background-color: #f9f8f4;
    border-radius: 16px;
    text-align: center;
    width: 90%;
    margin: 18px auto;
}

.social_box img {
    width: 55px;
    margin-bottom: 13px;
}

.play {
    width: 250px;
    height: auto;
    /* display: flex;
justify-content: center;
align-items: center; */
    margin-top: 50px;
}

.play img {
    width: 100%;
    /* margin-left: 45px; */
}

.followus {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 20px;
}


/* .followus .borders {
	border-right: 2px solid #e2e2e2;
} */

.insta {
    width: 60px;
    height: 80px;
    margin: auto;
}

.youtube {
    width: 60px;
    height: 80px;
    margin: auto;
}

.linkedin {
    width: 60px;
    height: 80px;
    margin: auto;
}

.facebook {
    width: 60px;
    height: 80px;
    margin: auto;
}

.followus .insta-box h5 {
    text-align: center;
}

.followus .youtube-box h5 {
    text-align: center;
}

.followus .linedin-box h5 {
    text-align: center;
}

.followus .facebook-box h5 {
    text-align: center;
}

.insta-box {
    margin-bottom: 24px;
    border-right: 2px solid #8d8585;
    margin-top: 40px;
}

.youtube-box {
    margin-bottom: 24px;
    border-right: 2px solid #8d8585;
    margin-top: 70px;
}

.linedin-box {
    margin-bottom: 24px;
    border-right: 2px solid #8d8585;
    margin-top: 70px;
}

.facebook-box {
    margin-bottom: 24px;
    /* border-right: 2px solid #8d8585; */
    margin-top: 70px;
}

.followus a {
    color: #000;
    font-size: 15px;
    font-weight: 500;
}


/* -------------------contact-us----------------------- */

.contactus {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 20px;
}

.contactus h5 {
    text-align: center;
}

.contactus .call-box h5 {
    text-align: center;
}

.contactus .email-box h5 {
    text-align: center;
}

.contactus .web-box h5 {
    text-align: center;
}

.contactus .location-box h5 {
    text-align: center;
}

.contactus a {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 14px;
    font-weight: bold;
}

.call {
    width: 60px;
    height: 80px;
    margin: auto;
}

.call-box {
    margin-bottom: 24px;
    border-right: 2px solid #8d8585;
    margin-top: 40px;
}

.email {
    width: 60px;
    height: 80px;
    margin: auto;
}

.email-box {
    margin-bottom: 24px;
    border-right: 2px solid #8d8585;
    margin-top: 70px;
}

.web {
    width: 60px;
    height: 80px;
    margin: auto;
}

.web-box {
    margin-bottom: 24px;
    border-right: 2px solid #8d8585;
    margin-top: 70px;
}

.location {
    width: 60px;
    height: 80px;
    margin: auto;
}

.location-box {
    margin-bottom: 24px;
    /* border-right: 2px solid #8d8585; */
    margin-top: 70px;
}


/* ----------------------------FORM-------------------- */

.form {
    padding: 20px;
}

.form h5 {
    font-weight: bold;
}

.contact_outer_container .form-control {
    padding: 1.375rem .75rem;
    height: calc(2.5em + 0.75rem + 2px);
    border-radius: 10px;
}

form {
    margin-top: 20px;
}

.contact_outer_container .form-control::placeholder {
    color: #a4a4a4;
}

.contact_outer_container button {
    display: inline-block;
    margin-top: 15px;
    width: 100%;
    padding: 11px;
    font-weight: 400;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #000;
    border: 1px solid transparent;
    /* padding: 0.375rem .75rem; */
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.contact_outer_container button:hover {
    color: #fff;
    text-decoration: none;
}


/* .footer_column h3 {
    color: #fff;
    font-weight: 400;
    font-size: 1.35rem;
    /* margin-bottom: 20px; */


/* }  */

.form-controlS {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid #fff;
    background: transparent;
    padding: 1.375rem .75rem;
    height: calc(2.5em + 0.75rem + 2px);
}

.contact_outer_container textarea.form-control {
    height: 194px;
}

.btns {}


/* ======================= CSS For Subcategory Main Page ================ */

.subcategory_main_page {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.subcategory_main_page img {
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.subcategory_main_page h3 {
    position: absolute;
    font-size: 26px;
    color: #fff;
    padding: 7px;
    border-radius: 3px;
    font-weight: 500;
    text-transform: uppercase;
}

.subcategory_title {
    font-size: 22px;
    margin-bottom: 1.5rem;
    color: #d3d3d3;
    background-color: #222121;
    border-radius: 20px 0;
    padding: 8px 16px;
    box-shadow: 0 0 10px 1px #1a1a1a;
}

.subcategory_title i {
    margin: 0 10px;
    font-size: 20px;
}

.sub_img_bg_dark {
    background-size: cover;
    background-color: #000;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    position: absolute;
    border-radius: 20px;
}

.sub_img_bg_dark:hover {
    background-color: rgba(0, 0, 0, .7);
    color: #fff;
}


/* ======================= CSS For Subcategory Main Page ================ */


/* ---------------------MEDIA QUERY------------------------- */

@media (max-width: 768px) {
    .borders {
        border-right: none
    }
}

@media only screen and (max-width: 767px) {
    .text {
        margin: auto;
    }
    .contact_outer_container button {
        margin-top: 20px;
    }
    .borders_responsive,
    .border {
        border: none
    }
}


/* CONTACT PAGE CSS END */


/* Style the form */

.regisform {
    background-color: #0c0c0c;
    margin: 0px auto;
    width: 80%;
    /* min-width: 300px; */
    border-radius: 10px;
    backdrop-filter: blur(22px);
    box-shadow: inset 0 0 0 200px rgba(255, 255, 255, 0.074);
    color: #e6e6e6;
    padding: 25px;
    border-radius: 12px;
    gap: 15px;
    border: 3px solid rgba(255, 255, 255, 0.30);
}

@media (max-width: 1024px) {
    .regisform {
        width: 100%;
        padding: 44px 13px;
    }
}

.regisform .tab {
    background-color: #1e1e1e;
    text-align: center;
}


/* .themodestyshow {
    border: 1px solid black;
    border-radius: 20px;
    padding: 20px;
    margin: 10px;
    line-height: 25px;
    background-image: url("kaaf.png");
    background-position: right;
    background-repeat: no-repeat;
    box-shadow: 6px 6px 7px 2px;
    font-family: monospace;
} */


/* .themodestyshow_content {
  border: 1px solid black;
  border-radius: 20px;
  padding: 20px;
  margin: 10px;
  line-height: 25px;
  background-image: url("kaaf.png");
  background-position: right;
  background-repeat: no-repeat;
  box-shadow: 6px 6px 7px 2px;
  font-family: monospace;
} */

section.tms {
    background-color: black;
    color: white;
}

.themodestyshow {
    /* font-family: 'Open Sans', sans-serif; */
    font-family: 'Poppins', sans-serif;
    line-height: 25px;
    margin: 40px 0px 0px 0px;
    padding: 27px;
    /* border-right: 5px solid;
  border-left: 5px solid;
  border-radius: 52px; */
    /* box-shadow: 5px -10px 10px #f1f1f1; */
}

.themodestyshow h1 {
    /* margin: 19px; */
    padding: 10px 0px;
    text-align: center;
    font-size: 20px;
}

.themodestyshow-3 {
    padding: 10px 10px;
    border: 3px solid #e7ca70;
    border-radius: 20px 0px;
    background-color: #ffffff;
    margin-bottom: 90px;
    color: black;
}

section.sponser_main_main {
    margin: 0px 0px;
    background-image: url("../images/new.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

section.benefits {
    background-color: #e1dfdf;
}


/* section.benefits {
  color: white;
  background-color: black;
} */

.tms-3 {
    padding-bottom: 40px;
}

.themodestyshow h3 {
    margin: 12px 0px;
    padding: 10px 0px;
    text-align: center;
    font-size: 20px;
    font-weight: normal;
}

.themodestyshow-2 {
    padding: 0px 30px;
    line-height: 30px;
    font-size: 16px;
    font-weight: 100;
}

.themodestyshow-2 ul {
    padding: 10px 20px;
}

.themodestyshow-2 p {
    /* padding: 10px 10px; */
    margin-bottom: 0;
}

.themodestyshow h1 strong {
    color: #e3c158;
    font-weight: unset;
    font-size: 45px;
}


/* .themodestyshow_content {
  padding: 10px 30px;
  border-right: 5px solid;
  border-left: 5px solid;
  border-radius: 30px;
} */

.sponser {
    padding: 50px 50px;
    text-align-last: center;
    /* filter: blur(); */
    background-color: black;
    opacity: .9;
    color: white;
}

.themodestyshow_content h1 img {
    width: 150px;
    vertical-align: -webkit-baseline-middle;
    padding-bottom: 10px;
}

.themodestyshow_content h1 {
    padding: 0px 20px;
    font-size: 30px;
    font-weight: 600;
}

.themodestyshow_content p {
    padding: 0px 20px;
    line-height: 30px;
    font-size: 16px;
    font-weight: 100;
}

.sponser h1 strong {
    color: #e3c158;
    font-weight: 600;
    font-size: 45px;
    padding: 20px 0px;
}

.designer_benefits h1 {
    color: #e3c158;
    font-weight: 600;
    font-size: 45px;
    padding: 20px 0px 0px 0px;
    text-align: center;
}

ul.provided strong {
    color: #ebd38a;
}


/* .designer_benefits p.first {
  padding-bottom: 50px;
} */


/* .designer_benefits p.sixth {
  padding-bottom: 50px;
} */


/* .designer_benefits p {
  /* text-align: center; */


/* color: black;
  background-color: #ffffff; */


/* } */

.designer_benefits p {
    padding: 30px 10px 20px 10px;
    font-size: 15px;
    font-weight: 400;
    /* text-align: center; */
    border: 1px solid rgb(212 181 87);
    border-radius: 0px 30px;
    background-color: #fff;
    color: black;
    /* max-width: 400px; */
    /* min-width: 415px; */
}


/* .designer_benefits p.sixth {
  padding-bottom: 40px;
}

.designer_benefits p.fifth {
  padding-bottom: 40px;
} */

.designer_benefits h3 {
    padding: 18px 10px;
    text-align: center;
}

.designer_benefits {
    position: relative;
}

.designer_benefits p .fa {
    margin: -42px -15px;
    position: absolute;
    color: #e3c158;
    font-size: 40px;
}

.designer_benefits ul.provided {
    padding: 30px;
}

.designer_benefits strong {
    color: rgb(212 181 87);
}

.designer_benefits ul li {
    padding: 5px;
}

.designer_benefits_1 ul li p {
    font-size: 16px;
}


/* .designer_benefits_1 {
  padding: 20px 50px;
  line-height: 30px;
  font-size: 16px;
  border-right: 5px solid;
  border-left: 5px solid;
  border-radius: 30px;
} */

.designer_benefits {
    padding: 60px 30px;
    opacity: 0.9;
    margin-top: 10rem;
    color: #fff;
    background-color: #000;
}

.designer_benefits_1 {
    padding: 20px 50px;
    line-height: 33px;
    font-size: 16px;
    font-weight: 100;
    margin-bottom: 30px;
    background-color: black;
    opacity: 70%;
    color: white;
}

.designer_benefits_2 {
    padding: 20px 30px 85px 50px;
    line-height: 33px;
    font-size: 16px;
    font-weight: 100;
    margin-bottom: 30px;
    background-color: black;
    opacity: 70%;
    color: white;
}

.designer_benefits ol {
    padding: 5px 25px;
    font-weight: 100;
}


/* Style the input fields */

.name p input {
    padding: 10px;
    width: 100%;
    font-size: 19px;
    font-family: Raleway;
    border: 1px solid #aaaaaa;
    border-radius: 20px;
    outline: none;
    /* margin: 15px; */
    background-color: transparent;
    color: #e3c158;
    height: 47px;
}

.name_1 p input[type="checkbox"] {
    width: auto;
    height: auto;
}

 ::-webkit-input-placeholder {
    /* Edge */
    /* color: #e3c158; */
    text-align: left;
}

 :-ms-input-placeholder {
    /* Internet Explorer */
    /* color: #e3c158; */
    text-align: left;
}

 ::placeholder {
    /* color: #e3c158; */
}

.name_1 p input {
    padding: 10px;
    width: 100%;
    font-size: 17px;
    font-family: Raleway;
    border: 1px solid #aaaaaa;
    border-radius: 20px;
    outline: none;
    /* margin: 15px; */
    background-color: transparent;
    color: #e3c158;
    height: 47px;
}

.name p select option {
    background-color: #0c0c0c;
    color: #e3c158;
}

.name p select {
    width: 100%;
    padding: 10px;
    border: 1px solid grey;
    border-radius: 20px;
    /* margin: 15px; */
    background-color: transparent;
    color: #e3c158;
    font-family: Raleway;
    font-size: 19px;
}

.name_1 p select option {
    background-color: #0c0c0c;
    color: #e3c158;
}

.name_1 p {
    margin: 30px 0px 0px;
}

.name_2 p select {
    width: 100%;
    padding: 10px;
    border: 1px solid grey;
    border-radius: 20px;
    /* margin: 15px; */
    background-color: transparent;
    color: #e3c158;
    font-family: Raleway;
    font-size: 19px;
}

.name_2 p select option {
    background-color: #0c0c0c;
    color: #e3c158;
}

.name_2 p {
    margin: 30px 0;
}


/* Mark input boxes that gets an error on validation: */

input.invalid {
    background-color: #ffdddd;
}


/* Hide all steps by default: */

#regForm .tab {
    display: none;
}


/* Make circles that indicate the steps of the form: */

.step {
    height: 30px;
    width: 30px;
    margin: 0 2px;
    background-color: #060606;
    border: 1px solid #e7cf6f;
    border-radius: 50%;
    display: inline-block;
    opacity: 0.5;
}


/* Mark the active step: */

.step.active {
    opacity: 1;
    padding-top: 3px;
    color: #f7f9f7;
}

span.step {
    padding-top: 3px;
    vertical-align: bottom;
    font-size: 15px;
    color: #f7f7f7;
}


/* Mark the steps that are finished and valid: */

.step.finish {
    background-color: #04AA6D;
}

.regisform .tab {
    background-color: #1e1e1e;
    text-align: center;
}

.regisform button#nextBtn {
    padding: 8px 30px;
    border-radius: 20px;
    outline: none;
    border: 1px solid grey;
    margin-top: 15px;
    background-color: transparent;
    color: #e3c158;
}

.regisform button#prevBtn {
    padding: 8px 30px;
    border: 1px solid grey;
    border-radius: 20px;
    outline: none;
    background-color: transparent;
    color: #e3c158;
}

button {
    font-family: "Poppins", sans-serif;
}

.name_content p {
    padding: 0px 0 0 20px;
    color: white;
    font-size: 12px;
    font-weight: 100;
    text-align: left;
}

input[type="checkbox"] {
    float: left;
}

section.new_1 {
    /* background-image: url("kaaf.png"); */
    background-size: 700px;
    background-repeat: no-repeat;
    background-color: black;
    background-position: right;
    /* text-align: -webkit-center; */
    padding-top: 100px;
    /* padding-bottom: 100px; */
    /* padding-left: 200px; */
}

.regisform button#nextBtn:hover {
    background-color: #e3c158;
    color: white;
}

.regisform button#prevBtn:hover {
    background-color: green;
    color: white;
}


/* .container.p-0.m-0 {
  background-color: black;
} */

.name p {
    margin: 30px 0px;
}

::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.newsubmit button {
    padding: 3px 16px;
    font-size: 20px;
    color: #1e1e1e;
    background-color: #e3c158;
    outline: none;
    border-radius: 6px;
    border: 1px solid #fbf8f8;
}


/* Style the form */

#regForm {
    background-color: #0c0c0c;
    /* margin: 100px auto; */
    padding: 40px;
    width: 75%;
    /* min-width: 300px; */
    border-radius: 10px;
    backdrop-filter: blur(22px);
    box-shadow: inset 0 0 0 200px rgba(255, 255, 255, 0.074);
    color: #e6e6e6;
    padding: 30px;
    border-radius: 12px;
    gap: 15px;
    /* min-width: 850px;
	min-height: 250px; */
    border: 3px solid rgba(255, 255, 255, 0.30);
}


/* Style the input fields */

 ::-webkit-input-placeholder {
    /* Edge */
    /* color: #e3c158; */
}

 :-ms-input-placeholder {
    /* Internet Explorer */
    /* color: #e3c158; */
}

 ::placeholder {
    /* color: #e3c158; */
}

.name_1 p select option {
    background-color: #0c0c0c;
    color: #e3c158;
}

.name p select option {
    background-color: #0c0c0c;
    color: #e3c158;
}

.name_1 p select {
    width: 100%;
    padding: 10px;
    border: 1px solid grey;
    border-radius: 20px;
    /* margin: 15px; */
    background-color: transparent;
    color: #e3c158;
    height: 47px;
}


/* Mark input boxes that gets an error on validation: */

input.invalid {
    background-color: #ffdddd;
}


/* Hide all steps by default: */

#regForm .tab {
    display: none;
}

.step_main {
    padding: 20px 0px;
}


/* Make circles that indicate the steps of the form: */

span.step\.active {
    height: 30px;
    width: 30px;
    margin: 0 2px;
    background-color: #060606;
    border: 2px solid #e7cf6f;
    display: inline-block;
    color: white;
    padding: 3px 0px;
    border-radius: 100%;
}


/* Mark the active step: */

.step.active {
    opacity: 1;
    padding-top: 5px;
    color: #f7f9f7;
}


/* Mark the steps that are finished and valid: */

.step.finish {
    background-color: #04AA6D;
}

#regForm .tab {
    background-color: #1e1e1e;
    text-align: center;
}

#regForm button#nextBtn {
    padding: 8px 30px;
    border-radius: 20px;
    outline: none;
    border: 1px solid grey;
    margin-top: 15px;
    background-color: transparent;
    color: #e3c158;
}

.name_1 p select {
    width: 100%;
    padding: 10px;
    border: 1px solid grey;
    /* margin: 15px; */
    border-radius: 20px;
}

#regForm button#prevBtn {
    padding: 8px 30px;
    border: 1px solid grey;
    border-radius: 20px;
    outline: none;
    background-color: transparent;
    color: #e3c158;
}

button {
    font-family: "Poppins", sans-serif;
}

.name_content p {
    padding: 0px 20px;
    color: white;
    font-size: 12px;
    font-weight: 100;
}

input[type="checkbox"] {
    float: left;
}


/* .desiner_registration img {
    padding-bottom: 15px;
} */


/* section.new_1 {
    background-image: url("../images/red-carpet-2.png");
    background-size: 500px;
    background-repeat: no-repeat;
    background-color: black;
    background-position: left;
    /* text-align: -webkit-center; */


/* padding-top: 200px;
    padding-bottom: 200px;
    padding-left: 280px;
} */

*/ #regForm button#nextBtn:hover {
    background-color: #e3c158;
    color: white;
}

#regForm button#prevBtn:hover {
    background-color: green;
    color: white;
}


/* .container.p-0.m-0 {
  background-color: black;
} */

.name p {
    margin: 30px 0px;
}

.name_1 p {
    margin: 30px 0px 0px;
}

::-webkit-calendar-picker-indicator {
    filter: invert(1);
}


/* the modesty show responsive css */


/* the modesty show responsive css */

.home_brands_box {
    text-align: center;
}

.home_brands_box img {
    width: 100%;
    height: 154px;
    border-radius: 9px;
    object-fit: cover;
    box-shadow: 1px 0px 9px -2px #333;
}

.home_brands_box h3 a {
    font-size: 1.25rem;
    font-weight: 300;
    color: rgb(26, 26, 26);
}

@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    .login_signup_form input[type="checkbox"] {
        margin: 0 5px 0 0;
        width: 35px;
        height: 35px;
        margin-block-start: 0;
    }
    .login_signup_form select {
        background-color: #fff;
        color: #000;
    }
    .my_profile_tab select {
        background-color: #fff;
        color: #000;
    }
}


/* Community CSS Start */

.sorting_community_bar {
    padding: 10px;
    background-color: #f1f1f1;
    margin-bottom: 20px;
}

.sorting_community_bar input {
    width: 100%;
    border-radius: 6px;
    outline: none;
    border: none;
    box-shadow: 0 0 13px -3px;
    padding: 3px 5px;
    height: 30px;
    background-color: #f5f5f5;
    color: #333;
}

.sorting_community_bar select {
    width: 100%;
    padding: 1px 5px;
    height: 30px;
    outline: none;
    border: none;
    box-shadow: 0 0 13px -3px;
    border-radius: 6px;
    background-color: #f5f5f5;
    color: #333;
}


/* Community CSS End */


/* Product Listing Filter CSS Start */

.filter_title_resp {
    /* display: flex; */
    position: relative;
    /* display: flex; */
    /* flex-wrap: wrap; */
    /* border-right: 1px solid #333; */
    align-items: center;
    /* justify-content: space-between; */
    padding: 6px 10px;
    text-align: center;
}

.resp_filter_sorting_bar {
    background-color: #d3d3d3;
    border-radius: 3px;
    margin-bottom: 20px;
    display: none;
}

.sort_product_title_resp {
    padding: 6px 10px;
}

.filter_title_resp select {
    float: right;
    border: 1px solid #cfcfcf;
    outline: none;
    padding: 3px 5px;
    border-radius: 1px;
    font-size: 1rem;
    width: 100%;
}

.filter_title_resp::after {
    content: "";
    width: 1px;
    background-color: #b1b1b1;
    height: 99%;
    position: absolute;
    right: 0;
    top: 0;
}

.sort_product_title_resp select {
    width: 100%;
}


/* 
.filter_title_resp span i {
    margin-left: 10px;
} */

.filter_title_resp span {
    padding: 0px 9px;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0;
}

.sort_product_title_resp select {
    width: 100%;
    border: 1px solid #cfcfcf;
    outline: none;
    padding: 3px 5px;
    border-radius: 30px;
    font-size: 1rem;
}

.filter_options_resp {
    height: auto;
    width: 100%;
    /* display: none; */
    background-color: #f1f1f1;
    border-radius: 20px 20px 0 0;
    /* margin: 0 10px 0 7px; */
    padding: 1.5rem;
    box-shadow: 0px -5px 10px -3px #7e7e7e;
}


/* Product Listing Filter CSS End */


/* Checkout Adress CSS Start  */

.checkout_address_form {
    border-left: 1px solid #e6e6e6;
    padding-left: 20px;
}

.checkout_address_form input {
    width: 100%;
    padding: 11.5px 10px;
    border: 1px solid #aaaaaa;
    outline: none;
    margin: 9px 0;
    height: 47px;
}

.checkout_address_form input::placeholder {
    color: #000;
    font-size: 16px;
}

.checkout_address_form input:focus {
    border: 1px solid #000;
}

.checkout_address_form input[type="checkbox"] {
    width: min-content;
    margin: 4px 5px 0 0;
    height: auto;
}

.checkout_address_form label {
    font-size: .9rem;
}

.checkout_address_form button {
    width: auto;
    padding: 7px 12px;
    border-radius: 3px;
    border: none;
    background-color: #000000;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    margin: 10px 0;
    outline: none;
}

.checkout_address_form p {
    text-align: center;
    font-size: .9rem;
    margin-top: 15px;
}

.checkout_address_form p a {
    color: #fff;
    font-weight: 500;
    background-color: #000;
    padding: 6px 13px;
    border-radius: 3px;
    margin-left: 5px;
}

.checkout_address_form button:hover {
    background-color: #484848;
}

.checkout_address_form select {
    width: 100%;
    padding: 11px 6px;
    border: 1px solid #aaaaaa;
    outline: none;
    margin: 9px 0;
    height: 47px;
}

.checkout_address_form select option {
    margin: 15px 0;
}

.checkout_address_form select:focus {
    margin: 5px 0;
}

.checkout_address_form select::placeholder {
    font-family: FontAwesome;
}


/* Checkout Address CSS End  */


/* 
checkout page by anand */

.section_title_copy {
    position: relative;
}

.section_title_copy h3 {
    /* text-align: center; */
    /* text-transform: uppercase; */
    font-size: 2rem;
    font-weight: 600;
    /* text-shadow: 1px 1px 0px #e3c17d; */
}

.section_title_copy::after {
    content: "";
    width: 100%;
    height: 3px;
    /* border-radius: 5px; */
    position: absolute;
    background-color: #000;
    bottom: 0;
}

.section_title_copy h3 span {
    color: #000;
    text-shadow: none;
    font-weight: 400;
    float: right;
}

.home_brands_box_copy {
    text-align: center;
    padding: 20px 0px;
}

.home_brands_box_copy img {
    width: 100%;
    height: 200px;
    border-radius: 9px;
    object-fit: cover;
    box-shadow: 1px 0px 9px -2px #333;
}

@media only screen and (max-width: 576px) {
    .home_brands_box_copy img {
        height: 166px;
        max-height: 166px;
    }
}

.section_title_copy h3 {
    font-size: 1.25rem;
    font-weight: 400;
    padding: 0px 0px 2px 0px;
}