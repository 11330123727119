/* CSS For Footer End */

@charset "UTF-8";

/* Accordion styles */

.footer_mob_tab input {
    display: none;
}

.footer_mob_tabs {
    /* border-radius: 8px; */
    overflow: hidden;
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
}

.footer_mob_tab {
    width: 100%;
    color: #b1b1b1;
    overflow: hidden;
    background-color: #d3d3d3;
    box-shadow: 0px 2px 6px 0px #b1b1b1;
}

.footer_mob_tab-label {
    display: flex;
    justify-content: space-between;
    padding: 12px 1.5rem;
    /* border-bottom: 0px solid #dbdbdb; */
    background: #000;
    font-weight: 400;
    cursor: pointer;
    margin-bottom: 0;
    line-height: 1.44;
    /* box-shadow: 1px 1px 5px 0px #dbdbdb; */
    font-size: 14px;
    text-transform: uppercase;
}

.footer_mob_tab-content li {
    letter-spacing: -0.2px;
    color: #e5e5e5;
    font-weight: 300;
    padding-bottom: 11px;
    font-size: 13px;
    text-decoration: underline;
}


/* .footer_mob_tab-label:hover {
    background: #222121;
} */

.footer_mob_tab-label::after {
    content: "+";
    width: 1em;
    height: 1em;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    transition: all 0.35s;
}

.footer_mob_tab-content {
    max-height: 0;
    list-style: none;
    padding: 0 1.5rem;
    color: #ffffff;
    background: black;
    box-shadow: 0px 0px 5px 0px #dbdbdb;
    transition: all 0.35s;
    margin: 0;
    /* border-bottom: none; */
    /* border-top: none; */
}

.footer_mob_tab-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: #2c3e50;
    cursor: pointer;
}

.footer_mob_tab-close:hover {
    background: #1a252f;
}

.footer_column_resp h3 {
    background: #000;
    font-weight: 500;
    margin-bottom: 13px;
    text-transform: uppercase;
    text-align: center;
    font-size: 1.1rem;
    color: #d3d3d3;
}


/* input:checked+.footer_mob_tab-label {
    background: #1a252f;
    background: #9f6e0a;
} */

input:checked+.footer_mob_tab-label::after {
    /* transform: rotate(90deg); */
    content: "-";
}

input:checked~.footer_mob_tab-content {
    max-height: 100vh;
    padding: 1rem 1.5rem;
}

.footer_column_resp {
    position: relative;
    background-color: #000;
    padding: 2rem 0;
    text-align: center;
    margin-top: 0px;
    border-bottom: 1px solid #d3d3d3;
}


/* CSS For Footer Start */

@media( min-width: 2560px) {
    .tms-3 {
        padding-bottom: 40px;
    }
    /* .name_2 p select {
        margin: 30px 0px 0px;
    } */
}

@media( min-width: 1440px) {
    /* .name_2 p select {
        margin: 30px 0px 0px;
    } */
    /* p.second {
        max-height: 142px;
    } */
    /* .designer_benefits p.second {
        min-height: 122px;
    } */
    /* p.sixth {
        padding-bottom: 43px;
    } */
}

@media (min-width: 1024px) and (max-width: 1439px) {
    .desiner_registration img {
        padding-bottom: 15px;
        /* width: 400px; */
    }
    section.new_1 {
        padding-left: 0px;
    }
    .designer_benefits p.sixth {
        padding-bottom: 40px;
    }
    .sponser {
        padding: 50px 25px;
    }
    /* .themodestyshow-3 {
        max-height: 200px;
        min-height: 215px;
    } */
}

@media only screen and (max-width: 1200px) {
    .prduct_card a img {
        height: 215px;
    }
    .freelancer_hire_btn {
        padding: 3px 7px;
    }
    .comm_flag_box img {
        width: 40px;
        height: 27px;
    }
    img.freelancer_card_img {
        height: 100px;
    }
    .freelancer_name h3 {
        font-size: 17px;
    }
    .freelancer_rank {
        font-size: 18px;
    }
    .product_filter h3,
    .filter_title span {
        font-size: 1rem;
    }
    .filter_title span {
        top: 8px;
    }
    .register_community_strip h3 {
        font-size: 17px;
    }
    .comm_flag_main_box img {
        width: 65px;
        height: 42px;
    }
}

@media only screen and (max-width: 1024px) {
    .header_text_resp {
        display: none !important;
    }
    .action_buttons span a {
        margin-left: 10px;
        font-size: 2rem;
    }
    .follow_us span {
        width: 35px;
        height: 35px;
    }
    .category_circle img {
        height: 100px;
    }
    .pledge_box h3 {
        font-size: 15px;
    }
    .hire_banner_bg.text-center {
        padding: 1rem 0;
    }
    .hire_banner_bg.text-center h3 {
        font-size: 1.5rem;
    }
    .hire_banner_img_box a button {
        padding: 10px 20px;
        right: 2em;
        bottom: 41px;
    }
    /* .hire_banner_img_box img {
        height: 21rem;
    } */
    .home_brands_box img {
        height: 140px;
    }
    .section_title h3 {
        font-size: 26px;
    }
    .navbar a {
        padding: 7px 5px;
        font-size: 13px;
    }
    a.header_comm_box {
        font-size: 14px !important;
    }
    .home_community_box img {
        height: 130px;
    }
    .login_signup_form input[type="checkbox"] {
        margin: 0 5px 0 0;
        width: 25px;
        height: 25px;
        margin-block-start: 0;
    }
    .checkout_address_form input[type="checkbox"] {
        margin: 0 5px 0 0;
        width: 25px;
        height: 25px;
        margin-block-start: 0;
    }
    .regisform {
        width: 100%;
        padding: 44px 13px;
    }
    section.new_1 {
        padding-top: 50px;
    }
}

@media( min-width: 768px) and (max-width: 1023px) {
    .step_main {
        padding-left: 0px;
    }
    section.new_1 {
        padding-top: 50px;
    }
    .regisform {
        width: 100%;
        padding: 44px 13px
    }
    section.new_1 {
        padding-left: 0px;
    }
    .themodestyshow_content h1 {
        text-align: center;
    }
    .designer_benefits p.sixth {
        padding-bottom: 40px;
    }
    /* .name_2 p select {
        margin: 30px 0px 0px;
    } */
    /* .designer_benefits p.second {
        min-height: 142px;
        max-height: 122px;
    } */
}

@media only screen and (max-width: 991px) {
    .header_text_resp {
        display: none !important;
    }
    .follow_us span {
        width: 25px;
        height: 25px;
    }
    .follow_us span i {
        font-size: 15px;
    }
    /* .hire_banner_img_box img {
        height: 15rem;
    } */
    .prduct_card a img {
        height: 245px;
    }
    .brand_name_product {
        font-size: 16px;
    }
    .product_brief h3 {
        font-size: 23px;
        margin-bottom: 0;
    }
    span.product_current_price {
        font-size: 18px;
        line-height: 1;
    }
    span.product_actual_price {
        font-size: 16px;
        line-height: 1;
    }
    span.product_percent_price {
        line-height: 1.2;
        font-size: 0.8rem;
    }
    .product_brief {
        padding: 0 0 3px;
        margin-bottom: 8px;
    }
    .product_description {
        padding: 16px 0 0px;
    }
    .product_type_one,
    .product_type_two {
        padding: 5px 12px;
    }
    .artists_box {
        padding-top: 6px;
        font-size: 15px;
    }
    .preview_wish_button button,
    .preview_button button {
        font-size: 14px;
        padding: 4px 6px;
    }
    .product_description p {
        margin-bottom: 8px;
    }
    .themodestyshow-3 {
        margin-bottom: 35px;
    }
    .tms-3 {
        padding-bottom: 10px;
    }
    .register_community_strip h3 {
        font-size: 14px;
    }
    .sign_up_community_btn {
        padding: 4px 8px;
    }
    /* img.freelancer_card_img {
        height: 76px;
    } */
    .freelancer_hire_btn_main,
    .freelancer_hire_btn {
        padding: 4px 10px;
        font-size: 18px;
    }
    .comm_flag_main_box img {
        width: 51px;
        height: 33px;
    }
    .freelancer_rank_main {
        font-size: 26px;
    }
    .freelancer_name_main h3 {
        font-size: 25px;
    }
    .freelancer_name_main h4 {
        font-size: 19px;
    }
    .order_history_prodct_card {
        padding: 10px;
    }
    .freelancer_rank_main img {
        width: 50px;
    }
}

@media only screen and (max-width: 767px) {
    .website_logo {
        margin-right: 0;
    }
    /* .navbar a {
        font-size: 24px;
    } */
    a.header_comm_box {
        font-size: 24px;
    }
    .follow_us span {
        width: 30px;
        height: 30px;
    }
    .section_title h3 {
        font-size: 1.5rem;
        font-weight: 600;
        padding: 0px 0px 2px 0px;
    }
    .section_title h3 span {
        font-weight: 400;
        font-size: 21px;
    }
    .hire_banner_bg.text-center {
        padding: 1rem 0;
    }
    .hire_banner_bg.text-center h3 {
        font-size: 1.3rem;
    }
    .hire_banner_img_box a button {
        bottom: 23px;
        padding: 10px 18px;
        font-size: 18px;
        right: 2em;
    }
    .category_circle img {
        height: 96px;
    }
    .product_type_btn {
        font-size: 14px;
        line-height: 1.2;
    }
    .brand_name_product {
        font-size: 14px;
    }
    .product_brief h3 {
        font-size: 20px;
        margin-bottom: 2px;
    }
    span.product_current_price {
        font-size: 16px;
    }
    .product_price_text {
        font-size: 14px;
    }
    .product_actual_price {
        font-size: 14px;
    }
    h3.choose_size {
        margin-bottom: 6px;
    }
    .product_textile_box {
        display: flex;
    }
    .product_description {
        padding: 12px 0 0px;
    }
    .preview_wish_button button,
    .preview_button button {
        font-size: 12px;
        padding: 4px 4px;
    }
    .artists_box {
        padding-top: 2px;
        font-size: 14px;
    }
    .themodestyshow {
        margin: 20px 0 0 0;
        padding: 10px;
    }
    .name_2 p {
        margin: 0 0 30px 0;
    }
    .community_category {
        padding: 3px 0;
    }
    .product_filter {
        margin-bottom: 10px;
    }
    .checkout_amount {
        margin-top: 10px;
    }
    img.freelancer_card_img {
        height: 140px;
    }
    .freelancer_listing .product_filter {
        display: none;
    }
    .community_resp_search {
        display: block;
    }
    .community_filter {
        display: flex;
    }
    .sorting_community_bar {
        display: none;
    }
    .product_filter,
    .sorting_resp_hide {
        display: none;
    }
    .register_community_strip {
        margin-bottom: 20px;
    }
    .account_menu_text_resp {
        display: none;
    }
    .tab {
        width: fit-content;
    }
    .tab button {
        width: auto;
        text-align: center;
    }
    .tabcontent {
        padding: 20px 10px;
    }
    .comm_flag_main_box img {
        width: 40px;
        height: 26px;
    }
    .freelancer_rank_main {
        font-size: 22px;
        line-height: 1;
    }
    .freelancer_name_main h3 {
        font-size: 20px;
        margin-top: 10px;
    }
    .freelancer_name_main h4 {
        font-size: 16px;
    }
    .freelancer_points {
        font-size: 16px;
    }
    .freelancer_hire_btn_main,
    .freelancer_hire_btn {
        padding: 3px 8px;
        font-size: 14px;
    }
    .whatsapp_floating_btn {
        bottom: 75px;
        right: 25px;
    }
    .resp_filter_sorting_bar {
        display: block;
    }
    .head_app_install_box {
        display: flex;
    }
    .mob_bottom_menu {
        display: block;
    }
}

@media only screen and (max-width: 576px) {
    .home_community_box img {
        height: 110px;
    }
    .home_community_box h3 {
        line-height: 0.9;
    }
    .home_community_box h3 a {
        font-size: 1rem;
    }
    .home_brands_box img {
        height: auto;
        max-height: 100px;
    }
    .category_circle img {
        height: auto;
        min-height: 80px;
    }
    .category_circle h3 {
        font-size: 18px;
    }
    .pledge_box h3 {
        font-size: 14px;
    }
    .hire_banner_bg.text-center h3 {
        font-size: 1rem;
    }
    .hire_banner_bg.text-center {
        padding: 1rem 0;
    }
    /* .hire_banner_img_box img {
        height: 15rem;
    } */
    .hire_banner_img_box a button {
        bottom: 18px;
        padding: 8px 14px;
        font-size: 16px;
        right: 1em;
    }
    .footer_mob_resp {
        display: block;
        box-shadow: 0px 2px 6px 0px #b1b1b1;
        padding: 0px 0 2px 0;
    }
    .footer_bg_dark {
        display: none;
    }
    .footer_column_resp a img {
        width: 130px;
    }
    .footer_column_resp a img {
        width: 140px;
    }
    .play_store_resp {
        margin-right: 15px;
    }
    .preview_wish_button button,
    .preview_button button {
        font-size: 15px;
        padding: 4px 4px;
    }
    .designer_benefits h1,
    .sponser h1 strong,
    .themodestyshow h1 strong {
        font-size: 34px;
    }
    .designer_benefits h3,
    .sponser h1,
    .themodestyshow h3 {
        font-size: 20px;
    }
    .sponser {
        padding: 10px 0;
    }
    .designer_benefits {
        padding: 10px 30px;
    }
    .sponser h1 {
        text-align: center;
    }
    .name_content p {
        font-size: 13px;
        padding-left: 30px;
    }
    img.freelancer_card_img {
        height: auto;
    }
    .tab {
        display: inline-flex;
        margin-bottom: 20px;
    }
    .tab button {
        padding: 8px;
    }
    .freelancer_main_img {
        border-radius: 50%;
    }
    .freelancer_name_main h3 {
        font-size: 17px;
        margin: 6px 0;
    }
    .freelancer_name_main h4 {
        font-size: 14px;
        line-height: 0.3;
    }
    .freelancer_points {
        font-size: 14px;
        line-height: 0.4;
        bottom: -4px;
    }
    .freelancer_hire_btn_main,
    .freelancer_hire_btn {
        padding: 2px 7px;
        font-size: 13px;
        bottom: -5px;
    }
    .freelancer_rank_main {
        font-size: 18px;
        line-height: 0.5;
    }
    .freelancer_rank_main img {
        width: 40px;
    }
}

@media only screen and (max-width: 540px) {
    .footer_bg_dark {
        text-align: center;
    }
}

@media only screen and (max-width: 425px) {
    /* .primary_header_resp {
        display: block;
        padding: 4px 0;
    }
    .primary_header {
        display: none;
    } */
    .website_logo a img {
        max-width: 140px;
    }
    .section_title h3 {
        font-size: 1.25rem;
        font-weight: 500;
        padding: 0px 0px 2px 0px;
    }
    .section_title h3 span {
        font-weight: 300;
        font-size: 20px;
    }
    .prduct_card {
        min-height: auto;
        height: auto;
    }
    /* .product_price_title {
        min-height: 123px;
    } */
    .action_buttons span a {
        margin-left: 5px;
        font-size: 1.6rem;
    }
    .action_buttons span a i {
        font-size: 1.4rem;
    }
    .footer_bg_dark {
        text-align: center;
    }
    /* .website_search {
        padding: 0 15px;
    } */
    .bg_black {
        background-color: #000;
    }
    /* .website_search button {
        right: 24px;
        top: 5px;
        font-size: 20px;
    } */
    /* .megamenu {
        display: none;
    } */
    .product_heading h3 a {
        margin-bottom: 0;
    }
    .product_heading h3 {
        margin-bottom: 0;
    }
    .prduct_card a img {
        height: 165px;
    }
    .span.wishlist_icon {
        width: 26px;
        height: 26px;
    }
    .product_price_title h3 {
        margin-bottom: 0;
    }
    .product_reviews_stars .App span,
    .product_reviews_stars {
        line-height: 0;
    }
    .react-simple-star-rating {
        line-height: 0;
    }
    span.current_price,
    span.actual_price,
    span.percent_price {
        font-size: 12px;
        margin-right: 7px;
    }
    .footer_credit p {
        font-size: 0.7rem;
    }
    .footer_credit p a {
        font-size: 0.8rem;
    }
    .designer_benefits h1,
    .sponser h1 strong,
    .themodestyshow h1 strong {
        font-size: 30px;
    }
    .designer_benefits h3,
    .sponser h1,
    .themodestyshow h3 {
        font-size: 17px;
        padding: 10px 0;
    }
    .register_community_strip h3 {
        font-size: 13px;
    }
    .register_community_strip {
        padding: 11px 5px;
    }
    .sign_up_community_btn {
        padding: 2px 5px;
    }
    .comm_flag_box img {
        width: 30px;
        height: 21px;
    }
    .freelancer_points {
        position: inherit;
        margin-top: 12px;
    }
    .freelancer_hire_btn_main {
        padding: 3px 8px;
        font-size: 11px;
        bottom: -5px;
    }
    .freelancer_hire_btn {
        padding: 3px 8px;
        font-size: 13px;
        bottom: -5px;
    }
    .pledge_box i {
        font-size: 25px;
    }
    .pledge_box h3 {
        font-size: 14px;
        color: #d3d3d3;
        font-weight: 400;
    }
    .order_status_detail {
        font-size: 13px;
    }
    .order_card_details h4 {
        font-size: 14px;
        margin-bottom: 0;
    }
    .order_card_details h3 {
        font-size: 14px;
        margin-bottom: 4px;
    }
    .order_purchase_price {
        font-size: 14px;
        margin-bottom: 0;
    }
    .order_purchase_time_date {
        font-size: 13px;
    }
    .order_product_quantity {
        font-size: 13px;
    }
    .order_status_processing,
    .order_status_dispatched,
    .order_status_cancel {
        line-height: 1.2;
        font-size: 13px;
    }
    .order_card_details button {
        padding: 4px 8px;
        width: 100%;
        font-size: 14px;
        font-weight: 400;
    }
    .order_history_prodct_card img {
        height: 10rem;
    }
    .order_rating_card h4 {
        font-size: 14px;
        margin-bottom: 0;
    }
    .order_rating_card h3 {
        font-size: 14px;
        margin-bottom: 4px;
    }
    .order_rating_card button {
        padding: 2px 8px;
        /* width: 100%; */
        font-size: 14px;
        font-weight: 400;
    }
    .order_rating_card input {
        padding: 3px 10px;
    }
    .comm_flag_main_box img {
        width: 28px;
        height: 20px;
        margin-right: 11px;
    }
    .freelancer_name_main h3 {
        font-size: 15px;
        margin: 5px 0;
    }
    .freelancer_name_main h4 {
        font-size: 12px;
    }
    .freelancer_rank_main img {
        width: 30px;
    }
    .freelancer_rank_main {
        font-size: 16px;
        line-height: 0.5;
    }
    .freelancer_rank img {
        width: 30px;
    }
    .freelancer_rank span {
        font-size: 16px;
    }
    .freelaancer_view_page {
        padding: 1rem 0 0 0;
    }
    .head_app_install_box {
        font-size: 13px;
    }
    .head_app_install_box>span>span {
        font-size: 16px;
    }
}

@media (max-width: 375px) {
    .section_title h3 {
        font-size: 1.25rem;
        font-weight: 400;
        padding: 0px 0px 2px 0px;
    }
    .section_title h3 span {
        font-weight: 300;
        font-size: 17px;
        line-height: 1.6;
    }
    /* .prduct_card {
        min-height: 320px !important;
    } */
    .action_buttons span a {
        margin-left: 5px;
    }
    .footer_bg_dark {
        text-align: center;
    }
    .regisform {
        width: 100%;
        padding: 44px 13px;
    }
    section.new_1 {
        padding-top: 50px;
    }
    .themodestyshow-2 {
        padding: 0px;
    }
    .themodestyshow-2 ul {
        padding: 0px;
    }
    .themodestyshow_content p {
        padding: 0px;
    }
    .designer_benefits_1 {
        padding: 0px 0px;
    }
    .desiner_registration img {
        width: 100%;
    }
    .regisform button#nextBtn {
        margin-top: -2px;
    }
    .register_community_strip h3 {
        font-size: 12px;
    }
    .whatsapp_floating_btn {
        width: 50px;
        height: 50px;
        font-size: 26px;
    }
    .whatsapp_floating_btn i {
        margin-top: 13px;
    }
    .pledge_box h3 {
        font-size: 12px;
    }
}

@media (max-width: 360px) {
    .category_circle h3 {
        font-size: 13px;
    }
    .category_circle img {
        height: 60px;
        min-height: 60px;
    }
    .comm_flag_main_box {
        line-height: 0.5;
    }
    .comm_flag_main_box img {
        width: 26px;
        height: 17px;
        margin-right: 7px;
    }
    .percent_price {
        display: none;
    }
    .order_card_details button {
        padding: 2px 8px;
        width: 100%;
        font-size: 13px;
        font-weight: 400;
        margin-top: 6px;
    }
    .freelancer_rank_main img {
        width: 25px;
    }
    .freelancer_rank_main {
        font-size: 13px;
        line-height: 0.5;
    }
    .freelancer_name_main h3 {
        font-size: 13px;
        margin: 5px 0;
    }
    .freelancer_name_main h4 {
        font-size: 10px;
    }
    .freelancer_hire_btn_main,
    .freelancer_hire_btn {
        padding: 2px 8px;
        font-size: 10px;
        bottom: -8px;
    }
}

@media (max-width: 320px) {
    /* .section_title h3 {
        font-size: 1.25rem;
        font-weight: 200;
        padding: 0px 0px 2px 0px;
    } */
    /* .section_title h3 span {
        font-weight: 100;
        font-size: 20px;
    } */
    /* .hire_banner_img_box img {
        height: 12rem;
    } */
    .hire_banner_img_box a button {
        padding: 5px 10px;
        font-size: 13px;
    }
    .hire_banner_bg.text-center h3 {
        font-size: 15px;
    }
    /* .community_card {
        min-height: 150px;
        max-height: 200px;
    } */
    .freelancer_hire_btn {
        font-weight: 100;
        font-size: 10px;
        text-align-last: center;
        font-weight: 100;
        font-size: 10px;
    }
    .freelancer_name h3 {
        font-size: 16px;
        font-weight: 500;
        text-align-last: left;
    }
    .action_buttons span a {
        margin-left: 5px;
    }
    .footer_bg_dark {
        text-align: center;
    }
    /* .navbar a {
        padding: 14px 10px;
    } */
    .navbar {
        flex-wrap: nowrap;
    }
    .themodestyshow-3 {
        margin-bottom: 30px;
    }
    .name_2 p {
        margin: -30px 0px 28px;
    }
    .desiner_registration img {
        width: 100%;
    }
    .designer_benefits p {
        margin: 10px 0px;
    }
    .themodestyshow h1 strong {
        line-height: 45px;
    }
    .regisform button#nextBtn {
        margin-top: -2px;
    }
    section.new_1 {
        padding-left: 0px;
    }
    .regisform {
        width: 100%;
        padding: 44px 13px;
    }
    section.new_1 {
        padding-top: 50px;
    }
    .themodestyshow-2 {
        padding: 0px;
    }
    .themodestyshow-2 ul {
        padding: 0px;
    }
    .themodestyshow_content p {
        padding: 0px;
    }
    .themodestyshow_content h1 {
        padding: 0px 0px;
        font-size: 26px;
    }
    .designer_benefits_1 {
        padding: 0px 0px;
    }
    .designer_benefits h1 {
        font-size: 35px;
        text-align: center;
    }
    .designer_benefits p.sixth {
        padding-bottom: 20px;
    }
}

@media( max-width: 320px) {
    .regisform {
        width: 100%;
        padding: 44px 13px
    }
    .themodestyshow-2 {
        padding: 0px;
    }
    .themodestyshow-2 ul {
        padding: 0px;
    }
    .themodestyshow_content p {
        padding: 0px;
    }
    .themodestyshow_content h1 {
        padding: 0px 0px;
        font-size: 26px;
    }
    .designer_benefits_1 {
        padding: 0px 0px;
    }
    .themodestyshow h1 strong {
        font-size: 30px;
    }
    .themodestyshow h3 {
        font-size: 16px;
    }
    .themodestyshow {
        padding: 0px;
    }
    .themodestyshow-3 {
        margin-bottom: 30px;
    }
    .sponser {
        padding: 20px 13px;
        text-align: center;
    }
    .sponser h1 strong {
        font-size: 30px;
        padding: 0px 0px;
    }
    .designer_benefits {
        padding: 20px 20px;
        margin-top: 100px;
    }
    .designer_benefits h3 {
        padding: 10px;
    }
    .tms-3 {
        padding-bottom: 20px;
    }
    .desiner_registration img {
        width: 100%;
    }
    .name_2 p {
        margin: -30px 0px 28px;
    }
}

@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    .login_signup_form input[type="checkbox"] {
        margin: 0 5px 0 0;
        width: 10px;
        height: 10px;
        margin-block-start: 0;
    }
    .login_signup_form select {
        background-color: #fff;
        color: #000;
        border-radius: 5px;
    }
    .my_profile_tab select {
        background-color: #fff;
        color: #000;
        border-radius: 5px;
    }
    .checkout_address_form input[type="checkbox"] {
        margin: 0 5px 0 0;
        width: 10px;
        height: 10px;
        margin-block-start: 0;
    }
    .checkout_address_form select {
        background-color: #fff;
        color: #000;
        border-radius: 5px;
    }
    .signup_display_flex label {
        font-size: 1rem;
    }
}