/* ---------------------MENU SUBMENU CSS--------------------- */


/* PUSH MENU CSS */

.debugger {
    position: absolute;
    left: 50%;
    top: 0;
}

.nav-wrapper {
    position: relative;
    width: 300px;
    height: 100vh;
    transition: transform 0.3s;
    transform: translateX(-100%);
    /* box-shadow: 5px 0px 19px 0px #000; */
}

.nav-wrapper.show-menu {
    transform: none;
    box-shadow: 5px 0px 19px 0px #000;
}

.js-nav-toggle {
    position: absolute;
    top: 0;
    right: 0px;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    background-color: #6e6e6e;
}

nav {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #fafafa;
    /* box-shadow: 5px 0px 19px 0px #000; */
}

nav .nav-toggle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0.45em 0.6em;
    background-color: #000;
    color: #fff;
    z-index: 100;
    cursor: pointer;
    transition: backgroun-color 0.2s;
}

nav .nav-toggle:hover {
    background-color: #222121;
}

nav .nav-toggle.back-visible .nav-back {
    opacity: 1;
}

nav .nav-toggle.back-visible .nav-title {
    transform: translateX(40px);
}

nav .nav-title {
    position: absolute;
    left: 0;
    top: 0.5em;
    padding-left: 0.7em;
    transition: transform 0.3s;
    font-size: 1.2rem;
}

nav .nav-back {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.2s;
}

span.nav-back i {
    padding-top: 9px;
}

nav a {
    display: block;
    position: relative;
    padding: 0.7em;
    border-bottom: 1px solid #e2e2e2;
    color: #000;
    text-decoration: none;
    transition: color 0.15s, background-color 0.15s;
}

nav a:hover {
    color: #222121;
    background-color: #efefef;
    font-weight: 500;
}

nav ul {
    list-style: none;
    padding: 45px 0 0;
    transition: transform 0.3s;
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

nav ul ul {
    display: none;
    left: 100%;
}

nav li.has-dropdown>a {
    padding-right: 2.5em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

nav li.has-dropdown>a:after {
    font-family: FontAwesome;
    content: "\f054";
    position: absolute;
    right: 1em;
}

nav li.nav-dropdown-open ul {
    display: block;
}

.mobile_menu {
    position: fixed;
    top: 0;
    height: 100%;
    width: 0;
    /*display: none;*/
    z-index: 1;
}

.mobile_hamburger {
    display: none;
}

@media (max-width: 425px) {
    .desktop_menu {
        display: none;
    }
    .mobile_hamburger {
        font-size: 1.4rem;
        color: #000000;
        display: block;
    }
    .mobile_menu {
        /*display: block;*/
    }
}

a.js-nav-toggle.nav_changer span i {
    color: #f7f7f7;
    font-size: 23px;
}


/* PUSH MENU CSS */

.mobile_hamburger i.fa.fa-bars {
    margin-left: 10px;
    font-size: 1.4rem;
    vertical-align: middle;
}


/* REPONSIVE PUSH MENU CSS */

@media (max-width: 425px) {
    .mobile_menu {
        display: block;
    }
    .nav-changer {
        font-size: 25px;
        color: #333333;
        display: block;
    }
}