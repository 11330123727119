
/* ==============Lov Patsariya UI Developer=========== */


@media(max-width:1366px) {
.container1440{max-width:1200px;}
.bookingFilterBox{width:600px;}
/* .menu ul li a{font-size:13px;} */
/* .menu ul li{padding:15px 10px;} */
}



@media(max-width:1199px) {
.container1440{max-width:991px;}
.featureBox{padding:15px;}
.topBanner-content h1{font-size:40px;}

.worksIconBox{height:100px;width:100px;}
.worksIconBox span img {max-width:50px;}
.hostingBox h4{font-size:18px;}
.bookingFilterBox{width:495px;padding-right:15px;}
.bookingFilterBox h5{font-size:17px;}
}


/* =============767px 991px Start========== */
@media(max-width:991px){
.globalCard{padding:15px;}
.hostingBox h4{font-size:14px;}
.login-box p.ml-4 {margin-left: 0!important;}
.bookingFilterBox{width:369px;padding-right:15px;}

.headerSearchBox {margin-right:auto;}	
.logo{max-width:50px;}
.head-user-name {display:none;}	
.web-view-logo{display:none;}	
body.loged header{padding-right:0px;}
header > nav {padding-right:50px; }
.banner-home{background-position:center top;background-size:cover;}
.desktopFrame {margin-top:30px;}
.mob-menu{display:block;}
.close-menu{display:block;}
.menu{opacity:0; visibility:hidden; backface-visibility:hidden; position:fixed; top:0px; right:0px; margin-right:-100%; background:#164a7b; padding-top:50px; width:300px; height:100vh; overflow:hidden; overflow-y:auto; z-index:1; transition:400ms;}
.menu ul li {display:block;border-bottom:1px solid #d8d8d8; }
.menu ul li a{color:#ffffff;}
.menu-slide{margin-right:0; opacity:1; visibility:visible; backface-visibility:visible; transition:400ms;}
.steps-main-wrapper{flex-wrap:wrap!important;}
.step-left, .step-right {order: 2;padding: 3rem 1rem 0;}
.steps-listing li{padding-right:0px;padding-left:70px;}
.steps-listing li span.step-number{left:0;right:auto;}
.steps-listing li:before{left: 26px;right:auto;}
.step-left {text-align: left;}
.login-box{margin-right:40px;}
.system-block{padding:0px;}
.menu_toggle_box {opacity: 1; visibility: visible; backface-visibility: visible; }
.leftPannelMAin {margin-left: -320px; position: absolute; height: 100%; z-index: 10;border:1px solid #ddd;top:0px}
.leftPannelMAin.show_slide { margin-left: 0;}
/* .innerbody  .header.sildepannel{padding-left:60px} */
.btn.btn-menu{background:transparent;}
.bookingSearchFilter .btn{font-size:12px;}
.bookingFilterBox h5{font-size:13px;}
}
/* =============767px 991px End========== */

/* =============767px Start========== */
@media(max-width:767px){

.thousand-month sub {
    font-size: 1.8rem;
}
.thousand-month {
    font-size: 3.8rem;
}

.storage-content h4.weight600 {
    font-size: 24px;
}

.p-5.storage-space-block {
    padding: 20px!important;
}
.banner-sm .topBanner-content {
    min-height: 360px;
}
.contactus-details.p-5.pt60 {
    padding: 20px!important;
}
.contactus-map, .contactus-details {
    width: 100%;
}
.contactus-box{flex-wrap:wrap;}
.contactus-map img {
        border-bottom-left-radius: 0px;
        border-top-right-radius: 10px;
}
.contactus-details p {font-size: 16px;}
.contactus-details p span {
    max-width: 40px;
}
.contactus-details p label {padding-left: 4.5rem;}


.logo {max-width:40px;}	
.headerSearchBox { width: 87px; margin: 0; text-align: center; }	
.headSearchInnerBox{display:none;position:absolute;left:0;bottom:-33px;width:100%;border-top:1px solid #ddd; z-index: 12;}
.headerSearchBox .form-control{border-radius:0;min-height:40px;}
.headSearchInnerBox.show{display:block;}
.head-user-img{width:30px;height:30px;}	
.priceTickersList{display:none;}	
.login-box{max-width:174px;margin-left:0;}	
.btn.btnHeader{padding:3px 12px;min-width:60px;}
.top_user_info{max-width:50px;min-width:auto;}
.topBanner-content h1,.sectionHead h2,.tableBoxHead h4{font-size:26px;}
.topBanner-content p{font-size:14px;}


.mobW100{width:100%!important;max-width:100%!important;}
.mobTextCenter{text-align:center;}
.tableFilter .btn {min-height:45px;}
span.footLogo{display:block;top:auto;margin:0 auto 25px;}
footer p.copyRight{margin:15px 0 0;font-size:0.7rem;}
.addressBox{margin-top:15px;}
.largeImg{margin-top:30px;}
.animationWalletBox {margin-bottom:45px!important;}
.createAccountBox span{margin:0 0 10px;}
.formLinksInfo{font-size:12px;}



.bannerSearchBox{flex-wrap:wrap;justify-content:center;}
.bannerSearchBox .btn{margin-top:15px;min-height:50px;}
.p2pStoargeBox{padding:0;}
.sliderCommonStyle {padding:0;}
.sliderCommonStyle .slick-prev {left:-2px;}
.sliderCommonStyle .slick-next {right:-2px;}
.videoBox{margin-bottom:15px;}
.videoBox video{height:256px;}
.subscribeHead{margin-bottom:10px;}
.subscribeBox .btn{top: 3px;}

.hostingBox {margin:15px 0;}
.emptyFormBox {flex-wrap:wrap;}
.minWidth200.ml15{margin:10px 0 0!important;text-align:center;}
.spaceWorthSection .btn{min-width:100%;}
.spaceWorthPrice{font-size:30px;margin-bottom:10px;}
.spaceWorth h2{font-size:20px;}
.banner-sm .topBanner-content, .bannerHeight{min-height:calc(100vh - 70px);}

.userProfileName{display:none;}
.bookingFilterBox { width: 100%; padding-right: 0; }
.bookingMapBox, .bookingLeftContentBox {width:100%;padding:15px;}
.bookingSearchFilter .btn {font-size:12px;margin-left:5px;padding-left:5px;padding-right:5px;}
.bookingMapBox iframe {height:256px;}
}
/* =============767px End========== */

@media(max-width:479px){
.topBanner-content h1, .sectionHead h2, .tableBoxHead h4 {
font-size: 22px;
}
.subscribeBox .form-control{padding-left:10px;padding-right:72px;}
.subscribeBox .btn{top:6px;font-size:13px;right:0px;}
.sectionActionBtn .btn{font-size:14px!important;min-width:auto!important;}	
}

@media(min-width:768px){
.headSearchIcon{display:none;}
}

@media(min-width:992px){
.mob-view-logo{display:none;}
.menu ul li + li{margin-left:10px;}
}

@media(min-width:1024px){
.mainContent {min-height:calc(100vh - 479px);}
.blogRow div[class*="col"]{max-width:30%;margin:15px 0;}		
}

@media(max-width:991px){

.info-block {
border-left: none;
padding-left: 0;
margin-top: 1rem;
}


}



